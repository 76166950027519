import { gql } from 'graphql-request';

export const PROJECT_FIELDS = gql`
  fragment ProjectFields on ProjectDto {
    projectId
    name
    address
    budget
    siteImage
    startDate
    endDate
    lat
    long
    clientId
    client {
      name
    }
    originalProjectId
  }
`;

export const getProjects = gql`
  query getProjects {
    getProjects {
      ...ProjectFields
    }
  }

  ${PROJECT_FIELDS}
`;

const getProjectById = gql`
  query getProjectByID($id: String!) {
    getProjectByID(id: $id) {
      projectId
      originalProjectId
    }
  }
`;

const getProjectsByIds = gql`
  query GetProjectByIDs($projectIds: [String!]!) {
    getProjectByIDs(projectIds: $projectIds) {
      projectId
      name
    }
  }
`;

export const createProject = gql`
  mutation createProject($project: ProjectCreateInput!) {
    createProject(project: $project) {
      ...ProjectFields
    }
  }
  ${PROJECT_FIELDS}
`;

export const updateProject = gql`
  mutation updateProject($project: ProjectUpdateInput!) {
    updateProject(project: $project) {
      ...ProjectFields
    }
  }
  ${PROJECT_FIELDS}
`;

export const deleteProject = gql`
  mutation deleteProject($id: String!) {
    deleteProject(id: $id) {
      affected
      messages
    }
  }
`;

const ProjectsGraphQL = {
  queries: {
    getProjects,
    getProjectById,
    getProjectsByIds,
  },
  mutations: {
    createProject,
    updateProject,
    deleteProject,
  },
};

export default ProjectsGraphQL;
