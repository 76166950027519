import Button from '@components/ui/Button';
import Input from '@components/ui/Input';
import { useTranslation } from '@hooks/useTranslation';
import { Box, Typography } from '@mui/material';
import { SetStateAction, useEffect, useState } from 'react';

interface CreateEditOrganizationModalProps {
  modalClose: () => void;
  createOrganization?: (name: string, email: string, firstName: string, lastName: string) => void;
  editOrganization?: (name: string, email: string, firstName: string, lastName: string, organizationId: string) => void;
  saving: boolean;
  editing?: boolean;
  initialData?: { name: string; organizationId: string } | null | undefined;
}

const CreateEditOrganizationModal: React.FC<CreateEditOrganizationModalProps> = ({
  modalClose,
  createOrganization,
  editOrganization,
  saving,
  editing = false,
  initialData,
}) => {
  const localeCommon = useTranslation('common');
  const localeUsers = useTranslation('users');
  const localeOrganizations = useTranslation('organizations');
  const [organizationName, setOrganizationName] = useState<string | null>(null);
  const [ownerEmail, setOwnerEmail] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);

  useEffect(() => {
    if (editing && initialData) {
      setOrganizationName(initialData.name);
    }
  }, [editing, initialData]);

  const handleNameChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    setOrganizationName(event.target.value);
  };

  const handleEmailChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    setOwnerEmail(event.target.value);
  };

  const handleFirstNameChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    setLastName(event.target.value);
  };

  const submit = () => {
    if (editing && initialData) {
      editOrganization && editOrganization(organizationName || '', '', '', '', initialData.organizationId || '');
    } else {
      createOrganization &&
        createOrganization(organizationName || '', ownerEmail || '', firstName || '', lastName || '');
    }
    modalClose();
  };

  return (
    <Box>
      <Typography variant="h3">{localeOrganizations['addOrganization']}</Typography>
      <Box>
        <Input
          fullWidth
          autoComplete="organization"
          label={localeCommon['organization'] + ' ' + localeCommon['name']}
          placeholder={localeCommon['organization'] + ' ' + localeCommon['name']}
          onChange={handleNameChange}
          value={organizationName}
        />
      </Box>

      {!editing && (
        <>
          <Box>
            <Input
              fullWidth
              autoComplete="email"
              label={localeCommon['owner'] + ' ' + localeCommon['email']}
              placeholder={localeCommon['organization'] + ' ' + localeCommon['email']}
              onChange={handleEmailChange}
              value={ownerEmail}
            />
          </Box>
          <Box>
            <Input
              fullWidth
              autoComplete="given-name"
              label={localeCommon['owner'] + ' ' + localeUsers['firstName']}
              placeholder={localeCommon['user'] + ' ' + localeUsers['firstName']}
              onChange={handleFirstNameChange}
              value={firstName}
            />
          </Box>
          <Box>
            <Input
              fullWidth
              autoComplete="family-name"
              label={localeCommon['owner'] + ' ' + localeUsers['lastName']}
              placeholder={localeCommon['user'] + ' ' + localeUsers['lastName']}
              onChange={handleLastNameChange}
              value={lastName}
            />
          </Box>
        </>
      )}
      <Box className="flex justify-between mt-4">
        <Button onClick={modalClose} color="secondary">
          {localeCommon['cancel']}
        </Button>
        <Button onClick={submit} color="primary" autoFocus disabled={saving}>
          {editing ? localeCommon['edit'] : localeCommon['create']}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEditOrganizationModal;
