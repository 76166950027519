import { useEffect, useState } from 'react';
import { useAutocomplete, AutocompleteGetTagProps } from '@mui/base/useAutocomplete';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import COLORS from '@utils/color';
import CheckIcon from '@mui/icons-material/Check';

const Root = styled('div')(
  ({ theme }) => `
  color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'};
  font-size: 14px;
`,
);

const Label = styled('label')`
  padding: 0 0 4px;
  line-height: 1.5;
  padding-right: 10px;
`;

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 79%;
  border: 1px solid ${theme.palette.mode === 'dark' ? COLORS.lightBlue : COLORS.lightBlue};
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: ${theme.palette.mode === 'dark' ? COLORS.white : COLORS.white};

  &:hover {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
  }

  &.focused {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
    color: ${theme.palette.mode === 'dark' ? COLORS.white : COLORS.white};
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
  onClick: () => void;
  downloadIcon?: boolean;
  onClose: () => void;
}

function Tag(props: TagProps) {
  const { label, onDelete, onClick, onClose, downloadIcon, ...other } = props;

  return (
    <div {...other} onClick={downloadIcon ? () => null : onClick} style={{ justifyContent: 'space-between' }}>
      <span>{label}</span>
      <div style={{ alignItems: 'center' }}>
        {downloadIcon && (
          <DescriptionIcon
            onClick={(event) => {
              event.stopPropagation();
              if (onClick) {
                onClick();
              }
            }}
          />
        )}
        <CloseIcon
          onClick={(event) => {
            event.stopPropagation();
            if (onDelete) {
              onDelete(event);
              onClose();
            }
          }}
        />
      </div>
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>(
  ({ theme }) => `
  width: 43%;
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${theme.palette.mode === 'dark' ? COLORS.lightBlue : COLORS.lightBlue};
  color: ${theme.palette.mode === 'dark' ? COLORS.black : COLORS.black};
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }

  &:hover {
    cursor: pointer;
  }
`,
);

const Listbox = styled('ul')(
  ({ theme }) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
);

interface CustomizedHookProps<T> {
  options: T[];
  label: string;
  setOptions: (newOptions: T[]) => void;
  getOptionLabel: (option: T) => string;
  showList?: boolean;
  taskIds?: string[];
  eventIds?: string[];
  onTagClick?: (value: T) => void;
  downloadIcon?: boolean;
  onClose?: (value: T) => void;
}

export default function CustomizedHook<T>({
  options,
  label,
  setOptions,
  getOptionLabel,
  showList = false,
  taskIds,
  eventIds,
  onTagClick,
  downloadIcon,
  onClose,
}: CustomizedHookProps<T>) {
  const [selectedValues, setSelectedValues] = useState<T[]>([]);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook-demo',
    multiple: true,
    options: options,
    value: selectedValues,
    onChange: (event, newValue) => {
      setSelectedValues(newValue);
      setOptions(options.filter((option) => newValue.includes(option)));
    },
    getOptionLabel: getOptionLabel,
  });

  useEffect(() => {
    if (taskIds?.length) {
      if (showList) {
        const filteredTasks = options.filter((task) => taskIds?.includes((task as { taskId: string }).taskId));
        setSelectedValues(filteredTasks);
      } else {
        setSelectedValues(options);
      }
    } else {
      if (showList) {
        const filteredEvents = options.filter((event) => eventIds?.includes((event as { eventId: string }).eventId));
        setSelectedValues(filteredEvents);
      } else {
        setSelectedValues(options);
      }
    }
  }, [options, taskIds, eventIds]);

  return (
    <Root>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }} {...getRootProps()}>
        <Label {...getInputLabelProps()}>{label}</Label>
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
          {value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <StyledTag
                key={key}
                {...tagProps}
                downloadIcon={downloadIcon}
                onClose={() => onClose && onClose(option)}
                label={getOptionLabel(option)}
                onClick={() => onTagClick && onTagClick(option)}
              />
            );
          })}
          <input {...getInputProps()} />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 && showList ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as typeof options).map((option, index) => {
            const { key, ...optionProps } = getOptionProps({ option, index });
            return (
              <li key={key} {...optionProps}>
                <span>{getOptionLabel(option)}</span>
                <CheckIcon fontSize="small" />
              </li>
            );
          })}
        </Listbox>
      ) : null}
    </Root>
  );
}
