import AltInput from '@components/ui/AltInput';
import DateInput from '@components/ui/DateInput';
import NumericFormatInput from '@components/ui/NumericFormatInput';
import Button from '@components/ui/Button';
import { useTranslation } from '@hooks/useTranslation';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { SetStateAction, useEffect, useState } from 'react';
import useFormattedDateLarge from '@hooks/useFormattedDateLarge';
import useValidation from '@hooks/useValidation';
import SelectInput from '@components/ui/SelectInput';
import { Task } from '@models/task.model';
import { Event } from '@models/event.model';
import { TimeOptions, EventTypeOptions, IncidentTypeOptions, InspectionTypeOptions } from '@utils/options';
import CustomizedHook from '@components/ui/CustomizedHookInput';

interface CreateEditEventModalProps {
  modalClose: (idProject: string | null) => void;
  createEvent?: (
    name: string,
    eventType: string,
    otherEventType: string | null,
    eventTime: Date | string,
    incidentType: string | null,
    addedIncidentType: string | null,
    inspectionType: string | null,
    permit: string | null | undefined,
    delay: number | null,
    delayType: string | null,
    notes: string | null | undefined,
    precedentTaskId?: string[],
  ) => void;
  editEvent?: (
    name: string,
    eventType: string,
    otherEventType: string | null,
    eventTime: Date | string,
    incidentType: string | null,
    addedIncidentType: string | null,
    inspectionType: string | null,
    permit: string | null | undefined,
    delay: number | null,
    delayType: string | null,
    notes: string | null | undefined,
    precedentTaskId?: string[],
    eventId?: string,
  ) => void;
  saving: boolean;
  initialData?: Event | null | undefined;
  tasks: Task[];
}

const CreateEditEventModal: React.FC<CreateEditEventModalProps> = ({
  modalClose,
  createEvent,
  editEvent,
  saving,
  initialData,
  tasks,
}) => {
  const localeCommon = useTranslation('common');
  const localeProjects = useTranslation('projects');
  const [name, setName] = useState<string | null>(null);
  const [addedIncidentType, setAddedIncidentType] = useState<string | null>(null);
  const [notes, setNotes] = useState<string | null | undefined>(null);
  const [permit, setPermit] = useState<string | null | undefined>(null);
  const [eventTime, setEventTime] = useState<string | null>(null);
  const [delay, setDelay] = useState<number | null>(null);
  const [preceding, setPreceding] = useState<string[] | undefined>([]);
  const formattedEventTime = useFormattedDateLarge(initialData?.eventTime || null);
  const [eventType, setSelectedEventType] = useState<string | null>(null);
  const [otherEventType, setOtherEventType] = useState<string | null>(null);
  const [incidentType, setSelectedIncidentType] = useState<string | null>(null);
  const [inspectionType, setSelectedInspectionType] = useState<string | null>(null);
  const [delayType, setSelectedDelayType] = useState<string | null>(null);
  const { validateInput, resetError, errors } = useValidation();

  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
      setNotes(initialData.notes);
      setPermit(initialData.permit);
      setEventTime(formattedEventTime);
      setSelectedEventType(initialData.eventType);
      setOtherEventType(initialData.otherEventType);
      setDelay(initialData.delay);
      setPreceding(initialData.precedentTaskId);
      setSelectedIncidentType(initialData.incidentType);
      setAddedIncidentType(initialData.addedIncidentType);
      setSelectedInspectionType(initialData.inspectionType);
      setSelectedDelayType(initialData.delayType);
    }
  }, [initialData, formattedEventTime]);

  const handleNameChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('name');
    setName(event.target.value);
  };

  const handlePermitChange = (event: { target: { value: SetStateAction<string | null | undefined> } }) => {
    resetError('permit');
    setPermit(event.target.value);
  };

  const handleOtherEventChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('otherType');
    setOtherEventType(event.target.value);
  };

  const handleNotesChange = (event: { target: { value: SetStateAction<string | null | undefined> } }) => {
    resetError('notes');
    setNotes(event.target.value);
  };

  const handleDelayChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('delay');
    setDelay(Number(event.target.value));
  };

  const handleEventTimeChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('eventTime');
    setEventTime(event.target.value);
  };

  const handleEventTypeChange = (event: React.SyntheticEvent, value: { id: string; name: string } | null) => {
    resetError('eventType');
    setOtherEventType(null);
    if (value) {
      setSelectedEventType(value.name);
    } else {
      setSelectedEventType(null);
    }
  };

  const handleIncidentTypeChange = (event: React.SyntheticEvent, value: { id: string; name: string } | null) => {
    resetError('incidentType');
    if (value) {
      setSelectedIncidentType(value.name);
    } else {
      setSelectedIncidentType(null);
    }
  };

  const handleAddedIncidentTypeChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('addedIncident');
    setAddedIncidentType(event.target.value);
  };

  const handleInspectionTypeChange = (event: React.SyntheticEvent, value: { id: string; name: string } | null) => {
    resetError('inspectionType');
    if (value) {
      setSelectedInspectionType(value.name);
    } else {
      setSelectedInspectionType(null);
    }
  };

  const handleDelayTypeChange = (event: React.SyntheticEvent, value: { id: string; name: string } | null) => {
    resetError('delayType');
    if (value) {
      setSelectedDelayType(value.name);
    } else {
      setSelectedDelayType(null);
    }
  };

  const submit = () => {
    const isNameValid = validateInput('name', name);
    const isEventTypeValid = validateInput('eventType', eventType);
    const isEventTimeValid = validateInput('eventTime', eventTime);
    const isIncidentTypeValid = eventType === 'Incident' ? validateInput('incidentType', incidentType) : true;
    const isInspectionTypeValid = eventType === 'Inspection' ? validateInput('inspectionType', inspectionType) : true;
    const isPermitValid = eventType === 'Permit' ? validateInput('permit', permit) : true;
    const isDelayValid = eventType === 'Incident' ? validateInput('delay', delay) : true;
    const isDelayTypeValid = eventType === 'Incident' ? validateInput('delayType', delayType) : true;
    const isNotesValid =
      eventType === 'Incident' || eventType === 'Visitor' || eventType === 'Permit' || eventType === 'Communication'
        ? validateInput('notes', notes)
        : true;
    const isAddedIncidentValid =
      eventType === 'Incident' && incidentType === 'Add incident type'
        ? validateInput('addedIncident', addedIncidentType)
        : true;
    const isFormValid =
      isNameValid &&
      isEventTypeValid &&
      isEventTimeValid &&
      isIncidentTypeValid &&
      isInspectionTypeValid &&
      isPermitValid &&
      isDelayValid &&
      isDelayTypeValid &&
      isNotesValid &&
      isAddedIncidentValid;

    if (!isFormValid) return;

    const incidentTypeToSend = eventType === 'Incident' ? incidentType : null;
    const addedIncidentTypeToSend =
      eventType === 'Incident' && incidentType === 'Add incident type' ? addedIncidentType : null;
    const inspectionTypeToSend = eventType === 'Inspection' ? inspectionType : null;
    const permitToSend = eventType === 'Permit' ? permit : null;
    const delayToSend = eventType === 'Incident' ? delay : null;
    const delayTypeToSend = eventType === 'Incident' ? delayType : null;
    const notesToSend =
      eventType === 'Incident' || eventType === 'Visitor' || eventType === 'Permit' || eventType === 'Communication'
        ? notes
        : null;

    const eventTimeValue = eventTime ? new Date(eventTime).toISOString() : null;

    if (initialData) {
      editEvent &&
        editEvent(
          name!,
          eventType!,
          otherEventType,
          eventTimeValue!,
          incidentTypeToSend,
          addedIncidentTypeToSend,
          inspectionTypeToSend,
          permitToSend,
          delayToSend,
          delayTypeToSend,
          notesToSend,
          preceding,
          initialData.eventId,
        );
    } else {
      createEvent &&
        createEvent(
          name!,
          eventType!,
          otherEventType,
          eventTimeValue!,
          incidentTypeToSend,
          addedIncidentTypeToSend,
          inspectionTypeToSend,
          permitToSend,
          delayToSend,
          delayTypeToSend,
          notesToSend,
          preceding,
        );
    }
    modalClose(null);
  };

  const attemptCancel = () => {
    if (window.confirm('Sure? Your changes will be lost')) {
      modalClose(null);
    }
  };

  return (
    <Box>
      <Typography variant="h3">{'Add Event'}</Typography>

      <Box>
        <AltInput
          fullWidth
          autoComplete="task"
          label={localeProjects['name']}
          placeholder={`Event Name`}
          onChange={handleNameChange}
          value={name}
          error={errors.name}
          errorMessage={`Name must be at least 3 characters long`}
        />
        <SelectInput
          label={'Event Delay'}
          placeholder={'Event Delay'}
          items={EventTypeOptions}
          value={eventType}
          valueKey="id"
          labelKey="name"
          onChange={handleEventTypeChange}
          error={errors.eventType}
          errorMessage={'Required field'}
        />
        {eventType === 'Other' && (
          <AltInput
            fullWidth
            autoComplete="otherEvent"
            label={'Other Event'}
            placeholder={`Add Other Event`}
            onChange={handleOtherEventChange}
            value={otherEventType}
            error={errors.name}
            errorMessage={`Other must be at least 3 characters long`}
          />
        )}
        <Box paddingLeft={2}>
          <DateInput
            fullWidth
            autoComplete="eventTime"
            disabled
            showTimeSelect
            showIcon={true}
            label={'Event Time'}
            placeholder={`mm/dd/yyyy`}
            onChange={handleEventTimeChange}
            error={errors.eventTime || null || undefined}
            value={eventTime}
            errorMessage={localeProjects['startErrorMessage']}
          />
        </Box>
        {eventType === 'Incident' && (
          <SelectInput
            label={'Incident Type'}
            placeholder={'Incident Type'}
            items={IncidentTypeOptions}
            value={incidentType}
            valueKey="id"
            labelKey="name"
            onChange={handleIncidentTypeChange}
            error={errors.incidentType}
            errorMessage={'Required field'}
          />
        )}

        {eventType === 'Incident' && incidentType === 'Add incident type' && (
          <AltInput
            fullWidth
            autoComplete="addedIncident"
            label={'Add Incident Type'}
            placeholder={`Add incident type`}
            onChange={handleAddedIncidentTypeChange}
            value={addedIncidentType}
            error={errors.addedIncident}
            errorMessage={`Name must be at least 3 characters long`}
          />
        )}

        {eventType === 'Inspection' && (
          <SelectInput
            label={'Inspection Type'}
            placeholder={'Inspection Type'}
            items={InspectionTypeOptions}
            value={inspectionType}
            valueKey="id"
            labelKey="name"
            onChange={handleInspectionTypeChange}
            error={errors.inspectionType}
            errorMessage={'Required field'}
          />
        )}
        {eventType === 'Permit' && (
          <AltInput
            fullWidth
            autoComplete="permit"
            label={'Permit #'}
            placeholder={`Permit #`}
            onChange={handlePermitChange}
            value={permit}
            error={errors.permit}
            errorMessage={`Name must be at least 3 characters long`}
          />
        )}
        {eventType === 'Incident' && (
          <Stack direction="row" width={'50%'} paddingLeft={3}>
            <NumericFormatInput
              fullWidth
              autoComplete="delay"
              label={'Delay'}
              placeholder={`10`}
              onChange={handleDelayChange}
              disablePrefix
              value={delay}
              error={errors.delay}
              errorMessage={localeProjects['budgetErrorMessage']}
            />
            <SelectInput
              label={'   '}
              placeholder={'Select time type'}
              items={TimeOptions}
              value={delayType}
              valueKey="id"
              labelKey="name"
              error={errors.delayType}
              errorMessage={'Required field'}
              onChange={handleDelayTypeChange}
            />
          </Stack>
        )}
        <Box className="flex justify-between mt-4">
          {(eventType === 'Incident' ||
            eventType === 'Visitor' ||
            eventType === 'Permit' ||
            eventType === 'Communication') && (
            <AltInput
              fullWidth
              autoComplete="notes"
              multiline
              rows={4}
              label={'Notes'}
              placeholder={`Notes`}
              onChange={handleNotesChange}
              value={notes}
              error={errors.notes}
              errorMessage={`Note must be at least 3 characters long`}
            />
          )}
        </Box>
        {eventType === 'Inspection' && (
          <CustomizedHook
            options={tasks}
            setOptions={(_tasks) => setPreceding(_tasks.map((task) => task.taskId!))}
            label="Preceding"
            getOptionLabel={(option) => option.name}
            showList={true}
            taskIds={preceding}
            onTagClick={(value) => console.log(value)}
          />
        )}
      </Box>
      <Box className="flex justify-between mt-4">
        <Button onClick={attemptCancel} color="secondary">
          {localeCommon['cancel']}
        </Button>
        <Button onClick={submit} color="primary" autoFocus disabled={saving}>
          {initialData ? localeCommon['saveChanges'] : localeCommon['create']}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEditEventModal;
