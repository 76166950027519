import { graphQlClient } from '@config/graphqlClient';
import UserGraphQL from '@graphql/user.queries';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { User, getUserByEmailRequest } from '@models/user.model';
import { Box, Button, Typography } from '@mui/material';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

interface UserInfoModalProps {
  modalClose: () => void;
  userToShow: string;
  organization: string;
}

const UserInfoModal: React.FC<UserInfoModalProps> = ({ modalClose, userToShow, organization }) => {
  const localeCommon = useTranslation('common');
  const localeUser = useTranslation('users');
  const dispatch = useDispatch();
  const [userData, setUserData] = useState<User | null>(null);

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    try {
      const data: getUserByEmailRequest = await graphQlClient.request(UserGraphQL.queries.getUserByEmail, {
        email: userToShow,
        emailOrganizationId: organization,
      });
      setUserData(data.getUserByEmail);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  return (
    <Box>
      <Typography variant="h5">{'USER INFO: '}</Typography>

      <Typography variant="h6">
        {localeUser['firstName']} {': '}
        {userData?.firstName}
      </Typography>
      <Typography variant="h6">
        {localeUser['lastName']}
        {': '}
        {userData?.lastName}
      </Typography>
      <Typography variant="h6">
        {localeCommon['email']}
        {': '}
        {userData?.email}
      </Typography>
      <Typography variant="h6">
        {localeUser['role']}
        {': '}
        {userData?.role}
      </Typography>
      <Typography variant="h6">
        {localeUser['status']}
        {': '} {userData?.status}
      </Typography>

      <Box className="flex justify-end">
        <Button onClick={modalClose} color="primary">
          {localeCommon['close']}
        </Button>
      </Box>
    </Box>
  );
};

export default UserInfoModal;
