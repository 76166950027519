import { Typography, TypographyProps } from '@mui/material';
import { SxProps, styled } from '@mui/system';

interface TextLinkProps extends TypographyProps {
  size?: '24px' | '16px' | '14px';
  background?: 'light' | 'dark';
  onClick?: () => void;
  sx?: SxProps;
}

const StyledTextLink = styled(Typography)<TextLinkProps>(({ theme, background, size }) => ({
  '&.MuiTypography-root': {
    cursor: 'pointer',
    fontSize: size,
    textDecoration: 'none',
    color: background === 'light' ? theme.palette.primaryDark.main : theme.palette.common.white,
    backgroundColor: 'transparent',
    '&:hover': {
      textDecoration: 'underline',
      color: background === 'light' ? theme.palette.frozenGrayShades4.main : theme.palette.frozenGrayShades6.main,
    },
  },
}));

const TextLink: React.FC<TextLinkProps> = ({ background = 'light', onClick, children, size, ...props }) => (
  <StyledTextLink {...props} onClick={onClick} background={background} size={size}>
    {children}
  </StyledTextLink>
);

export default TextLink;
