import Header from '@components/generic/Header';
import { AppBar, CssBaseline, styled } from '@mui/material';
import ActionMessages from '@components/generic/ActionMessages';
import Sidebar from '@components/generic/Sidebar';
import Box from '@mui/material/Box';

const ContentContainer = styled(Box)(({ theme }) => ({
  marginLeft: 240,
  padding: 0,
}));

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <Box className="flex">
      <ActionMessages />
      <CssBaseline />
      <AppBar position="fixed" elevation={0}>
        <Header />
      </AppBar>
      <Box component="main" marginTop={'65px'} className="flex-grow bg-backgroundUI">
        <Sidebar />
        <ContentContainer
          component="main"
          className="flex flex-grow bg-backgroundUI p-8"
          sx={{ height: 'calc(100vh - 65px)' }}>
          {children}
        </ContentContainer>
      </Box>
    </Box>
  );
}
