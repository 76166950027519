import { useState, useCallback, useEffect } from 'react';

const useCompareDates = (date1: string | null, date2: string | null): boolean | null => {
  const [isDate1Greater, setIsDate1Greater] = useState<boolean>(false);

  const compareDates = useCallback(() => {
    if (date1 === null || date2 === null) {
      setIsDate1Greater(false);
      return;
    }

    const [month1, day1, year1] = date1.split('/').map(Number);
    const [month2, day2, year2] = date2.split('/').map(Number);

    const formattedDate1 = new Date(year1, month1 - 1, day1);
    const formattedDate2 = new Date(year2, month2 - 1, day2);

    setIsDate1Greater(formattedDate1 > formattedDate2);
  }, [date1, date2]);

  useEffect(() => {
    compareDates();
  }, [compareDates]);

  return isDate1Greater;
};

export default useCompareDates;
