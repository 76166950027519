import { CloseOutlined } from '@mui/icons-material';
import { Box, Fade, IconButton, Modal } from '@mui/material';

export interface CustomDrawerProps {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  style?: {
    maxWidth?: number;
  };
}

const CustomModal: React.FC<CustomDrawerProps> = ({ isOpen, onClose, children, style }) => {
  return (
    <Modal open={isOpen} onClose={onClose} closeAfterTransition className="content-center overflow-scroll">
      <Fade in={isOpen}>
        <Box
          className="relative w-3/4 bg-white ml-auto mr-auto p-2 pt-4 my-4"
          sx={{ maxWidth: style?.maxWidth || 'auto', padding: '30px 40px' }}>
          <Box className="absolute top-2 right-2">
            {onClose && (
              <IconButton onClick={onClose} color="primary">
                <CloseOutlined />
              </IconButton>
            )}
          </Box>
          <Box>{children}</Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
