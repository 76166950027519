import { Box, IconButton, LinearProgress, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import COLORS from '@utils/color';
import { useHistory } from 'react-router-dom';
import BudgetTable from '@components/budgetTable/BudgetTable';
import { Project } from '@models/project.model';
import Checkbox from '@mui/material/Checkbox';

interface BudgetModalProps {
  modalClose: (s: boolean) => void;
  selectedItem: Project | null;
  inventory: any;
  loadingInventory: boolean;
  setUnassigned: (s: boolean) => void;
  unassigned: boolean;
}

const BudgetModal = ({
  modalClose,
  selectedItem,
  inventory,
  loadingInventory,
  setUnassigned,
  unassigned,
}: BudgetModalProps) => {
  const history = useHistory();

  const handleClose = () => {
    modalClose(false);
    setUnassigned(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnassigned(event.target.checked);
  };

  function formatNumber(value: number) {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  const total = inventory.reduce((sum: number, item: any) => {
    if ('equipmentId' in item) {
      return sum + item.costPerHour * item.hoursUsed;
    } else if ('materialId' in item) {
      return sum + item.amount * item.costPerUnit;
    }
    return sum;
  }, 0);

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: COLORS.white,
        padding: 2,
      }}>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
        }}>
        <CloseIcon />
      </IconButton>
      <Box sx={{ flex: 1 }}>
        <Box display="flex" alignItems="center">
          <Typography fontSize={14} fontWeight={500} color={COLORS.lighterGray} variant="subtitle1">
            {'Budget Detail'}
          </Typography>
          {!unassigned && (
            <Typography
              fontSize={14}
              fontWeight={500}
              color={selectedItem?.budget && total <= selectedItem?.budget ? COLORS.lightBlue : COLORS.red}
              sx={{ marginLeft: 2 }}
              variant="subtitle1">
              {selectedItem?.budget && total <= selectedItem?.budget ? 'ON BUDGET' : 'OUT OF BUDGET'}
            </Typography>
          )}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 2, width: '100%' }}>
          <Typography fontWeight={800} variant="h6">
            {selectedItem?.name}
          </Typography>
          <Box alignItems="center" display="flex">
            <Typography fontSize={14} fontWeight={500} color={COLORS.lighterGray} variant="subtitle1">
              {'unassigned'}
            </Typography>
            <Checkbox checked={unassigned} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
          </Box>
        </Box>
        <BudgetTable inventory={inventory} />
        <Box
          sx={{
            borderTopWidth: 1,
            borderTopColor: COLORS.darkBlue,
            borderTopStyle: 'solid',
          }}
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <Typography fontWeight={600} color={'#215182'} variant="subtitle1">
            {'Total Cost to Date'}
          </Typography>
          {!unassigned && (
            <Typography fontWeight={600} color={'#215182'} variant="subtitle1">
              {'Max Budget'}
            </Typography>
          )}
          {!unassigned && (
            <Typography fontWeight={600} color={'#215182'} variant="subtitle1">
              {'Percentage Spent'}
            </Typography>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontWeight={800} variant="subtitle1">{`$ ${formatNumber(total)}`}</Typography>
          {!unassigned && (
            <Typography
              fontWeight={800}
              variant="subtitle1">{`$ ${selectedItem?.budget && formatNumber(selectedItem?.budget)}`}</Typography>
          )}
          {!unassigned && (
            <Box sx={{ width: 100 }}>
              <Typography
                fontWeight={800}
                variant="subtitle1">{`${selectedItem?.budget ? (total / selectedItem?.budget) * 100 : 0}%`}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BudgetModal;
