import {
  getTokenLocalStorage,
  getTokenSessionStorage,
  setTokenLocalStorage,
  setTokenSessionStorage,
} from '@config/storage';
import { RootState, store } from '@redux/store';
import { GraphQLClient, RequestMiddleware, ResponseMiddleware } from 'graphql-request';

const responseMiddleware: ResponseMiddleware = async (response: any) => {
  const refreshed = response?.headers?.get('Refreshed');
  if (refreshed) {
    const state: RootState = store.getState();
    const rememberMe = state.user?.rememberMe;
    if (rememberMe) {
      setTokenLocalStorage(refreshed);
    } else {
      setTokenSessionStorage(refreshed);
    }
  }
  if (
    response?.response?.errors &&
    ['Forbidden resource', 'NOT AUTHORIZED'].includes(response?.response?.errors[0]?.message)
  ) {
    //Redirect to process logout and then to start page
    window.location.href = '/logout';
  }
};

const requestMiddleware: RequestMiddleware = async (request) => {
  const state: RootState = store.getState();
  const rememberMe = state.user?.rememberMe;
  let token = '';
  if (rememberMe) {
    token = getTokenLocalStorage();
  } else {
    token = getTokenSessionStorage();
  }
  return {
    ...request,
    headers: {
      ...request.headers,
      Authorization: 'Bearer ' + token,
    },
  };
};

export { gql } from 'graphql-request';

export const graphQlClient = new GraphQLClient(process.env.REACT_APP_BACKEND_URL + 'graphql', {
  fetch,
  requestMiddleware,
  responseMiddleware,
});

// we might need to migrate to a different graphql library so that we don't need to use this separately
// and that the mutation requests are more descriptive. This will eventually reach a limitation.
export const graphQlUpload = async (file: File, entity: string, query: string, variables?: {}) => {
  const MAX_FILE_SIZE_MB = 50;
  if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
    throw new Error('File size exceeds the maximum allowed size');
  }
  const state: RootState = store.getState();
  const rememberMe = state.user?.rememberMe;
  let token = '';
  if (rememberMe) {
    token = getTokenLocalStorage();
  } else {
    token = getTokenSessionStorage();
  }

  const operations = {
    query,
    variables: {
      [entity]: {
        ...variables,
        file: null, // This will be replaced in the 'map' with the actual file location.
      },
    },
  };

  const map = {
    '0': [`variables.${entity}.file`],
  };

  const formData = new FormData();
  formData.append('operations', JSON.stringify(operations));
  formData.append('map', JSON.stringify(map));
  formData.append('0', file); // The file should be appended last.

  const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'graphql', {
    method: 'POST',
    body: formData,
    headers: {
      'apollo-require-preflight': 'true',
      Authorization: 'Bearer ' + token,
    },
  });
  const responseData = await response.json();
  return responseData.data;
};

export const graphQlMultipleUpload = async (files: File[], entity: string, query: string, variables?: {}) => {
  const MAX_FILE_SIZE_MB = 50;
  files.forEach((file) => {
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      throw new Error(`File '${file.name}' size exceeds the maximum allowed size`);
    }
  });

  const state: RootState = store.getState();
  const rememberMe = state.user?.rememberMe;
  let token = '';
  if (rememberMe) {
    token = getTokenLocalStorage();
  } else {
    token = getTokenSessionStorage();
  }

  const operations = {
    query,
    variables: {
      [entity]: {
        ...variables,
        files: Array(files.length).fill(null), // Placeholder for the files
      },
    },
  };

  // Dynamically map file indices
  const map: { [key: string]: string[] } = {};
  files.forEach((_, index) => {
    map[index] = [`variables.${entity}.files.${index}`];
  });

  const formData = new FormData();
  formData.append('operations', JSON.stringify(operations));
  formData.append('map', JSON.stringify(map));

  // Append all the files dynamically
  files.forEach((file, index) => {
    formData.append(`${index}`, file);
  });

  const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'graphql', {
    method: 'POST',
    body: formData,
    headers: {
      'apollo-require-preflight': 'true',
      Authorization: 'Bearer ' + token,
    },
  });
  const responseData = await response.json();
  return responseData.data;
};
