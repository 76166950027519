import AltInput from '@components/ui/AltInput';
import Button from '@components/ui/Button';
import { useTranslation } from '@hooks/useTranslation';
import { Box, Typography } from '@mui/material';
import { SetStateAction, useEffect, useState } from 'react';
import useValidation from '@hooks/useValidation';

interface CreateEditCrewModalProps {
  modalClose: () => void;
  createCrew?: (name: string) => void;
  editCrew?: (name: string, crewId: string) => void;
  saving: boolean;
  initialData?:
    | {
        name: string;
        crewId: string;
      }
    | null
    | undefined;
}

const CreateEditCrewModal: React.FC<CreateEditCrewModalProps> = ({
  modalClose,
  createCrew,
  editCrew,
  saving,
  initialData,
}) => {
  const localeCommon = useTranslation('common');
  const [name, setName] = useState<string | null>(null);
  const { validateInput, resetError, errors } = useValidation();

  useEffect(() => {
    if (initialData) {
      setName(initialData?.name);
    }
  }, [initialData]);

  const handleNameChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('name');
    setName(event.target.value);
  };

  const submit = () => {
    const isNameValid = validateInput('name', name);

    if (name && isNameValid) {
      if (initialData) {
        editCrew && editCrew(name, initialData.crewId);
      } else {
        createCrew && createCrew(name);
      }
      modalClose();
    }
  };

  const attemptCancel = () => {
    if (window.confirm('Sure? Your changes will be lost')) {
      modalClose();
    }
  };

  return (
    <Box>
      <Typography variant="h3">{'Add New Crew'}</Typography>

      <Box>
        <AltInput
          fullWidth
          autoComplete="nameCrew"
          label={'Name'}
          placeholder={`Crew Name`}
          onChange={handleNameChange}
          value={name}
          error={errors.name}
          errorMessage={'First Name must be at least 3 characters long'}
        />
      </Box>
      <Box className="flex justify-between mt-4">
        <Button onClick={attemptCancel} color="secondary">
          {localeCommon['cancel']}
        </Button>
        <Button onClick={submit} color="primary" autoFocus disabled={saving}>
          {initialData ? localeCommon['saveChanges'] : localeCommon['create']}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEditCrewModal;
