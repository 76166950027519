import { styled } from '@mui/material/styles';
import { Box, IconButton, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Employee, getEmployeesRequest } from '@models/employee.model';
import { useState, useEffect } from 'react';
import { graphQlClient } from '@config/graphqlClient';
import EmployeesGraphQL from '@graphql/employee.queries';
import { useAppDispatch } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import Button from '@components/ui/Button';
import CustomModal from '@components/generic/CustomModal';
import AddEmployeeModal from './AddEmployeeModal';
import { Crew } from '@models/crew.model';
import { Business, Edit } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CrewsGraphQL from '@graphql/crew.queries';
import CreateEditEmployeeModal from '@components/employee/CreateEditEmployeeModal';
import { UserRoles } from '@models/user.model';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '160px',
  border: '2px solid #00bfff',
  backgroundColor: 'white',
  boxShadow: 'none',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '80px',
}));

interface EmployeeContainerProps {
  crewId: string | undefined;
  selectedItem: Crew;
  getCrew: () => void;
}

export default function EmployeeContainer({ selectedItem, crewId, getCrew }: EmployeeContainerProps) {
  const dispatch = useAppDispatch();
  const localeCommon = useTranslation('common');
  const [items, setItems] = useState<Employee[] | undefined>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showEmployeeModal, setShowEmployeeModal] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>(undefined);

  useEffect(() => {
    if (selectedItem) {
      setItems(selectedItem?.employees);
    }
  }, [selectedItem]);

  useEffect(() => {
    getEmployees(true);
  }, []);

  const getEmployees = async (initialize = false) => {
    setLoading(true);
    try {
      const data: getEmployeesRequest = await graphQlClient.request(EmployeesGraphQL.queries.getEmployees);
      setEmployees(data.getEmployees);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleAddItem = () => {
    setShowModal(!showModal);
  };

  const handleSetItem = (items: Employee[]) => {
    setItems(items);
  };

  const handleUnassignEmployee = async (employeeId: string | undefined) => {
    try {
      await graphQlClient.request(CrewsGraphQL.mutations.unassign, { crewId, employeeId });
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      getCrew();
    }
  };

  const toggleNewEmployeeModal = () => {
    setShowEmployeeModal(!showEmployeeModal);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomModal isOpen={!!showModal} style={{ maxWidth: 580 }}>
        <AddEmployeeModal
          modalClose={() => setShowModal(false)}
          employees={employees}
          setItems={handleSetItem}
          items={items}
          crewId={crewId}
          getCrew={getCrew}
        />
      </CustomModal>
      <CustomModal isOpen={!!showEmployeeModal} style={{ maxWidth: 580 }}>
        <CreateEditEmployeeModal
          modalClose={toggleNewEmployeeModal}
          saving={false}
          initialData={selectedEmployee}
          disabled={true}
        />
      </CustomModal>
      <Grid container spacing={2} columns={12}>
        <Grid container spacing={2} columns={12}>
          {items?.map((item, index) => (
            <Grid item xs={2} sm={2} md={2} key={index}>
              <Item
                onClick={() => {
                  setSelectedEmployee(item);
                  toggleNewEmployeeModal();
                }}>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 1,
                    position: 'relative',
                  }}>
                  {/* Botón de cerrar */}
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: -20,
                      right: -20,
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleUnassignEmployee(item.employeeId);
                    }}>
                    <ClearIcon />
                  </IconButton>
                  {item.user?.role === UserRoles.SUPER_EMPLOYEE && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: -15,
                        left: -15,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'gold',
                        width: 30,
                        height: 30,
                        borderRadius: '50%',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                      }}>
                      <BookmarkIcon sx={{ fontSize: 16, color: 'white' }} />
                    </Box>
                  )}

                  {/* Avatar */}
                  {item.user?.avatar ? (
                    <img
                      src={item.user.avatar}
                      style={{ width: 60, height: 60, objectFit: 'cover', borderRadius: '50%' }}
                      alt="User Avatar"
                    />
                  ) : (
                    <Business style={{ width: 60, height: 60 }} />
                  )}

                  {/* Nombre */}
                  <Typography sx={{ fontSize: '0.9rem' }} variant="h6" align="center">
                    {item.user?.firstName || 'No Name'}
                  </Typography>
                  <Typography sx={{ fontSize: '0.9rem' }} variant="h6" align="center">
                    {item.user?.lastName || 'No Name'}
                  </Typography>
                </Box>
              </Item>
            </Grid>
          ))}
        </Grid>

        <Grid item xs={2} sm={2} md={2}>
          <Button
            onClick={handleAddItem}
            color="primary"
            sx={{ height: '16px', width: '16px', borderRadius: 20, marginLeft: '12px' }}>
            <Edit sx={{ fontSize: '16px' }} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
