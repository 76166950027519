import { useTranslation } from '@hooks/useTranslation';
import { User } from '@models/user.model';
import { Box, Button, Typography } from '@mui/material';

interface EnableUserModalProps {
  modalClose: () => void;
  enableUser: () => void;
  user: User | undefined;
}

//TODO: Maybe this can be consolidated with the DisableUserModal
const EnableUserModal: React.FC<EnableUserModalProps> = ({ modalClose, enableUser, user }) => {
  const localeCommon = useTranslation('common');
  const localeUsers = useTranslation('users');

  return (
    <Box>
      <Typography variant="h5">{localeUsers['enableUser']}</Typography>

      <Typography variant="h6">{localeUsers['confirmEnableMessage']}</Typography>

      <Typography variant="h6">{user?.email}</Typography>

      <Box className="flex justify-between">
        <Button onClick={modalClose} color="primary">
          {localeCommon['cancel']}
        </Button>
        <Button onClick={() => enableUser()} color="primary" autoFocus>
          {localeCommon['enable']}
        </Button>
      </Box>
    </Box>
  );
};

export default EnableUserModal;
