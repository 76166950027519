import actionMessagesReducer, {
  ActionMessagesAction,
  ActionMessagesState,
} from '@redux/reducers/actionMessages.reducer';
import localeReducer, { LocaleAction, LocaleState } from '@redux/reducers/locale.reducer';
import organizationReducer, { OrganizationAction, OrganizationState } from '@redux/reducers/organization.reducer';
import userReducer, { UserAction, UserState, UserTypes } from '@redux/reducers/user.reducer';
import { CombinedState, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const localePersistConfig = {
  key: 'locale',
  storage,
  whitelist: ['activeLocale'],
  blacklist: ['locale'],
};

const appReducer = combineReducers({
  actionMessages: actionMessagesReducer,
  locale: persistReducer(localePersistConfig, localeReducer),
  user: userReducer,
  organization: organizationReducer,
});

const rootReducer = (
  state:
    | CombinedState<{
        organization: OrganizationState;
        user: UserState;
        locale: LocaleState;
        actionMessages: ActionMessagesState;
      }>
    | undefined,
  action: UserAction | LocaleAction | ActionMessagesAction | OrganizationAction,
) => {
  if (action.type === UserTypes.LOGOUT_USER) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
