import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Project, deleteProjectRequest, getProjectsRequest } from '@models/project.model';
import { graphQlClient } from '@config/graphqlClient';
import ProjectsGraphQL from '@graphql/project.queries';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useAppDispatch } from '@redux/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import TimeLineComponent from '@components/timeLine/TimeLine';
import { Task } from '@models/task.model';
import { Event } from '@models/event.model';
import COLORS from '@utils/color';
import { Box, Typography } from '@mui/material';
import { Circle } from '@mui/icons-material';

interface AccordionUsageProps {
  setEvent: (id: string, events: Event[]) => void;
  setTask: (id: string, tasks: Task[]) => void;
  handleBack: () => void;
  toggleNewTaskModal: (idProject: string | null) => void;
  toggleNewEventModal: (idProject: string | null) => void;
}

const AccordionUsage: React.FC<AccordionUsageProps> = ({
  setEvent,
  setTask,
  handleBack,
  toggleNewTaskModal,
  toggleNewEventModal,
}) => {
  const [projects, setProjects] = React.useState<Project[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const localeCommon = useTranslation('common');

  React.useEffect(() => {
    getProjects(true);
  }, []);

  const getProjects = async (initialize = false) => {
    setLoading(true);
    try {
      const data: getProjectsRequest = await graphQlClient.request(ProjectsGraphQL.queries.getProjects);
      setProjects(data.getProjects);
      if (data.getProjects[0] && initialize) {
        // setSelectedItem(data.getProjects[0]);
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {projects.map((project) => {
        return (
          <Accordion
            key={project.projectId}
            style={{ background: 'lightgray', color: '#216ba5', borderColor: 'orange', padding: 0, margin: 1 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                margin: 0,
                padding: '0 10px',
                '&.Mui-expanded': {
                  backgroundColor: COLORS.veryLightBlue,
                  padding: '0 10px',
                  minHeight: '48px',
                  maxHeight: '48px',
                },
              }}>
              {project.name}
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0, margin: 0 }}>
              <TimeLineComponent
                isDelegated={project.originalProjectId ? true : false}
                setTask={setTask}
                setEvent={setEvent}
                projectId={project?.projectId}
                handleBack={handleBack}
                toggleNewTaskModal={toggleNewTaskModal}
                toggleNewEventModal={toggleNewEventModal}
              />
              <div
                style={{
                  display: 'flex',
                  backgroundColor: 'white',
                  height: 50,
                  width: 600,
                  position: 'fixed',
                  zIndex: 2,
                  right: 0,
                  bottom: 0,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 10,
                }}>
                <Box sx={{ display: 'flex' }}>
                  <Circle style={{ color: COLORS.lightBlue }} fontSize="small" />
                  <Typography sx={{}}>Competed</Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Circle style={{ color: COLORS.green }} fontSize="small" />
                  <Typography sx={{}}>On Track</Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Circle style={{ color: COLORS.yellow }} fontSize="small" />
                  <Typography sx={{}}>Delayed</Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Circle style={{ color: COLORS.orange }} fontSize="small" />
                  <Typography sx={{}}>On Hold</Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Circle style={{ color: COLORS.darkUltraGray }} fontSize="small" />
                  <Typography sx={{}}>Unassigned</Typography>
                </Box>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default AccordionUsage;
