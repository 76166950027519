import SidebarButton from '@components/ui/SidebarButton';
import { Box, CircularProgress, List, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CustomModal from '@components/generic/CustomModal';
import Button from '@components/ui/Button';
import { graphQlClient, graphQlUpload } from '@config/graphqlClient';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useAppDispatch } from '@redux/hooks';
import { Client, deleteClientRequest, getClientsRequest } from '@models/client.model';
import ClientsGraphQL from '@graphql/client.queries';
import CreateEditClientModal from '@components/client/CreateEditClientModal';
import { Business, Delete, Edit, EmailOutlined, PhoneEnabledOutlined } from '@mui/icons-material';
import COLORS from '@utils/color';
import DetailBox from '@components/detailBox/DetailBox';
import { useParams } from 'react-router-dom';

const styles = {
  name: {
    fontSize: 22,
    fontWeight: 'bold',
  },
};

interface RouteParams {
  id: string;
}

const ClientsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<RouteParams>();

  const [showNewClientModal, setShowNewClientModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Client | null>(null);
  const localeCommon = useTranslation('common');
  const [initialData, setInitialData] = useState<Client | null>(null);

  useEffect(() => {
    if (clients && clients?.length > 0 && id) {
      const initial = clients.filter((client) => client?.clientId === id);
      if (initial?.[0]) {
        setSelectedItem(initial[0]);
      }
    }
  }, [id, clients]);

  const editClient = () => {
    const initial = clients.filter((client) => client?.clientId === selectedItem?.clientId);
    setInitialData(initial[0]);
    setShowNewClientModal(true);
  };
  const toggleNewClientModal = () => {
    setShowNewClientModal(!showNewClientModal);
    if (!showNewClientModal) {
      setInitialData(null);
    }
  };

  const handleMenuItemClick = (client: Client) => {
    setSelectedItem(client);
  };

  const createOrUpdateClient = async (
    name: string,
    address: string,
    phone: string,
    email: string,
    avatar: File | null,
    projectIds?: string[],
    clientId?: string,
  ) => {
    setLoading(true);

    try {
      const query = clientId ? ClientsGraphQL.mutations.updateClient : ClientsGraphQL.mutations.createClient;
      const params = {
        client: {
          clientId,
          name,
          address,
          phone,
          email,
          projectIds,
        } as Client,
      };
      if (!clientId) {
        delete params.client.clientId;
      }
      const data = avatar
        ? await graphQlUpload(avatar, 'client', query, params.client)
        : await graphQlClient.request(query, params);
      if (data?.createClient?.clientId || data?.updateClient?.clientId) {
        const selected = (data?.createClient || data?.updateClient) as Client;
        setSelectedItem(selected);
        dispatch(
          appendActionMessage({
            message: clientId ? localeCommon['updatedSuccessfully'] : localeCommon['createdSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        getClients();
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const updateClient = async (
    name: string,
    address: string,
    phone: string,
    email: string,
    avatar: File | null,
    projectIds?: string[],
    clientId?: string,
  ) => {
    setLoading(true);
    try {
      const query = ClientsGraphQL.mutations.updateClient;
      const params = {
        client: {
          clientId,
          name,
          address,
          phone,
          email,
          projectIds,
        } as Client,
      };
      if (!clientId) {
        delete params.client.clientId;
      }
      const data = avatar
        ? await graphQlUpload(avatar, 'client', query, params.client)
        : await graphQlClient.request(query, params);
      if (data?.createClient?.clientId || data?.updateClient?.clientId) {
        const selected = (data?.createClient || data?.updateClient) as Client;
        setSelectedItem(selected);
        dispatch(
          appendActionMessage({
            message: clientId ? localeCommon['updatedSuccessfully'] : localeCommon['createdSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        getClients();
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteClient = async () => {
    if (window.confirm('Sure? Your changes will be lost')) {
      setLoading(true);

      try {
        const deleteResult: deleteClientRequest = await graphQlClient.request(ClientsGraphQL.mutations.deleteClient, {
          id: selectedItem?.clientId,
        });
        if (deleteResult?.deleteClient?.affected > 0) {
          dispatch(appendActionMessage({ message: localeCommon['deletedSuccessfully'], type: SnackType.SUCCESS }));
        }
      } catch (e: any) {
        dispatch(
          appendActionMessage({
            message: e?.response?.errors[0]?.message || localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
      } finally {
        getClients();
      }
    }
  };

  useEffect(() => {
    getClients(true);
  }, []);

  const getClients = async (initialize = false) => {
    setLoading(true);
    try {
      const data: getClientsRequest = await graphQlClient.request(ClientsGraphQL.queries.getClients);
      setClients(data.getClients);
      if (data.getClients[0] && initialize) {
        setSelectedItem(data.getClients[0]);
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ display: 'flex', flex: 1, backgroundColor: COLORS.veryLightBlue }}>
      <CustomModal isOpen={!!showNewClientModal} style={{ maxWidth: 580 }}>
        <CreateEditClientModal
          modalClose={toggleNewClientModal}
          createClient={createOrUpdateClient}
          editClient={updateClient}
          saving={false}
          initialData={initialData}
        />
      </CustomModal>

      <List
        className="flex flex-col items-start"
        sx={{
          marginTop: '0',
          paddingTop: 0,
          backgroundColor: '#c3cbcd',
          width: '240px',
          justifyContent: 'space-between',
          paddingBottom: 0,
        }}>
        <Box sx={{ overflow: 'scroll' }}>
          {clients.map((item) => (
            <SidebarButton
              sx={{ maxHeight: '49px', width: '240px', backgroundColor: '#5dc1e6' }}
              key={item.clientId}
              icon={<></>}
              labelStyle={{
                textWrap: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
                maxWidth: '184px',
                overflow: 'hidden',
              }}
              label={item.name}
              selected={selectedItem?.clientId === item.clientId}
              onClick={() => handleMenuItemClick(item)}
            />
          ))}
        </Box>
        <SidebarButton
          sx={{ maxHeight: '49px', width: '240px', border: '1px solid #5dc1e6', backgroundColor: '#1f282d' }}
          labelColor={'#5dc1e6'}
          key={'somekey'}
          icon={<AddIcon sx={{ color: '#5dc1e6' }} />}
          label={'New Client'}
          selected={false}
          onClick={() => toggleNewClientModal()}
        />
      </List>
      {selectedItem && (
        <Box className="my-6 mx-8" sx={{ flex: 1 }}>
          <Box className="mb-4" sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Typography sx={styles.name}>
                {selectedItem?.name}
                <Button
                  onClick={editClient}
                  color="primary"
                  sx={{ height: '16px', width: '16px', borderRadius: 20, marginLeft: '12px' }}>
                  <Edit sx={{ fontSize: '16px' }} />
                </Button>
                <Button
                  onClick={deleteClient}
                  color="error"
                  sx={{ height: '16px', width: '16px', borderRadius: 20, marginLeft: '12px' }}>
                  <Delete sx={{ fontSize: '16px', color: 'white' }} />
                </Button>
              </Typography>

              <Typography sx={{ fontSize: 14, fontWeigth: 'light', marginBottom: '4px' }}>
                {selectedItem?.address}
              </Typography>
              <Box className="flex">
                <Box className="flex" sx={{ alignItems: 'center' }}>
                  <PhoneEnabledOutlined sx={{ fontSize: '16px', color: COLORS.lightBlue, marginRight: '4px' }} />
                  <Typography fontSize={'14px'}>{selectedItem?.phone}</Typography>
                </Box>
                <Box className="flex" sx={{ alignItems: 'center', marginLeft: '16px' }}>
                  <EmailOutlined sx={{ fontSize: '16px', color: COLORS.lightBlue, marginRight: '4px' }} />
                  <Typography fontSize={'14px'}>{selectedItem?.email}</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {selectedItem?.logo ? (
                <img src={selectedItem?.logo} style={{ height: '100%', width: '100%' }} />
              ) : (
                <Business />
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ flex: 1, marginRight: '20px' }}>
              <Typography sx={{ marginBottom: '12px' }}>Active Projects</Typography>
              {selectedItem?.projects?.map((project) => {
                return (
                  <DetailBox
                    key={project.projectId}
                    title={project.name}
                    subtitle={project.address}
                    link={`/projects/${project.projectId}`}
                  />
                );
              }) || null}
            </Box>
            <Box sx={{ flex: 1, marginRight: '20px' }}>
              <Typography sx={{ marginBottom: '12px' }}>Current Invoices</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ClientsPage;
