import Avatar from '@components/ui/Avatar';
import { useTranslation } from '@hooks/useTranslation';
import { UserRoles } from '@models/user.model';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { useAppSelector } from '@redux/hooks';
import { RootState } from '@redux/store';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

interface AvatarDropdownProps {
  avatar: string;
}

const AvatarDropdown: React.FC<AvatarDropdownProps> = ({ avatar }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const localeMenu = useTranslation('menu');

  const { role } = useAppSelector((state: RootState) => state.user);

  const options = [
    { text: localeMenu['settings'], link: '/settings' },
    { text: localeMenu['logout'], link: '/logout' },
  ];

  // if (role === UserRoles.SUPER_ADMIN) {
  //   options.unshift({
  //     text: localeMenu['organizations'],
  //     link: `/organizations`,
  //   });
  // }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path: string) => {
    history.push(path);
    handleClose();
  };

  return (
    <Box className="flex items-center">
      <IconButton onClick={handleClick}>
        <Avatar size="40px" src={avatar || ''} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option) => (
          <MenuItem key={option.text} onClick={() => handleMenuItemClick(option.link)}>
            {option.text}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default AvatarDropdown;
