import SidebarButton from '@components/ui/SidebarButton';
import { Box, List, Typography } from '@mui/material';
import ListItemSubcontractor from '@components/listSubcontractor/listSubcontractor';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CustomModal from '@components/generic/CustomModal';
import { graphQlClient } from '@config/graphqlClient';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { useParams } from 'react-router-dom';
import COLORS from '@utils/color';
import { Activity } from '@models/activity.model';
import ActivitiesGraphQL from '@graphql/activity.queries';
import TasksGraphQL from '@graphql/task.queries';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton } from '@mui/material';
import { Task } from '@models/task.model';
import { RootState } from '@redux/store';
import { Organization } from '@models/organization.model';
import { idText } from 'typescript';

interface RouteParams {
  id: string;
}

const SubcontractorPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [delegateTask, setDelegateTask] = useState<Task[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [groupedActivities, setGroupedActivities] = useState<Activity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { organizationId } = useAppSelector((state: RootState) => state.organization);
  const limit = 20;
  const [showNewSubcontractorModal, setShowNewSubcontractorModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const localeCommon = useTranslation('common');
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const { id } = useParams<RouteParams>();

  const toggleNewSubcontractorModal = () => {
    setShowNewSubcontractorModal(!showNewSubcontractorModal);
  };

  const handleMenuItemClick = (delegateOrganization: Organization | undefined) => {
    setSelectedItem(delegateOrganization);
    setActivities([]);
    setGroupedActivities([]);
    setPage(1);
  };

  useEffect(() => {
    if (id && delegateTask.length > 0) {
      const initial = delegateTask.filter((delegate) => delegate?.delegateOrganization?.organizationId === id);
      if (initial?.[0].delegateOrganization) {
        setSelectedItem(initial?.[0].delegateOrganization);
        setPage(1);
        setActivities([]);
        setGroupedActivities([]);
      }
    }
  }, [id, delegateTask]);

  useEffect(() => {
    getTasksByOriginalOrganizationId(true, organizationId);
  }, []);

  useEffect(() => {
    if (selectedItem?.organizationId) {
      setActivities([]);
      setGroupedActivities([]);
      setPage(1);
      getActivities(true, selectedItem.organizationId, 1, limit);
    }
  }, [selectedItem]);

  const getActivities = async (initialize = false, organizationId: string, page: number, limit: number) => {
    try {
      const data: any = await graphQlClient.request(ActivitiesGraphQL.queries.getActivitiesByOrganizationId, {
        organizationId,
        page,
        limit,
      });
      setPages(Math.ceil(data.getActivitiesByOrganizationId.total / limit));
      setActivities([...activities, ...data.getActivitiesByOrganizationId.items]);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLoad = (newPage: number, id: string) => {
    setPage(newPage);
    getActivities(false, id, newPage, limit);
  };

  const getTasksByOriginalOrganizationId = async (initialize = false, organizationId: string | null) => {
    setLoading(true);
    try {
      const data: any = await graphQlClient.request(TasksGraphQL.queries.getTasksByOriginalOrganizationId, {
        originalOrganizationId: organizationId,
      });
      if (data?.getTasksByOriginalOrganizationId.length > 0) {
        setDelegateTask(data.getTasksByOriginalOrganizationId);
        setSelectedItem(data.getTasksByOriginalOrganizationId[0].delegateOrganization);
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();

    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    return isToday
      ? 'Today'
      : `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(
          2,
          '0',
        )}/${date.getFullYear()}`;
  };

  const groupByDate = (activities: any[]) => {
    return activities.reduce(
      (acc, activity) => {
        const dateKey = formatDate(activity.createdAt);

        if (!acc[dateKey]) acc[dateKey] = [];
        acc[dateKey].push(activity);
        return acc;
      },
      {} as Record<string, any[]>,
    );
  };

  useEffect(() => {
    setGroupedActivities(groupByDate(activities));
  }, [activities]);

  return (
    <Box sx={{ display: 'flex', flex: 1, backgroundColor: COLORS.veryLightBlue }}>
      <CustomModal isOpen={!!showNewSubcontractorModal} style={{ maxWidth: 580 }}>
        <IconButton
          onClick={() => setShowNewSubcontractorModal(false)}
          sx={{
            position: 'absolute',
            top: 2,
            right: 2,
          }}>
          <CloseOutlinedIcon />
        </IconButton>

        <Box
          sx={{
            backgroundColor: COLORS.white,
            border: `2px solid ${COLORS.lightBlue}`,
            marginBottom: 1,
            padding: '6px 18px',
            display: 'flex',
            cursor: 'pointer',
          }}>
          <Box sx={{ flex: 1 }}>
            <Typography textAlign={'center'} fontSize={16}>
              Subcontractors are listed when assigned to tasks.
            </Typography>
            <Typography textAlign={'center'} fontSize={16}>
              If you can’t find a subcontractor in your task definition, please contact support
              projectsupport@getzenhammer.com
            </Typography>
          </Box>
        </Box>
      </CustomModal>
      <List
        className="flex flex-col items-start"
        sx={{
          marginTop: '0',
          paddingTop: 0,
          backgroundColor: '#c3cbcd',
          width: '240px',
          justifyContent: 'space-between',
          paddingBottom: 0,
        }}>
        <Box sx={{ overflow: 'scroll' }}>
          {delegateTask.map((item) => (
            <SidebarButton
              sx={{
                maxHeight: '49px',
                width: '240px',
                backgroundColor: '#5dc1e6',
              }}
              key={item.taskId}
              delegated={false}
              icon={<></>}
              label={item?.delegateOrganization?.name}
              labelStyle={{
                textWrap: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
                maxWidth: '184px',
                overflow: 'hidden',
              }}
              selected={selectedItem?.organizationId === item?.delegateOrganization?.organizationId}
              onClick={() => handleMenuItemClick(item?.delegateOrganization)}
            />
          ))}
        </Box>
        <SidebarButton
          sx={{ maxHeight: '49px', width: '240px', backgroundColor: '#1f282d' }}
          labelColor={'#5dc1e6'}
          key={'somekey'}
          icon={<AddIcon sx={{ color: '#5dc1e6' }} />}
          label={'New Subcontractor'}
          selected={false}
          onClick={() => toggleNewSubcontractorModal()}
        />
      </List>
      {selectedItem && (
        <Box className="my-6 mx-8" sx={{ flex: 1 }}>
          <Box sx={{ flex: 1, justifyContent: 'space-between' }}>
            <ListItemSubcontractor
              delegateOrganizationId={selectedItem.organizationId}
              page={page}
              pages={pages}
              handleLoad={handleLoad}
              groupedActivities={groupedActivities}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SubcontractorPage;
