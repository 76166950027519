import { gql } from 'graphql-request';

export const CREW_FIELDS = gql`
  fragment CrewFields on CrewDto {
    crewId
    name
    employees {
      employeeId
      rate
      hireDate
      dob
      payPeriod
      user {
        firstName
        lastName
        avatar
        email
        userId
        role
      }
    }
    tasks {
      name
      startTime
      endTime
      projectId
      status
    }
  }
`;

export const getCrews = gql`
  query getCrews {
    getCrews {
      ...CrewFields
    }
  }

  ${CREW_FIELDS}
`;

export const createCrew = gql`
  mutation createCrew($crew: CrewCreateInput!) {
    createCrew(crew: $crew) {
      ...CrewFields
    }
  }
  ${CREW_FIELDS}
`;

export const updateCrew = gql`
  mutation updateCrew($crew: CrewUpdateInput!) {
    updateCrew(crew: $crew) {
      ...CrewFields
    }
  }
  ${CREW_FIELDS}
`;

export const deleteCrew = gql`
  mutation deleteCrew($id: String!) {
    deleteCrew(id: $id) {
      affected
      messages
    }
  }
`;

export const AssignEmployeesToCrew = gql`
  mutation AssignEmployeesToCrew($crewId: String!, $employeeIds: [String!]!) {
    assignEmployeesToCrew(crewId: $crewId, employeeIds: $employeeIds) {
      crewId
      name
      employees {
        employeeId
        rate
        hireDate
        dob
        payPeriod
        user {
          firstName
          lastName
          avatar
          email
          userId
        }
      }
    }
  }
`;
export const unassign = gql`
  mutation unassignEmployee($crewId: String!, $employeeId: String!) {
    unassignEmployee(crewId: $crewId, employeeId: $employeeId) {
      crewId
      name
    }
  }
`;

const CrewsGraphQL = {
  queries: {
    getCrews,
  },
  mutations: {
    createCrew,
    updateCrew,
    deleteCrew,
    AssignEmployeesToCrew,
    unassign,
  },
};

export default CrewsGraphQL;
