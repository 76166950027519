import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, IconButton, TextField, TextFieldProps, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import COLORS from '@utils/color';
import { InfoOutlined } from '@mui/icons-material';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInput-underline:before': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInput-root .MuiInput-input': {
    padding: '4px 4px',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInputBase-root': {
    boxSizing: 'border-box',
    height: '40px',
    '& .MuiInputBase-input': {
      padding: '8px',
      paddingLeft: '16px',
      height: '40px',
      boxSizing: 'border-box',
    },
  },
  '& .MuiOutlinedInput-root': {
    border: `1px solid ${theme.palette.grey[300]}`,
    transition: 'border-color 0.3s ease',
    '&:hover': {
      border: `1px solid ${theme.palette.grey[400]}`,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&.Mui-error fieldset': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '&.Mui-disabled fieldset': {
      border: `1px solid ${theme.palette.grey[200]}`,
      background: theme.palette.action.disabledBackground,
    },
  },
}));

interface AltInputProps<T> {
  label: string;
  name?: string;
  placeholder?: string;
  infoText?: string;
  showIcon?: boolean;
  icon?: React.ReactNode;
  iconAction?: () => void;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  value?: string | null | undefined;
  items: T[];
  valueKey: keyof T;
  labelKey: keyof T;
  onChange: (event: React.SyntheticEvent, value: T | null) => void;
}

export default function SelectStatusInput<T>({
  label,
  name,
  placeholder,
  infoText,
  showIcon = false,
  icon,
  iconAction,
  error = false,
  errorMessage,
  disabled,
  value,
  items,
  valueKey,
  labelKey,
  onChange,
  ...props
}: AltInputProps<T>) {
  const defaultValue = items.find((item) => item[valueKey] === value) || null;

  return (
    <Box className="flex w-full" sx={{ alignItems: 'center' }}>
      {label && (
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            width: 100,
            textAlign: 'right',
            marginRight: '10px',
            fontSize: '14px',
          }}>
          {label}
          {infoText && (
            <Tooltip title={infoText}>
              <IconButton size="small">
                <InfoOutlined sx={{ width: 18, height: 18 }} className="text-primaryDark" />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      )}
      <Autocomplete
        disabled={disabled}
        disablePortal
        options={items}
        value={defaultValue}
        onChange={(event: React.SyntheticEvent, value: T | null) => onChange(event, value)}
        getOptionLabel={(option) => option[labelKey] as unknown as string} // Muestra la etiqueta dinámica
        isOptionEqualToValue={(option, value) => option[valueKey] === value[valueKey]} // Compara usando el valor dinámico
        sx={{
          width: '100%',
          '& .MuiAutocomplete-input': {
            width: '100%',
          },
        }}
        renderInput={(params) => (
          <StyledTextField
            placeholder={placeholder}
            variant="standard"
            error={error}
            helperText={error ? errorMessage : ''}
            {...props}
            {...params}
          />
        )}
      />
    </Box>
  );
}
