import { UploadIcon24 } from '@components/ui/Icons';
import { VisuallyHiddenInput } from './VisuallyHiddenInput';
import { Avatar, Box, Button } from '@mui/material';

type ProfilePictureProps = {
  src?: string | null;
  name?: string;
  disabled?: boolean;
  handleImageChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const LogoPicture: React.FC<ProfilePictureProps> = ({ src, name, disabled, handleImageChange }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" margin={2}>
      <Avatar
        src={src || 'avatar_placeholder.png'}
        alt={name || 'User'}
        sx={{ width: 100, height: 100 }}
        variant="rounded"
      />{' '}
      {handleImageChange && (
        <Box>
          {disabled ? null : (
            <Button
              sx={{ margin: 2 }}
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<UploadIcon24 />}>
              Upload {name || 'Image'}
              <VisuallyHiddenInput type="file" accept="image/png, image/jpeg, image/gif" onChange={handleImageChange} />
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
export default LogoPicture;
