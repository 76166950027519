import ProfilePicture from '@components/user/ProfilePicture';
import { EditIcon24 } from '@components/ui/Icons';
import { graphQlClient, graphQlUpload } from '@config/graphqlClient';
import { getTokenLocalStorage, getTokenSessionStorage } from '@config/storage';
import UserGraphQL from '@graphql/user.queries';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { User, UserLocale, getSelfRequest, updateOwnUserRequest } from '@models/user.model';
import { DoneOutline, Save } from '@mui/icons-material';
import { Box, Button, IconButton, InputLabel, TextField, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { setLocale } from '@redux/reducers/locale.reducer';
import { RootState } from '@redux/store';
import React, { useEffect, useState } from 'react';

const SettingsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [userData, setUserData] = useState<User>({});
  const [saving, setSaving] = useState(false);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const localeActionMessages = useTranslation('actionMessages');
  const localeCommon = useTranslation('common');

  const { rememberMe } = useAppSelector((state: RootState) => state.user);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [userLocale, setUserLocale] = useState<UserLocale>(UserLocale.EN);

  useEffect(() => {
    getSelf();
  }, []);

  const getSelf = async () => {
    try {
      const data: getSelfRequest = await graphQlClient.request(UserGraphQL.queries.getSelf);
      setUserData(data.getSelf);
      dispatch(setLocale(data.getSelf.locale || UserLocale.EN));
      setUserLocale(data.getSelf.locale || UserLocale.EN);
      setFirstName(data.getSelf.firstName || '');
      setLastName(data.getSelf.lastName || '');
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setAvatar(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    if (avatar && imagePreview) {
      try {
        setSaving(true);
        const data = await graphQlUpload(avatar, 'user', UserGraphQL.mutations.updateOwnUser);
        setUserData({ ...userData, avatar: data.updateOwnUser.avatar });
        dispatch(
          appendActionMessage({ message: localeActionMessages['profileUpdatedSuccessfully'], type: SnackType.SUCCESS }),
        );
      } catch (e: any) {
        dispatch(
          appendActionMessage({
            message: e?.response?.errors[0]?.message || localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
      } finally {
        setSaving(false);
        setAvatar(null);
      }
    }
  };

  // TODO: Refactor update when the settings page is updated
  const handleDataUpdate = async (language?: UserLocale) => {
    try {
      setSaving(true);
      const updateUser: updateOwnUserRequest = await graphQlClient.request(UserGraphQL.mutations.updateOwnUser, {
        user: { firstName, lastName, locale: language },
      });
      setUserData({
        ...userData,
        firstName: updateUser.updateOwnUser.firstName,
        lastName: updateUser.updateOwnUser.lastName,
        locale: updateUser.updateOwnUser.locale,
      });
      setFirstName(updateUser.updateOwnUser.firstName || '');
      setLastName(updateUser.updateOwnUser.lastName || '');
      dispatch(
        appendActionMessage({ message: localeActionMessages['profileUpdatedSuccessfully'], type: SnackType.SUCCESS }),
      );
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setSaving(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setFirstName(userData.firstName || '');
    setLastName(userData.lastName || '');
  };

  const handleSaveClick = () => {
    handleDataUpdate();
    setIsEditing(false);
  };

  const handleUpdatePassword = async () => {
    if (!oldPassword) {
      return dispatch(appendActionMessage({ message: 'No oldPassword', type: SnackType.ERROR }));
    }
    if (!newPassword) {
      return dispatch(appendActionMessage({ message: 'No newPassword', type: SnackType.ERROR }));
    }

    const url = process.env.REACT_APP_BACKEND_URL + 'auth/updatePassword';
    const payload = {
      previousPassword: oldPassword,
      newPassword,
    };
    const token = rememberMe ? getTokenLocalStorage() : getTokenSessionStorage();

    if (url && token) {
      try {
        const auth = 'Bearer ' + token;
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: auth,
          },
          body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (data.confirmation && data.confirmation === '200') {
          dispatch(appendActionMessage({ message: 'Password reset', type: SnackType.SUCCESS }));
        } else {
          if (data.errorMessage) {
            dispatch(appendActionMessage({ message: data.errorMessage, type: SnackType.ERROR }));
          }
          if (data.error) {
            dispatch(appendActionMessage({ message: data.error, type: SnackType.ERROR }));
            // dispatch(logoutUser());
          }
        }
      } catch (error) {
        dispatch(appendActionMessage({ message: localeCommon['requestError'], type: SnackType.ERROR }));
        // dispatch(logoutUser());
      }
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
        <div>
          {isEditing ? (
            <div>
              <TextField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                label="First Name"
                variant="outlined"
                margin="normal"
              />
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                label="Last Name"
                variant="outlined"
                margin="normal"
                className="ml-2"
              />
              <IconButton className="ml-2" onClick={handleSaveClick}>
                <DoneOutline />
              </IconButton>
            </div>
          ) : (
            <Typography variant="h4" gutterBottom>
              {userData.firstName + ' ' + userData.lastName}
              <IconButton className="ml-2" onClick={handleEditClick}>
                <EditIcon24 />
              </IconButton>
            </Typography>
          )}
        </div>

        <ProfilePicture
          src={imagePreview || userData.avatar}
          name={userData.firstName}
          handleImageChange={handleImageChange}
        />
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<DoneOutline />}
          onClick={handleSave}
          disabled={!avatar || saving}
          sx={{ marginTop: 1 }}>
          {saving ? 'Saving...' : 'Save'}
        </Button>
      </Box>

      {/* TODO: Refactor this eventually */}
      {userData.loginMethod === 'cognito' && (
        <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
          <Box className="m-2">
            <InputLabel>Old Password</InputLabel>
            <TextField placeholder="oldPassword" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
          </Box>
          <Box className="m-2">
            <InputLabel>New Password</InputLabel>
            <TextField placeholder="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
          </Box>

          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<Save />}
            onClick={handleUpdatePassword}
            sx={{ marginTop: 1 }}>
            Update password
          </Button>
        </Box>
      )}
    </>
  );
};

export default SettingsPage;
