import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from '@fullcalendar/core';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './CalendarStyles.css';
import { Task, deleteTaskRequest, getTaskRequest } from '@models/task.model';
import { Event, deleteEventRequest, getEventRequest } from '@models/event.model';
import COLORS from '@utils/color';
import TasksGraphQL from '@graphql/task.queries';
import { graphQlClient } from '@config/graphqlClient';
import EventsGraphQL from '@graphql/event.queries';
import { SnackType } from '@models/common.model';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useAppDispatch } from '@redux/hooks';
import { useTranslation } from '@hooks/useTranslation';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Box, Button } from '@mui/material';
import CustomizedTables from '@components/crew/TaskAndEventTable';

interface Resource {
  id: string;
  title: string;
  start: string;
  end: string;
  isEvent: boolean;
  status?: string | null;
}

interface Evento {
  id: string;
  title: string;
  start: string;
  end: string;
  resourceId: string;
  color: string;
  textColor: string;
  isEvent: boolean;
  status?: string | null;
}

interface TimeLineComponentProps {
  projectId: string | undefined;
  setEvent: (id: string, events: Event[]) => void;
  setTask: (id: string, tasks: Task[]) => void;
  handleBack: () => void;
  toggleNewTaskModal: (idProject: string | null) => void;
  toggleNewEventModal: (idProject: string | null) => void;
  isDelegated: boolean;
}

interface RouteParams {
  id: string;
}

const TimeLineComponent: React.FC<TimeLineComponentProps> = ({
  isDelegated,
  projectId,
  setEvent,
  setTask,
  handleBack,
  toggleNewEventModal,
  toggleNewTaskModal,
}) => {
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const [tasksLocal, setTasksLocal] = useState<Task[]>([]);
  const [eventsLocal, setEventsLocal] = useState<Event[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const localeCommon = useTranslation('common');
  const { id } = useParams<RouteParams>();
  const [view, setView] = useState<string>('resourceTimelineMonth');
  const [showTaskList, setShowTaskList] = useState<boolean>(false);

  useEffect(() => {
    if (projectId) {
      getTasks(true, projectId);
      getEvents(true, projectId);
    }
  }, []);

  const getTasks = async (initialize = false, projectId: string | undefined) => {
    setLoading(true);
    try {
      const data: getTaskRequest = await graphQlClient.request(TasksGraphQL.queries.getTasks, { projectId });
      setTasksLocal(data.getTasks);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const getEvents = async (initialize = false, projectId: string | undefined) => {
    setLoading(true);
    try {
      const data: getEventRequest = await graphQlClient.request(EventsGraphQL.queries.getEvents, { projectId });
      setEventsLocal(data.getEvents);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleTaskDetail = (id: string) => {
    setTask(id, tasksLocal);
  };

  const combinedArray = [
    ...eventsLocal.map((event) => ({
      id: event.eventId || 'unknown-event-id',
      name: event.name,
      startTime: new Date(event.eventTime),
      endTime: event.delay
        ? new Date(new Date(event.eventTime).getTime() + event.delay * 60 * 60 * 1000)
        : new Date(event.eventTime),
      isEvent: true,
      eventType: event.eventType,
    })),
    ...tasksLocal.map((task) => ({
      id: task.taskId || 'unknown-task-id',
      name: task.name,
      startTime: new Date(task.startTime),
      endTime: new Date(task.endTime),
      isEvent: false,
      eventType: '',
      status: task.status,
      presedentTaskId: task.presedentTaskId,
      unassigned: task.unassigned,
    })),
  ];

  const tasksToReorder: any = combinedArray
    .filter((item) => !item.isEvent)
    .map((task, idx) => ({
      ...task,
      sortOrder: idx,
    }));

  const reordered = tasksToReorder.map((task: any) => {
    let sortOrder = task?.sortOrder;

    if (task.presedentTaskId && task.presedentTaskId.length > 0) {
      const preceedingTask = tasksToReorder
        .filter((t: any) => task.presedentTaskId?.includes(t.id))
        .map((t: any) => tasksToReorder.find((tt: any) => tt.id === t.id))
        .reduce((acc: any, curr: any) => {
          if (!acc || curr.endTime > acc.endTime) {
            return curr;
          }
          return acc;
        }, null);
      sortOrder = preceedingTask?.sortOrder + 1;
    }

    if (tasksToReorder.find((t: any) => t.presedentTaskId?.includes(task.id))) {
      sortOrder = sortOrder + 1;
    }

    return {
      ...task,
      sortOrder,
    };
  });

  const myResources: Resource[] = combinedArray.map((item, idx) => ({
    id: item.isEvent ? item?.eventType : (item.id as string),
    title: item.isEvent ? item?.eventType : item.name,
    start: item.startTime.toLocaleDateString('en-CA').replace(/-/g, '/'),
    end: item.endTime.toLocaleDateString('en-CA').replace(/-/g, '/'),
    isEvent: item.isEvent,
    sortOrder: item.isEvent ? 0 : reordered.find((task: any) => task.id === item.id)?.sortOrder,
  }));

  const myEvents: Evento[] = combinedArray.map((item: any) => ({
    id: item.id as string,
    title: item.name,
    start: item.startTime.toISOString(),
    end: item.endTime.toISOString(),
    resourceId: item.isEvent ? item?.eventType : (item.id as string),
    color: item.isEvent
      ? COLORS.eventOrange
      : item?.status === 'completed'
        ? COLORS.lightBlue
        : item?.status === 'On Track'
          ? COLORS.green
          : item?.status === 'Delayed'
            ? COLORS.yellow
            : item?.status === 'On Hold'
              ? COLORS.orange
              : item?.unassigned
                ? COLORS.darkGray
                : COLORS.darkUltraGray,
    textColor: 'white',
    isEvent: item.isEvent,
  }));

  const toggleView = () => setView(view === 'resourceTimelineMonth' ? 'dayGridMonth' : 'resourceTimelineMonth');
  const toggleTaskList = () => {
    setShowTaskList(!showTaskList);
  };

  const deleteTask = async (task: any) => {
    if (window.confirm('Sure? Your changes will be lost')) {
      setLoading(true);
      try {
        const deleteResult: deleteTaskRequest = await graphQlClient.request(TasksGraphQL.mutations.deleteTask, {
          id: task.id,
        });
        if (deleteResult?.deleteTask?.affected > 0) {
          dispatch(appendActionMessage({ message: localeCommon['deletedSuccessfully'], type: SnackType.SUCCESS }));
        }
      } catch (e: any) {
        dispatch(
          appendActionMessage({
            message: e?.response?.errors[0]?.message || localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
      } finally {
        getTasks(true, projectId);
      }
    }
  };

  const deleteEvent = async (event: Event) => {
    if (window.confirm('Sure? Your changes will be lost')) {
      setLoading(true);

      try {
        const deleteResult: deleteEventRequest = await graphQlClient.request(EventsGraphQL.mutations.deleteEvent, {
          id: event.eventId,
        });
        if (deleteResult?.deleteEvent?.affected > 0) {
          dispatch(appendActionMessage({ message: localeCommon['deletedSuccessfully'], type: SnackType.SUCCESS }));
        }
      } catch (e: any) {
        dispatch(
          appendActionMessage({
            message: e?.response?.errors[0]?.message || localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
      } finally {
        getEvents(true, projectId);
      }
    }
  };

  useEffect(() => {
    if (calendarRef.current) {
      let calendar = new Calendar(calendarRef.current, {
        plugins: [resourceTimelinePlugin, dayGridPlugin, interactionPlugin],
        schedulerLicenseKey: process.env.REACT_APP_FULLCALENDAR_KEY,
        initialView: view,
        editable: false,
        selectable: false,
        height: 'auto',
        customButtons: {
          backButton: {
            text: 'Back',
            click: function () {
              handleBack();
            },
          },
          toggleViewButton: {
            text: view === 'resourceTimelineMonth' ? 'Calendar View' : 'Gantt View',
            click: function () {
              toggleView();
            },
          },
          taskListButton: {
            text: 'View Task List',
            click: function () {
              toggleTaskList();
            },
          },
          addTaskButton: {
            text: '+ Add Task',
            click: function () {
              if (projectId) {
                toggleNewTaskModal(projectId);
              }
            },
          },
          addEventButton: {
            text: '+ Add Event',
            click: function () {
              if (projectId) {
                toggleNewEventModal(projectId);
              }
            },
          },
        },
        headerToolbar: {
          left: !!id ? 'backButton toggleViewButton taskListButton' : 'toggleViewButton taskListButton',
          center: 'prev title next',
          right: isDelegated ? '' : 'addTaskButton addEventButton',
        },
        resources: myResources.map((resource) => ({
          ...resource,
          extendedProps: {
            start: resource.start,
            end: resource.end,
          },
        })),
        events: myEvents.map((event) => ({
          ...event,
          resourceId: event.resourceId,
          className: 'custom-event',
        })),
        eventContent: (args) => {
          if (view === 'resourceTimelineMonth') {
            const { title } = args.event._def;
            const resourceId = args.event._def?.resourceIds?.[0] ?? 'No Resource ID';
            let content = ' ';

            if (resourceId && resourceId === 'Incident') {
              content = `<span class="icon-container">⚠️</span>`;
            }

            if (resourceId && resourceId === 'Inspection') {
              content = `<span class='icon-container'>📋</span>`;
            }

            if (resourceId && resourceId === 'Visitor') {
              content = `<span class="icon-container">👤</span>`;
            }

            if (resourceId && resourceId === 'Permit') {
              content = `<span class='icon-container'>🔐</span>`;
            }

            if (resourceId && resourceId === 'Communucation') {
              content = `<span class='icon-container'>ℹ️</span>`;
            }

            return { html: content };
          }
          return true;
        },
        resourceAreaColumns: [
          {
            field: 'title',
            headerContent: 'Taks',
            width: 60,
          },
          {
            field: 'startDate',
            headerContent: 'Start',
            width: 30,
            cellContent: (args) => {
              return args.resource?.extendedProps?.isEvent
                ? '⚠️'
                : args.resource?.extendedProps?.start
                  ? format(args.resource?.extendedProps?.start, 'MM/dd/yy')
                  : 'No Start Date';
            },
          },
          {
            field: 'endDate',
            headerContent: 'End',
            width: 30,
            cellContent: (args) => {
              return args.resource?.extendedProps?.isEvent
                ? ''
                : args.resource?.extendedProps?.end
                  ? format(args.resource?.extendedProps?.end, 'MM/dd/yy')
                  : 'No End Date';
            },
          },
          {
            field: 'Days',
            headerContent: 'Days',
            width: 20,
            cellContent: (args) => {
              const endDate = new Date(args.resource?.extendedProps?.end);
              const startDate = new Date(args.resource?.extendedProps?.start);
              const differenceInMs = endDate.getTime() - startDate.getTime();
              const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
              return args.resource?.extendedProps?.isEvent ? '' : (differenceInDays ?? 'No End Date');
            },
          },
        ],
        resourceOrder: '-isEvent, sortOrder, start',
        eventClick: (info) => {
          if (info.event.id && info.event._def.resourceIds) {
            console.log('info.event._def.resourceIds[0]', info.event._def.resourceIds[0]);
            if (
              info.event._def.resourceIds[0] === 'Incident' ||
              info.event._def.resourceIds[0] === 'Inspection' ||
              info.event._def.resourceIds[0] === 'Visitor' ||
              info.event._def.resourceIds[0] === 'Permit' ||
              info.event._def.resourceIds[0] === 'Communication' ||
              info.event._def.resourceIds[0] === 'Other'
            ) {
              setEvent(info.event.id, eventsLocal);
            } else {
              setTask(info.event.id, tasksLocal);
            }
          }
        },
        resourceLabelContent: (arg) => {
          if (
            arg.resource.title.toLowerCase() === 'incident' ||
            arg.resource.title.toLowerCase() === 'inspection' ||
            arg.resource.title.toLowerCase() === 'visitor' ||
            arg.resource.title.toLowerCase() === 'permit' ||
            arg.resource.title.toLowerCase() === 'communication' ||
            arg.resource.title.toLowerCase() === 'other'
          ) {
            return { html: `<span class="resource-incident">${arg.resource.title}</span>` };
          }
          return { html: arg.resource.title };
        },
      });

      calendar.render();

      return () => {
        calendar.destroy();
      };
    }
  }, [myResources, myEvents, view]);

  if (showTaskList) {
    return (
      <div style={{ backgroundColor: 'white', padding: '20px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: '10px',
          }}>
          <h2>Task List</h2>
          <Button variant="contained" onClick={() => toggleTaskList()}>
            Hide Task List
          </Button>
        </Box>
        <CustomizedTables
          deleteEvent={deleteEvent}
          deleteTask={deleteTask}
          tasks={combinedArray}
          handleTaskDetail={handleTaskDetail}
        />
      </div>
    );
  }

  return <div ref={calendarRef} style={{ maxWidth: '100%', height: '80vh' }} />;
};

export default TimeLineComponent;
