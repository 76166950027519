import { UploadIcon24 } from '@components/ui/Icons';
import { VisuallyHiddenInput } from '@components/generic/VisuallyHiddenInput';
import { Avatar, Box, Button } from '@mui/material';

type ProfilePictureProps = {
  src?: string;
  name?: string;
  handleImageChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ProfilePicture: React.FC<ProfilePictureProps> = ({ src, name, handleImageChange }) => {
  return (
    <>
      <Avatar src={src || 'default-avatar-url.png'} alt={name || 'User'} sx={{ width: 100, height: 100 }} />{' '}
      {handleImageChange && (
        <Box>
          <Button
            sx={{ margin: 2 }}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<UploadIcon24 />}>
            Change Avatar
            <VisuallyHiddenInput type="file" accept="image/png, image/jpeg, image/gif" onChange={handleImageChange} />
          </Button>
        </Box>
      )}
    </>
  );
};
export default ProfilePicture;
