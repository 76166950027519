import React, { useState, useRef } from 'react';
import { CalendarMonthOutlined, InfoOutlined } from '@mui/icons-material';
import {
  BaseTextFieldProps,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import COLORS from '@utils/color';
import Popper from '@mui/material/Popper';
import { IMaskInput } from 'react-imask';
import IMask from 'imask';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInput-underline:before': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInputBase-root': {
    boxSizing: 'border-box',
    height: '40px',
    '& .MuiInputBase-input': {
      padding: '8px 16px',
      height: '40px',
      boxSizing: 'border-box',
    },
  },
  '& .MuiInputLabel-root': {
    display: 'none',
  },
  '& .MuiOutlinedInput-root': {
    border: `1px solid ${theme.palette.grey[300]}`,
    transition: 'border-color 0.3s ease',
    '&:hover': {
      border: `1px solid ${theme.palette.grey[400]}`,
    },
    '&.Mui-focused': {
      outline: 'none',
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&.Mui-error fieldset': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '&.Mui-disabled fieldset': {
      color: theme.palette.grey[500],
      border: `1px solid ${theme.palette.grey[200]}`,
      background: theme.palette.action.disabledBackground,
    },
  },
}));

interface AltInputProps extends BaseTextFieldProps {
  label: string;
  name?: string;
  placeholder?: string;
  infoText?: string;
  showIcon?: boolean;
  icon?: React.ReactNode;
  iconAction?: () => void;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  showTimeSelect?: boolean;
}

const InputMask = React.forwardRef<HTMLInputElement, any>((props, ref) => {
  const { showTimeSelect } = props;
  return (
    <IMaskInput
      {...props}
      inputRef={ref}
      mask={showTimeSelect ? 'MM{/}DD{/}YYYY hh:mm a' : 'MM{/}DD{/}YYYY'}
      blocks={{
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
        },
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1900,
          to: 9999,
        },
        hh: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
        },
        mm: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59,
        },
        a: {
          mask: IMask.MaskedEnum,
          enum: ['AM', 'PM'],
        },
      }}
    />
  );
});

const DateInput: React.FC<AltInputProps> = ({
  label,
  name,
  placeholder,
  infoText,
  showIcon = false,
  icon,
  iconAction,
  error = false,
  errorMessage,
  disabled,
  onChange,
  showTimeSelect = false,
  ...props
}) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const anchorEl = useRef<HTMLButtonElement | null>(null);

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);

    if (onChange && date) {
      const formattedDate = format(date, showTimeSelect ? 'MM/dd/yyyy hh:mm aa' : 'MM/dd/yyyy');
      const event = {
        target: {
          value: formattedDate,
          name,
        },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange(event);
    }
    setDatePickerOpen(false);
  };

  return (
    <Box className="flex w-full" sx={{ alignItems: 'center' }}>
      {label && (
        <Typography
          variant="body2"
          gutterBottom
          sx={{ width: 90, textAlign: 'right', paddingRight: '11px', fontSize: '14px' }}>
          {label}
          {infoText && (
            <Tooltip title={infoText}>
              <IconButton size="small">
                <InfoOutlined sx={{ width: 18, height: 18 }} className="text-primaryDark" />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      )}
      <StyledTextField
        placeholder={placeholder}
        error={error}
        helperText={error ? errorMessage : ''}
        variant="standard"
        onChange={onChange}
        disabled={disabled}
        name={name}
        value={selectedDate ? format(selectedDate, showTimeSelect ? 'MM/dd/yyyy hh:mm aa' : 'MM/dd/yyyy') : ''}
        InputProps={{
          inputComponent: InputMask as any,
          inputProps: {
            showTimeSelect,
          },
          ...(showIcon && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" ref={anchorEl} onClick={() => setDatePickerOpen((prev) => !prev)}>
                  <CalendarMonthOutlined sx={{ width: 22, height: 24, color: COLORS.lightBlue }} />
                </IconButton>
              </InputAdornment>
            ),
          }),
        }}
        {...props}
      />
      <Popper open={datePickerOpen} anchorEl={anchorEl.current} placement="bottom-start" style={{ zIndex: 1300 }}>
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          onClickOutside={() => setDatePickerOpen(false)}
          dateFormat={showTimeSelect ? 'MM/dd/yyyy hh:mm aa' : 'MM/dd/yyyy'}
          showTimeSelect={showTimeSelect}
          yearDropdownItemNumber={70}
          showYearDropdown
          scrollableYearDropdown
          inline
        />
      </Popper>
    </Box>
  );
};

export default DateInput;
