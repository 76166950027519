import locales from '@locales';
import { PersistPartial } from 'redux-persist/es/persistReducer';

export const LocaleTypes = {
  SET_LOCALE: 'SET_LOCALE',
};

export const setLocale = (locale: string) => ({
  type: LocaleTypes.SET_LOCALE,
  data: locale,
});

export type LocaleState = {
  activeLocale: string;
  locale: { [key: string]: any };
} & PersistPartial;

// INITIAL STATE
const initialLanguage: string = ['en', 'es'].includes(navigator.language.split('-')[0])
  ? navigator.language.split('-')[0]
  : 'en';
const localeInitialState: LocaleState & PersistPartial = {
  activeLocale: initialLanguage,
  locale: {
    ...(locales as { [key: string]: any })[initialLanguage],
  },
  _persist: {
    version: 0,
    rehydrated: false,
  },
};

export type LocaleAction = {
  type: string;
  data: string;
};

const localeReducer = (state = localeInitialState, action: LocaleAction) => {
  switch (action.type) {
    case LocaleTypes.SET_LOCALE:
      return {
        ...state,
        activeLocale: action.data,
        locale: (locales as { [key: string]: any })[action.data],
      };

    default:
      return state;
  }
};

export default localeReducer;
