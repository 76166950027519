import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {
  BaseTextFieldProps,
  Box,
  Chip,
  IconButton,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import COLORS from '@utils/color';
import { InfoOutlined } from '@mui/icons-material';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInput-underline:before': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInput-root .MuiInput-input': {
    padding: '4px 4px',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInputBase-root': {
    boxSizing: 'border-box',
    height: '40px',
    '& .MuiInputBase-input': {
      padding: '8px 16px',
      height: '40px',
      boxSizing: 'border-box',
    },
  },
  '& .MuiInputLabel-root': {
    display: 'none',
  },
  '& .MuiOutlinedInput-root': {
    border: `1px solid ${theme.palette.grey[300]}`,
    transition: 'border-color 0.3s ease',
    '&:hover': {
      border: `1px solid ${theme.palette.grey[400]}`,
    },
    '&.Mui-focused': {
      outline: 'none',
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&.Mui-error fieldset': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '&.Mui-disabled fieldset': {
      color: theme.palette.grey[500],
      border: `1px solid ${theme.palette.grey[200]}`,
      background: theme.palette.action.disabledBackground,
    },
  },
}));

interface AltInputProps<T> extends BaseTextFieldProps {
  label: string;
  name?: string;
  placeholder?: string;
  infoText?: string;
  showIcon?: boolean;
  icon?: React.ReactNode;
  iconAction?: () => void;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  value: T[] | undefined;
  items: T[];
  getOptionLabel: (option: T) => string;
  isOptionEqualToValue: (option: T, value: T) => boolean;
  onChange: (event: React.SyntheticEvent, value: T[]) => void;
  onChangeCoor?: (lat?: number | null, long?: number | null) => void;
}

export default function AutocompleteMultipleInput<T>({
  label,
  name,
  placeholder,
  infoText,
  showIcon = false,
  icon,
  iconAction,
  error = false,
  errorMessage,
  disabled,
  value,
  items,
  getOptionLabel,
  isOptionEqualToValue,
  onChange,
  onChangeCoor,
  ...props
}: AltInputProps<T>) {
  return (
    <Box className="flex w-full" sx={{ alignItems: 'center' }}>
      {label && (
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            width: 100,
            textAlign: 'right',
            marginRight: '10px',
            fontSize: '14px',
          }}>
          {label}
          {infoText && (
            <Tooltip title={infoText}>
              <IconButton size="small">
                <InfoOutlined sx={{ width: 18, height: 18 }} className="text-primaryDark" />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      )}
      <Autocomplete
        multiple
        id="size-small-filled-multi"
        size="small"
        options={items}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={onChange}
        value={value}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return <Chip key={key} variant="outlined" label={getOptionLabel(option)} size="small" {...tagProps} />;
          })
        }
        renderInput={(params) => (
          <StyledTextField
            {...params}
            {...props}
            variant="standard"
            multiline
            InputProps={{
              ...params.InputProps,
              style: { maxHeight: 'auto' },
            }}
            sx={{
              width: '100%',
              height: 'auto',
              '& .MuiInputBase-root': {
                height: 'auto',
                padding: '8px',
                alignItems: 'flex-start',
              },
            }}
          />
        )}
        sx={{
          width: '100%',
          '& .MuiAutocomplete-input': {
            width: '100%',
          },
        }}
      />
    </Box>
  );
}
