import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { CircularProgress } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { SnackType } from '@models/common.model';
import { useTranslation } from '@hooks/useTranslation';
import ProjectsGraphQL from '@graphql/project.queries';
import { graphQlClient } from '@config/graphqlClient';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  height: '34px',
  border: 'none',
  color: '#05c1ff',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#edf4f5',
    color: '#859194',
    fontSize: 12,
    padding: '4px 8px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '4px 8px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  height: '34px',
  border: 'none',
  '&:nth-of-type(odd)': {
    backgroundColor: '#ffffff',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#edf4f5',
  },
}));

interface CustomizedTableProps {
  projectIds: (string | undefined)[];
}

interface Project {
  projectId: string;
  name: string;
}

interface ProjectRequest {
  getProjectByIDs?: any;
}

export default function ProjectTable({ projectIds }: CustomizedTableProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const localeCommon = useTranslation('common');
  const dispatch = useAppDispatch();

  useEffect(() => {
    getProjects(true);
  }, [projectIds]);

  const getProjects = async (initialize = false) => {
    setLoading(true);
    const variables = { projectIds };
    try {
      const data: ProjectRequest = await graphQlClient.request(ProjectsGraphQL.queries.getProjectsByIds, variables);

      setProjects(data.getProjectByIDs);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
