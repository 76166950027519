import { Box, BoxProps, Chip as MuiChip } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import React from 'react';

interface ChipProps extends BoxProps {
  selected: boolean;
  label: string;
  //chipSize to avoid conflict with ChipProps so we just have our custom sizes
  chipsize?: '40px' | '32px';
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  sx?: SxProps;
}

const IconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
}));

const StyledBox = styled(Box)<ChipProps>(({ theme, selected, chipsize }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: selected ? theme.palette.primaryDark.main : theme.palette.common.white,
  color: selected ? theme.palette.common.white : theme.palette.primaryDark.main,
  height: chipsize,
  '&:hover': {
    backgroundColor: selected ? theme.palette.frozenGrayShades7.main : theme.palette.frozenGrayShades2.main,
  },
  borderRadius: '100px',
  width: 'fit-content',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  margin: theme.spacing(0),
  fontSize: '14px',
  fontWeight: '600',
}));

const StyledChip = styled(MuiChip)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: 'inherit',
  '& .MuiChip-label': {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const Chip: React.FC<ChipProps> = ({ selected, chipsize = '32px', leftIcon, rightIcon, label, ...props }) => {
  return (
    <StyledBox selected={selected} chipsize={chipsize} label={label} {...props}>
      {leftIcon && <IconContainer>{leftIcon}</IconContainer>}
      <StyledChip label={label} />
      {rightIcon && <IconContainer>{rightIcon}</IconContainer>}
    </StyledBox>
  );
};

export default Chip;
