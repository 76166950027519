const en = {
  login: {
    login: 'Log In',
    loginText: 'Please log in to access your Zenhammer dashboard.',
    email: 'Email',
    enterEmail: 'Enter your email',
    writePasword: 'Write your password',
    password: 'Password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password?',
    sendResetCode: 'Send reset code',
    forgotPasswordMessage: 'Enter your email to receive a reset code',
    signIn: 'Sign In',
    resetPassword: 'Reset Password',
    resetPasswordMessage: 'Enter the reset code you received and your new password',
    passwordResetCode: 'Password reset code',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    enterPasswordResetCode: 'Enter the password reset code you received',
    writeNewPassword: 'Write a new password',
    confirmNewPassword: 'Confirm the new password',
    passwordsDoNotMatch: 'Passwords do not match',
    enterTempPassword: 'Enter the temporary password you received',
    tempPassword: 'Temporary password',
    confirmUserMessage: 'Looks like you are doing the initial login, please enter a new password below',
    initialLoginMessage: 'You should have received the welcome message, please enter the password below',
  },
  //TODO: I would refactor these using element + action to avoid so much repetition
  actionMessages: {
    deleteSuccessful: 'Delete successful',
    updateSuccessful: 'Update successful',
    profileUpdatedSuccessfully: 'Profile updated successfully',
    organizationCreatedSuccessfully: 'Organization created successfully',
    organizationEditedSuccessfully: 'Organization edited successfully',
    organizationEnabledSuccessfully: 'Organization enabled successfully',
    organizationDisabledSuccessfully: 'Organization deleted successfully',
    userCreatedSuccessfully: 'User created successfully',
    userEditedSuccessfully: 'User edited successfully',
    userDisabledSuccessfully: 'User deleted successfully',
    userEnabledSuccessfully: 'User enabled successfully',
    loginNoEmail: 'No email, enter your email and click "Forgot password" again',
  },
  menu: {
    dashboard: 'Dashboard',
    clients: 'Clients',
    employees: 'Employees',
    crews: 'Crews',
    subcontractors: 'Subcontractors',
    projects: 'Projects',
    inventory: 'Inventory',
    reports: 'Reports',
    invoices: 'Invoices',
    schedule: 'Schedule',
    permits: 'Permits',
    insurance: 'Insurance',
    organizations: 'Organizations',
    settings: 'Settings',
    logout: 'Logout',
    users: 'Users',
    searchResultsMinLength: 'Type at least three characters',
  },
  settings: {
    changeAvatar: 'Change Avatar',
    saveAvatar: 'Save Avatar',
    changePassword: 'Change Password',
    oldPassword: 'Current Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    updatePassword: 'Update Password',
    saveName: 'Save Name',
    saveLanguage: 'Save Language',
    english: 'English',
    spanish: 'Spanish',
  },
  common: {
    createdSuccessfully: 'Created successfully',
    updatedSuccessfully: 'Updated successfully',
    deletedSuccessfully: 'Deleted successfully',
    action: 'Action',
    actions: 'Actions',
    active: 'Active',
    add: 'Add',
    alert: 'Alert',
    all: 'All',
    back: 'Back',
    cancel: 'Cancel',
    cannotDelete: 'Cannot delete if there are related entities',
    clickDelete: 'Click here to delete',
    clickEdit: 'Click here to edit',
    clickShowDoc: 'Click to show in google docs',
    clickView: 'Click here to view',
    close: 'Close',
    collapse: 'Collapse',
    color: 'Color',
    confirm: 'Confirm',
    create: 'Create',
    custom: 'Custom',
    delete: 'Delete',
    deleted: 'Deleted',
    description: 'Description',
    disable: 'Disable',
    disabled: 'Disabled',
    documents: 'Documents',
    edit: 'Edit',
    saveChanges: 'Save Changes',
    email: 'Email',
    enable: 'Enable',
    end: 'End',
    expand: 'Expand',
    export: 'Export',
    import: 'Import',
    name: 'Name',
    none: 'None',
    notNow: 'Not Now',
    openDrawer: 'Open Drawer',
    optional: 'Optional',
    options: 'Options',
    organization: 'Organization',
    owner: 'Owner',
    preview: 'Preview',
    requestError: 'Network request failed',
    somethingWentWrong: 'Something went wrong, please refresh and try again.',
    save: 'Save',
    show: 'Show',
    step: 'Step',
    submit: 'Submit',
    triggers: 'Triggers',
    update: 'Update',
    user: 'User',
    yes: 'Yes',
    first: 'First',
    second: 'Second',
    third: 'Third',
    fourth: 'Fourth',
    last: 'Last',
  },
  organizations: {
    title: 'Organizations',
    organization: 'Organization',
    ownersNames: 'Owners Names',
    ownersEmails: 'Owners Emails',
    addOrganization: 'Add Organization',
    create: 'Create',
    enableOrganization: 'Enable Organization',
    disableOrganization: 'Disable Organization',
    confirmDisableMessage: 'Are you sure that you want to disable the organization?',
    confirmEnableMessage: 'Are you sure that you want to enable the organization?',
  },
  users: {
    title: 'Users',
    role: 'Role',
    firstName: 'First Name',
    status: 'Status',
    lastName: 'Last Name',
    addUser: 'Add User',
    disableUser: 'Disable User',
    editUser: 'Edit User',
    confirmDisableMessage: 'Are you sure that you want to disable the user?',
    confirmEnableMessage: 'Are you sure that you want to enable the user?',
    notAllowedToEdit: 'You are not allowed to edit this user',
    locale: 'Locale',
  },
  clients: {
    client: 'Client',
    addClient: 'Add New Client',
    name: 'Name',
    address: 'Address',
    phone: 'Phone',
    email: 'Email',
    phoneErrorMessage: 'invalid phone format',
    emailErrorMessage: 'invalid email format',
    addressErrorMessage: 'The address must be at least 3 characters long',
    nameErrorMessage: 'Name must be at least 3 characters long',
  },
  projects: {
    project: 'Project',
    addProject: 'Add New Project',
    name: 'Name',
    address: 'Address',
    nameErrorMessage: 'Name must be at least 3 characters long',
    addressErrorMessage: 'The address must be at least 3 characters long',
    startErrorMessage: 'Please enter a valid date in the format MM/DD/YYYY',
    endErrorMessage: 'Please enter a valid date in the format MM/DD/YYYY',
    rangeErrorMessage: 'End date cannot be before start date',
    budgetErrorMessage: 'Please enter a number greater than 0',
  },
};

export default en;
