import SidebarButton from '@components/ui/SidebarButton';
import { Box, CircularProgress, FormControlLabel, List, Stack, Typography, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CustomModal from '@components/generic/CustomModal';
import Button from '@components/ui/Button';
import { graphQlClient, graphQlUpload } from '@config/graphqlClient';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useAppDispatch } from '@redux/hooks';
import { deleteProjectRequest } from '@models/project.model';
import ProjectsGraphQL from '@graphql/project.queries';
import CreateEditEmployeeModal from '@components/employee/CreateEditEmployeeModal';
import { Business, Delete, Edit } from '@mui/icons-material';
import useFormattedDate from '@hooks/useFormattedDate';
import useFormattedCurrency from '@hooks/useFormattedCurrency';
import { useHistory, useParams } from 'react-router-dom';
import COLORS from '@utils/color';
import { Employee, getEmployeesRequest } from '@models/employee.model';
import EmployeesGraphQL from '@graphql/employee.queries';
import { UserRoles } from '@models/user.model';

const styles = {
  name: {
    fontSize: 22,
    fontWeight: 'bold',
  },
};

interface RouteParams {
  id: string;
}

const EmployeesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { id } = useParams<RouteParams>();

  const [showNewEmployeeModal, setShowNewEmployeeModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Employee | null>(null);
  const localeCommon = useTranslation('common');
  const [initialData, setInitialData] = useState<any | null>(null);
  const formattedHireData = useFormattedDate(selectedItem?.hireDate || null);
  const formattedDoB = useFormattedDate(selectedItem?.dob || null);
  const formattedCurrency = useFormattedCurrency(selectedItem?.rate || null);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (employees && employees?.length > 0 && id) {
      const initial = employees.filter((employee) => employee?.employeeId === id);
      if (initial?.[0]) {
        setSelectedItem(initial[0]);
      }
    }
  }, [id, employees]);

  const editEmployee = () => {
    setInitialData(selectedItem);
    setShowNewEmployeeModal(true);
  };
  const toggleNewEmployeeModal = () => {
    setShowNewEmployeeModal(!showNewEmployeeModal);
    if (!showNewEmployeeModal) {
      setInitialData(null);
    } else {
      setErrorMessage(undefined);
    }
  };

  const handleMenuItemClick = (employee: Employee) => {
    setSelectedItem(employee);
  };

  const createOrUpdateProject = async (
    firstName: string,
    lastName: string,
    email: string,
    dob: Date | string,
    hireDate: Date | string,
    rate: number | null,
    selectedPayPeriod: string,
    selectedMode: string | null,
    roleType: string | null,
    avatar?: File | null,
    employeeId?: string,
    userId?: string | null,
  ) => {
    setLoading(true);
    let errorMessage = undefined;

    try {
      const query = employeeId ? EmployeesGraphQL.mutations.updateEmployee : EmployeesGraphQL.mutations.createEmployee;
      const params = {
        employee: {
          employeeId,
          firstName,
          lastName,
          email,
          dob,
          hireDate,
          rate,
          selectedPayPeriod,
          selectedMode,
          roleType,
          userId,
        } as Employee,
      };
      if (!employeeId) {
        delete params.employee.employeeId;
      }
      const data = avatar
        ? await graphQlUpload(avatar, 'employee', query, params.employee)
        : await graphQlClient.request(query, params);
      if (data?.createEmployee?.employeeId || data?.updateEmployee?.employeeId) {
        const selected = (data?.createEmployee || data?.updateEmployee) as Employee;
        setSelectedItem(selected);
        dispatch(
          appendActionMessage({
            message: employeeId ? localeCommon['updatedSuccessfully'] : localeCommon['createdSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        getEmployees();
      }
    } catch (e: any) {
      errorMessage = (e?.response?.errors[0]?.message || localeCommon['requestError']).replace('in cognito', '');
      if (e?.response?.errors[0]?.message.includes('in cognito')) {
        setErrorMessage('Error creating employee: Email already in use');
      } else {
        dispatch(
          appendActionMessage({
            message: errorMessage,
            type: SnackType.ERROR,
          }),
        );
      }
    } finally {
      setLoading(false);
      if (!errorMessage) {
        toggleNewEmployeeModal();
      }
    }
  };

  const deleteEmployee = async () => {
    if (window.confirm('Sure? Your changes will be lost')) {
      setLoading(true);

      try {
        const deleteResult: deleteProjectRequest = await graphQlClient.request(
          ProjectsGraphQL.mutations.deleteProject,
          { id: selectedItem?.employeeId },
        );
        if (deleteResult?.deleteProject?.affected > 0) {
          dispatch(appendActionMessage({ message: localeCommon['deletedSuccessfully'], type: SnackType.SUCCESS }));
        }
      } catch (e: any) {
        dispatch(
          appendActionMessage({
            message: e?.response?.errors[0]?.message || localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
      } finally {
        getEmployees();
      }
    }
  };

  useEffect(() => {
    getEmployees(true);
  }, []);

  const getEmployees = async (initialize = false) => {
    setLoading(true);
    try {
      const data: getEmployeesRequest = await graphQlClient.request(EmployeesGraphQL.queries.getEmployees);
      setEmployees(data.getEmployees);
      if (data.getEmployees[0] && initialize) {
        setSelectedItem(data.getEmployees[0]);
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading && !showNewEmployeeModal) {
    return <CircularProgress />;
  }

  const navigateToClient = () => {
    history.push(`/clients/${selectedItem?.employeeId}`);
  };

  return (
    <Box sx={{ display: 'flex', flex: 1, backgroundColor: COLORS.veryLightBlue }}>
      <CustomModal isOpen={!!showNewEmployeeModal} style={{ maxWidth: 580 }}>
        <CreateEditEmployeeModal
          modalClose={toggleNewEmployeeModal}
          createProject={createOrUpdateProject}
          editProject={createOrUpdateProject}
          saving={false}
          initialData={initialData}
          errorMessage={errorMessage}
          loading={loading}
        />
      </CustomModal>

      <List
        className="flex flex-col items-start"
        sx={{
          marginTop: '0',
          paddingTop: 0,
          backgroundColor: '#c3cbcd',
          width: '240px',
          justifyContent: 'space-between',
          paddingBottom: 0,
        }}>
        <Box sx={{ overflow: 'scroll' }}>
          {employees.map((item) => (
            <SidebarButton
              sx={{
                maxHeight: '49px',
                width: '240px',
                backgroundColor: '#5dc1e6',
              }}
              key={item.employeeId}
              icon={<></>}
              label={`${item.user?.firstName} ${item.user?.lastName}`}
              labelStyle={{
                textWrap: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
                maxWidth: '184px',
                overflow: 'hidden',
              }}
              selected={selectedItem?.employeeId === item.employeeId}
              onClick={() => handleMenuItemClick(item)}
            />
          ))}
        </Box>
        <SidebarButton
          sx={{ maxHeight: '49px', width: '240px', backgroundColor: '#1f282d' }}
          labelColor={'#5dc1e6'}
          key={'somekey'}
          icon={<AddIcon sx={{ color: '#5dc1e6' }} />}
          label={'New Employee'}
          selected={false}
          onClick={() => toggleNewEmployeeModal()}
        />
      </List>
      {selectedItem && (
        <Box className="my-6 mx-8" sx={{ flex: 1 }}>
          <Box className="mb-4" sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Typography sx={styles.name}>
                {`${selectedItem?.user?.firstName} ${selectedItem?.user?.lastName}`}
                <Button
                  onClick={editEmployee}
                  color="primary"
                  sx={{ height: '16px', width: '16px', borderRadius: 20, marginLeft: '12px' }}>
                  <Edit sx={{ fontSize: '16px' }} />
                </Button>
                <Button
                  onClick={deleteEmployee}
                  color="error"
                  sx={{ height: '16px', width: '16px', borderRadius: 20, marginLeft: '12px' }}>
                  <Delete sx={{ fontSize: '16px', color: 'white' }} />
                </Button>
              </Typography>
              <Typography fontSize={'14px'} sx={{ paddingBottom: 2 }}>
                {selectedItem.user?.email}
              </Typography>
              <Box>
                <Box className="flex" sx={{ alignItems: 'center' }}>
                  <Typography fontSize={'14px'} fontWeight={600} color={'#215182'}>
                    DOB:{' '}
                  </Typography>
                  <Typography fontSize={'14px'} sx={{ paddingLeft: 1 }}>
                    {formattedDoB}
                  </Typography>
                </Box>
                <Box className="flex" sx={{ alignItems: 'center' }}>
                  <Typography fontSize={'14px'} fontWeight={600} color={'#215182'}>
                    Hire Date:{' '}
                  </Typography>
                  <Typography fontSize={'14px'} sx={{ paddingLeft: 1 }}>
                    {formattedHireData}
                  </Typography>
                </Box>
                <Box className="flex" sx={{ alignItems: 'center' }}>
                  <Typography fontSize={'14px'} fontWeight={600} color={'#215182'}>
                    Rate:{' '}
                  </Typography>
                  <Typography fontSize={'14px'} sx={{ paddingLeft: 1 }}>{`${formattedCurrency} / hr`}</Typography>
                </Box>
                <Box className="flex" sx={{ alignItems: 'center' }}>
                  <Typography fontSize={'14px'} fontWeight={600} color={'#215182'}>
                    Pay Period:{' '}
                  </Typography>
                  <Typography
                    fontSize={'14px'}
                    sx={{ paddingLeft: 1 }}>{`${selectedItem?.payPeriod} / 52 per year`}</Typography>
                </Box>
                <Box className="flex" sx={{ alignItems: 'center' }}>
                  <Typography fontSize={'14px'} fontWeight={600} color={'#215182'}>
                    fullTime/PartTime:{' '}
                  </Typography>
                  <Typography fontSize={'14px'} sx={{ paddingLeft: 1 }}>{`${selectedItem?.mode}`}</Typography>
                </Box>
                <Stack direction="row" width={'100%'} paddingLeft={1} sx={{ paddingTop: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginLeft: 0,
                      marginRight: 0,
                    }}>
                    <Typography sx={{ marginRight: 3, fontWeight: 600, color: '#215182' }}>SUPER</Typography>
                    <FormControlLabel
                      control={
                        <Switch checked={selectedItem?.user?.role == UserRoles.SUPER_EMPLOYEE} color="primary" />
                      }
                      label=""
                      disabled
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
            <Box sx={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {selectedItem?.user?.avatar ? (
                <img src={selectedItem?.user.avatar} style={{ height: '100%', width: '100%' }} />
              ) : (
                <Business />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EmployeesPage;
