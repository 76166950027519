import { useState } from 'react';

type ValidationType =
  | 'email'
  | 'phone'
  | 'name'
  | 'lastName'
  | 'address'
  | 'startDate'
  | 'endDate'
  | 'budget'
  | 'supplierOwner'
  | 'amount'
  | 'hourUsed'
  | 'costPerHour'
  | 'supplier'
  | 'costPerUnit'
  | 'description'
  | 'manHours'
  | 'timeConstraint'
  | 'time'
  | 'startTime'
  | 'endTime'
  | 'taskType'
  | 'taskStatus'
  | 'timeType'
  | 'eventType'
  | 'eventTime'
  | 'incidentType'
  | 'inspectionType'
  | 'permit'
  | 'delay'
  | 'delayType'
  | 'notes'
  | 'addedIncident'
  | 'otherType'
  | 'mode';

const useValidation = () => {
  const [errors, setErrors] = useState<{ [key in ValidationType]?: boolean }>({});

  const validateInput = (type: ValidationType, value: string | number | null | undefined): boolean => {
    const regexMap: { [key in ValidationType]: RegExp } = {
      email:
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      phone: /^\(\d{3}\) \d{3}-\d{4}$/,
      name: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      lastName: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      address: /^\S{3,}.*$/,
      startDate: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
      endDate: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
      budget: /^(?!0(\.0+)?$)\d*\.?\d+$/,
      supplierOwner: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      amount: /^(?!0(\.0+)?$)\d*\.?\d+$/,
      hourUsed: /^(?!0(\.0+)?$)\d*\.?\d+$/,
      costPerHour: /^(?!0(\.0+)?$)\d*\.?\d+$/,
      costPerUnit: /^(?!0(\.0+)?$)\d*\.?\d+$/,
      supplier: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      description: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      manHours: /^(?!0(\.0+)?$)\d*\.?\d+$/,
      timeConstraint: /^(?!0(\.0+)?$)\d*\.?\d+$/,
      startTime: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}\s(0[1-9]|1[0-2]):([0-5][0-9])\s(AM|PM)$/,
      time: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}\s(0[1-9]|1[0-2]):([0-5][0-9])\s(AM|PM)$/,
      endTime: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}\s(0[1-9]|1[0-2]):([0-5][0-9])\s(AM|PM)$/,
      taskType: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      taskStatus: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      timeType: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      eventType: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      eventTime: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}\s(0[1-9]|1[0-2]):([0-5][0-9])\s(AM|PM)$/,
      incidentType: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      inspectionType: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      permit: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      delay: /^(?!0(\.0+)?$)\d*\.?\d+$/,
      delayType: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      notes: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      addedIncident: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      otherType: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
      mode: /^[a-zA-ZÀ-ÿ0-9\s]{3,}$/,
    };

    // Verifica si el valor es string y lo trimea, o si es un número
    const isValid =
      typeof value === 'string'
        ? value.trim() !== '' && regexMap[type].test(value)
        : typeof value === 'number'
          ? regexMap[type].test(value.toString())
          : false;

    setErrors((prev) => ({
      ...prev,
      [type]: !isValid,
    }));

    return isValid;
  };

  const resetError = (type: ValidationType) => {
    setErrors((prev) => ({
      ...prev,
      [type]: false,
    }));
  };

  return { validateInput, resetError, errors };
};

export default useValidation;
