import { Box, Icon, Typography } from '@mui/material';
import COLORS from '@utils/color';
import { useHistory } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface DetailBoxProps {
  title: string;
  subtitle: string;
  link: string;
}

const DetailBox = ({ title, subtitle, link }: DetailBoxProps) => {
  const history = useHistory();

  const navigateToLink = () => {
    history.push(link);
  };

  return (
    <Box
      onClick={navigateToLink}
      sx={{
        backgroundColor: COLORS.white,
        border: `2px solid ${COLORS.lightBlue}`,
        marginBottom: 1,
        padding: '6px 18px',
        display: 'flex',
        cursor: 'pointer',
      }}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
        {/* <Typography>On Budget</Typography> */}
        {/* <Typography>Behind Schedule</Typography> */}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', color: COLORS.lightBlue }}>
        <ArrowForwardIosIcon />
      </Box>
    </Box>
  );
};

export default DetailBox;
