import { IMaskInput } from 'react-imask';
import React from 'react';
import { CalendarMonthOutlined, InfoOutlined, WarningAmber } from '@mui/icons-material';
import {
  BaseTextFieldProps,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import COLORS from '@utils/color';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInput-underline:before': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInputBase-root': {
    boxSizing: 'border-box',
    height: '40px',
    '& .MuiInputBase-input': {
      padding: '8px 16px',
      height: '40px',
      boxSizing: 'border-box',
    },
  },
  '& .MuiInputLabel-root': {
    display: 'none', // Hide the label by default
  },
  '& .MuiOutlinedInput-root': {
    border: `1px solid ${theme.palette.grey[300]}`,
    transition: 'border-color 0.3s ease',
    '&:hover': {
      border: `1px solid ${theme.palette.grey[400]}`, // Hover state
    },
    '&.Mui-focused': {
      outline: 'none', // Remove default focus outline
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary.main}`, // Focused state
    },
    '&.Mui-error fieldset': {
      border: `1px solid ${theme.palette.error.main}`, // Error state
    },
    '&.Mui-disabled fieldset': {
      color: theme.palette.grey[500],
      border: `1px solid ${theme.palette.grey[200]}`, // Disabled state
      background: theme.palette.action.disabledBackground,
    },
  },
}));

interface AltInputProps extends BaseTextFieldProps {
  label: string;
  name?: string;
  placeholder?: string;
  infoText?: string;
  showIcon?: boolean;
  icon?: React.ReactNode;
  iconAction?: () => void;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputMask = React.forwardRef<HTMLInputElement, any>((props, ref) => <IMaskInput {...props} inputRef={ref} />);

const PhoneInput: React.FC<AltInputProps> = ({
  label,
  name,
  placeholder,
  infoText,
  showIcon = false,
  icon,
  iconAction,
  error = false,
  errorMessage,
  disabled,
  onChange,
  ...props
}) => {
  return (
    <Box className="flex w-full" sx={{ alignItems: 'center' }}>
      {label && (
        <Typography
          variant="body2"
          gutterBottom
          sx={{ width: 100, textAlign: 'right', marginRight: '10px', fontSize: '14px' }}>
          {label}
          {infoText && (
            <Tooltip title={infoText}>
              <IconButton size="small">
                <InfoOutlined sx={{ width: 18, height: 18 }} className="text-primaryDark" />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      )}
      <StyledTextField
        placeholder={placeholder}
        error={error}
        helperText={error ? errorMessage : ''}
        variant="standard"
        onChange={onChange}
        disabled={disabled}
        name={name}
        InputProps={{
          inputComponent: InputMask as any,
          inputProps: {
            mask: '(000) 000-0000',
          },
          ...(showIcon && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={iconAction}>
                  {icon ? icon : <CalendarMonthOutlined sx={{ width: 22, height: 24 }} className="text-primaryDark" />}
                </IconButton>
              </InputAdornment>
            ),
          }),
        }}
        {...props}
      />
    </Box>
  );
};

export default PhoneInput;
