const COLORS = {
  white: 'white',
  veryLightBlue: '#ebf3f4',
  lightBlue: '#3cbde6',
  lighterGray: '#d2d2d2',
  darkBlue: '#254776',
  lightGray: '#626262',
  error: '#b30000',
  black: 'black',
  orange: '#f77239',
  eventOrange: 'orange',
  violet: '#662c91',
  green: '#7eb81c',
  yellow: '#ffe100',
  darkGray: '#85929e',
  darkUltraGray: '#212f3c',
  red: 'red',
};

export default COLORS;
