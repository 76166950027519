import * as React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { InfoOutlined } from '@mui/icons-material';
import { BaseTextFieldProps, Box, IconButton, TextField, TextFieldProps, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import COLORS from '@utils/color';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInput-underline:before': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInputBase-root': {
    boxSizing: 'border-box',
    height: '40px',
    '& .MuiInputBase-input': {
      padding: '8px 16px',
      height: '40px',
      boxSizing: 'border-box',
    },
  },
  '& .MuiInputLabel-root': {
    display: 'none',
  },
  '& .MuiOutlinedInput-root': {
    border: `1px solid ${theme.palette.grey[300]}`,
    transition: 'border-color 0.3s ease',
    '&:hover': {
      border: `1px solid ${theme.palette.grey[400]}`,
    },
    '&.Mui-focused': {
      outline: 'none',
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&.Mui-error fieldset': {
      border: `1px solid ${theme.palette.error.main}`,
    },
    '&.Mui-disabled fieldset': {
      color: theme.palette.grey[500],
      border: `1px solid ${theme.palette.grey[200]}`,
      background: theme.palette.action.disabledBackground,
    },
  },
}));

interface NumericFormatInputProps extends BaseTextFieldProps {
  label: string;
  name?: string;
  placeholder?: string;
  infoText?: string;
  showIcon?: boolean;
  icon?: React.ReactNode;
  iconAction?: () => void;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  disablePrefix?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  disablePrefix?: boolean;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: { value: any }) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix={props.disablePrefix ? '' : '$'}
    />
  );
});

const NumericFormatInput: React.FC<NumericFormatInputProps> = ({
  label,
  name,
  placeholder,
  infoText,
  showIcon = false,
  icon,
  iconAction,
  error = false,
  errorMessage,
  disabled,
  onChange,
  disablePrefix = false,
  ...props
}) => {
  const [values, setValues] = React.useState({
    textmask: '(100) 000-0000',
    numberformat: '1320',
  });

  return (
    <Box className="flex w-full" sx={{ alignItems: 'center' }}>
      {label && (
        <Typography
          variant="body2"
          gutterBottom
          sx={{ width: 100, textAlign: 'right', marginRight: '10px', fontSize: '14px' }}>
          {label}
          {infoText && (
            <Tooltip title={infoText}>
              <IconButton size="small">
                <InfoOutlined sx={{ width: 18, height: 18 }} className="text-primaryDark" />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      )}
      <StyledTextField
        placeholder={placeholder}
        error={error}
        helperText={error ? errorMessage : ''}
        variant="standard"
        onChange={onChange}
        disabled={disabled}
        name={name}
        InputProps={{
          inputComponent: NumericFormatCustom as any,
          inputProps: {
            disablePrefix,
          },
        }}
        {...props}
      />
    </Box>
  );
};

export default NumericFormatInput;
