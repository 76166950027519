import { Box, Typography } from '@mui/material';
import React from 'react';

const DashboardPage: React.FC = () => {
  return (
    <Box className="flex justify-center h-full">
      <Typography>Projects Feed</Typography>
    </Box>
  );
};

export default DashboardPage;
