import logoText from '@assets/logo.png';
import Button from '@components/ui/Button';
import Input from '@components/ui/Input';
import TextLink from '@components/ui/TextLink';
import { useTranslation } from '@hooks/useTranslation';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AppBar, Avatar, Box, CssBaseline, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import COLORS from '@utils/color';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export interface LoginFormProps {
  showReset?: boolean;
  showConfirm?: boolean;
  showInitial?: boolean;
  signIn: (email: string, password: string, rememberMe: boolean) => void;
  forgotPassword: (email: string) => void;
  resetPassword: (email: string, confirmationCode: string, password: string, rememberMe: boolean) => void;
  confirmUserPassword: (email: string, password: string, rememberMe: boolean) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  signIn,
  forgotPassword,
  resetPassword,
  confirmUserPassword,
  showReset,
  showConfirm,
  showInitial,
}) => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [rememberMe, setRememberMe] = useState(true);

  const localeLogin = useTranslation('login');
  const localeCommon = useTranslation('common');
  const localeActionMessages = useTranslation('actionMessages');
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
  const [showError, setShowError] = useState<string | null>(null);

  const theme = useTheme();

  const handleEnterKey = () => {
    showReset
      ? resetPassword(email, confirmationCode, password, rememberMe)
      : showConfirm
        ? confirmUserPassword(email, password, rememberMe)
        : signIn(email, password, rememberMe);
  };

  useEffect(() => {
    if (showReset || showConfirm || showInitial) {
      setShowForgotPassword(false);
    }
  }, [showReset, showConfirm, showInitial]);

  useEffect(() => {
    setShowError(null);
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleEnterKey();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [email, password, confirmationCode, showReset, showConfirm]);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = () => {
    if ((showReset || showConfirm) && password !== confirmPassword) {
      setShowError('Passwords do not match');
      return;
    }

    if (showReset || showConfirm) {
      if (!password || !passwordRegex.test(password)) {
        setShowError(
          'The password must be at least 8 characters long and include at least: one lowercase letter, one uppercase letter, one number, and one special character (@, $, !, %, *, ?, & or #).',
        );
        return;
      }
    } else {
      setShowError(null);
    }

    if (showReset) {
      resetPassword(email, confirmationCode, password, rememberMe);
    } else if (showConfirm) {
      confirmUserPassword(email, password, rememberMe);
    } else if (showForgotPassword) {
      forgotPassword(email);
    } else {
      signIn(email, password, rememberMe);
    }
  };

  const goToLanding = () => history.push('/');

  return (
    <Box className="flex">
      {/* <ActionMessages /> */}
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: 'rgb(33, 45, 51)',
          borderBottom: '2px solid rgb(32, 196, 234)',
          height: 65,
          display: 'flex',
          padding: '0 3em',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Box sx={{ height: 30 }}>
          <img height={'100%'} src={logoText} alt="logo" />
        </Box>
        <Avatar />
      </AppBar>

      <Box
        component="main"
        marginTop={'65px'}
        sx={{ backgroundColor: '#8a8987' }}
        className="flex-grow pt-4 bg-backgroundUI min-h-screen flex justify-center">
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '12px 24px 24px 24px',
            marginTop: '24px',
            width: '500px',
            height: showReset ? '660px' : showForgotPassword ? '360px' : showConfirm ? '510px' : '460px',
          }}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h1" sx={{ mb: 2 }}>
              {localeLogin['login']}
            </Typography>
            {!showConfirm && !showForgotPassword && !showReset && !showInitial && (
              <Typography variant="body14" sx={{ mb: 2 }}>
                {localeLogin['loginText']}
              </Typography>
            )}
          </Box>
          {showConfirm ||
            showForgotPassword ||
            (showReset && (
              <Typography variant="body14" sx={{ mb: 2 }}>
                {showForgotPassword && localeLogin['confirmUserMessage']}
                {showConfirm && localeLogin['forgotPasswordMessage']}
                {showReset && localeLogin['resetPasswordMessage']}
              </Typography>
            ))}
          {showInitial && (
            <Typography variant="body14" sx={{ mb: 2 }}>
              {localeLogin['initialLoginMessage']}
            </Typography>
          )}
          <Box>
            {(showConfirm || showReset || showForgotPassword) && (
              <Button
                color="secondary"
                className="!my-4 !ml-auto"
                onClick={() => {
                  window.location.reload();
                }}>
                {localeCommon['back']}
              </Button>
            )}
          </Box>
          <Input
            placeholder={localeLogin['enterEmail']}
            label={localeLogin['email']}
            variant="outlined"
            fullWidth
            className="!mb-4"
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
            disabled={showReset || showConfirm}
          />
          {!(showReset || showConfirm || showForgotPassword) && (
            <Input
              placeholder={localeLogin['writePassword']}
              label={localeLogin['password']}
              variant="outlined"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              className="!mb-4"
              autoComplete="off"
              showIcon={true}
              icon={showPassword ? <VisibilityOff /> : <Visibility />}
              iconAction={handleClickShowPassword}
              onChange={(e) => setPassword(e.target.value)}
            />
          )}
          {(showReset || showConfirm) && (
            <>
              {showReset && (
                <Input
                  placeholder={showConfirm ? localeLogin['enterTempPassword'] : localeLogin['enterPasswordResetCode']}
                  label={showConfirm ? localeLogin['tempPassword'] : localeLogin['passwordResetCode']}
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  className="!mb-4"
                  onChange={(e) => setConfirmationCode(e.target.value)}
                />
              )}
              <Input
                placeholder={localeLogin['writeNewPassword']}
                label={localeLogin['newPassword']}
                variant="outlined"
                fullWidth
                type={showPassword ? 'text' : 'password'}
                className="!mb-4"
                showIcon={true}
                autoComplete="off"
                icon={showPassword ? <VisibilityOff /> : <Visibility />}
                iconAction={handleClickShowPassword}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Input
                placeholder={localeLogin['confirmNewPassword']}
                label={localeLogin['confirmPassword']}
                variant="outlined"
                fullWidth
                type={showPassword ? 'text' : 'password'}
                className="!mb-4"
                showIcon={true}
                autoComplete="off"
                icon={showPassword ? <VisibilityOff /> : <Visibility />}
                iconAction={handleClickShowPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {showError && (
                <Typography variant="body14" sx={{ color: COLORS.error }}>
                  {showError}
                </Typography>
              )}
            </>
          )}

          <Button color="primary" fullWidth className="!my-4" onClick={handleSubmit}>
            {showReset
              ? localeLogin['resetPassword']
              : showForgotPassword
                ? localeLogin['sendResetCode']
                : localeLogin['login']}
          </Button>

          {!showReset && !showForgotPassword && !showConfirm && (
            <TextLink
              size="16px"
              onClick={() => setShowForgotPassword(true)}
              align="center"
              sx={{ marginTop: '0.5em' }}>
              {localeLogin['forgotPassword']}
            </TextLink>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LoginForm;
