import { Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress } from '@mui/material';
import { SxProps, styled } from '@mui/system';
import COLORS from '@utils/color';

interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
  sx?: SxProps;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  height?: '28px' | '32px' | '40px';
  onClick?: () => void;
}

const StyledButton = styled(MuiButton)<ButtonProps>(({ theme, color, loading, height }) => ({
  '&.MuiButtonBase-root': {
    padding: loading ? '16px' : '8px 16px',
    height: height || '32px',
    minHeight: height || '32px',
    minWidth: '32px',
    fontWeight: '600',
    textTransform: 'none',
    backgroundColor:
      color === 'primary'
        ? theme.palette.callToAction.main
        : color === 'secondary'
          ? theme.palette.frozenGrayShades2.main
          : color === 'tertiary'
            ? COLORS.lightBlue
            : color === 'error'
              ? theme.palette.feedbackError.main
              : theme.palette.callToAction.main,
    color:
      color === 'primary'
        ? theme.palette.common.white
        : color === 'tertiary'
          ? theme.palette.common.white
          : theme.palette.primaryDark.main,
  },
  '&.Mui-disabled': {
    backgroundColor:
      color === 'primary'
        ? theme.palette.ctaShades8.main
        : color === 'secondary'
          ? theme.palette.frozenGrayShades3.main
          : color === 'tertiary'
            ? theme.palette.frozenGrayShades1.main
            : theme.palette.ctaShades8.main,
  },
  '&:hover': {
    opacity: color === 'error' ? 0.8 : 1,
    backgroundColor:
      color === 'primary'
        ? theme.palette.ctaShades5.main
        : color === 'secondary'
          ? theme.palette.frozenGrayShades1.main
          : color === 'tertiary'
            ? COLORS.lightBlue
            : color === 'error'
              ? theme.palette.feedbackError.main
              : theme.palette.ctaShades5.main,
  },
}));

const IconContainer = styled('span')({
  display: 'inherit',
  marginLeft: '8px',
  marginRight: '8px',
});

const Button: React.FC<ButtonProps> = ({ loading, children, leftIcon, rightIcon, height, onClick, ...props }) => (
  <StyledButton {...props} disabled={loading || props.disabled} height={height} onClick={onClick}>
    {leftIcon && !loading && <IconContainer>{leftIcon}</IconContainer>}
    {loading ? <CircularProgress size={24} color="inherit" thickness={5} /> : children}
    {rightIcon && !loading && <IconContainer>{rightIcon}</IconContainer>}
  </StyledButton>
);

export default Button;
