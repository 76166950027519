import AltInput from '@components/ui/AltInput';
import DateInput from '@components/ui/DateInput';
import NumericFormatInput from '@components/ui/NumericFormatInput';
import Button from '@components/ui/Button';
import { useTranslation } from '@hooks/useTranslation';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { SetStateAction, useEffect, useState } from 'react';
import LogoPicture from '@components/generic/LogoPicture';
import useFormattedDate from '@hooks/useFormattedDate';
import useValidation from '@hooks/useValidation';
import useCompareDates from '@hooks/useCompareDates';
import AddressSelect from '@components/ui/AutocompleteInput';
import SelectInput from '@components/ui/SelectInput';
import { Client, getClientsRequest } from '@models/client.model';
import ClientsGraphQL from '@graphql/client.queries';
import { graphQlClient } from '@config/graphqlClient';
import { useAppDispatch } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { SnackType } from '@models/common.model';
import Map from '../../components/maps/map';

interface CreateEditProjectModalProps {
  modalClose: () => void;
  createProject?: (
    name: string,
    address: string,
    startDate: Date,
    endDate: Date,
    budget: number,
    lat: number | null,
    long: number | null,
    clientId: string | null,
    avatar?: File | null,
  ) => void;
  editProject?: (
    name: string,
    address: string,
    startDate: Date,
    endDate: Date,
    budget: number,
    lat: number | null,
    long: number | null,
    clientId: string | null,
    avatar?: File | null,
    projectId?: string,
  ) => void;
  saving: boolean;
  initialData?:
    | {
        name: string;
        address: string;
        budget: number | null;
        startDate: string | Date | null;
        endDate: string | Date | null;
        clientId: string | null;
        siteImage?: string | null;
        projectId?: string;
        lat: number | null;
        long: number | null;
      }
    | null
    | undefined;
}

const CreateEditProjectModal: React.FC<CreateEditProjectModalProps> = ({
  modalClose,
  createProject,
  editProject,
  saving,
  initialData,
}) => {
  const dispatch = useAppDispatch();
  const localeCommon = useTranslation('common');
  const localeProjects = useTranslation('projects');
  const [name, setName] = useState<string | null>(null);
  const [address, setAddress] = useState<string>('');
  const [start, setStart] = useState<string | null>(null);
  const [end, setEnd] = useState<string | null>(null);
  const [budget, setBudget] = useState<number | null>(null);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [siteImage, setSiteImage] = useState<string | null | undefined>('');
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [lat, setLat] = useState<number | null>(null);
  const [long, setLong] = useState<number | null>(null);
  const formattedStartDate = useFormattedDate(initialData?.startDate || null);
  const formattedEndDate = useFormattedDate(initialData?.endDate || null);
  const isDateRangeInvalid = useCompareDates(start, end);
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
  const { validateInput, resetError, errors } = useValidation();

  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
      setAddress(initialData.address);
      setStart(formattedStartDate);
      setEnd(formattedEndDate);
      setBudget(initialData.budget);
      setSiteImage(initialData.siteImage);
      setSelectedClientId(initialData.clientId);
      setLat(initialData.lat);
      setLong(initialData.long);
    }
  }, [initialData, formattedStartDate, formattedEndDate]);

  const handleNameChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('name');
    setName(event.target.value);
  };

  const handleAddressChange = (address: string, lat?: Number, long?: Number) => {
    resetError('address');
    setAddress(address);
  };

  const handleCoordChange = (lat?: number | null, long?: number | null) => {
    setLat(lat !== undefined ? lat : null);
    setLong(long !== undefined ? long : null);
  };

  const handleStartChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('startDate');
    setStart(event.target.value);
  };

  const handleEndChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('endDate');
    setEnd(event.target.value);
  };

  const handleBudgetChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('budget');
    setBudget(Number(event.target.value));
  };

  const handleChange = (event: React.SyntheticEvent, value: Client | null) => {
    if (value?.clientId) {
      setSelectedClientId(value?.clientId);
    }
  };

  useEffect(() => {
    getClients(true);
  }, []);

  const getClients = async (initialize = false) => {
    try {
      const data: getClientsRequest = await graphQlClient.request(ClientsGraphQL.queries.getClients);
      setClients(data.getClients);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const submit = () => {
    const isAddressValid = validateInput('address', address);
    const isNameValid = validateInput('name', name);
    const isStartValid = validateInput('startDate', start);
    const isEndValid = validateInput('endDate', end);
    const isBudgetValid = validateInput('budget', budget);

    if (
      !isDateRangeInvalid &&
      // selectedClientId &&
      start &&
      end &&
      budget &&
      isNameValid &&
      name &&
      isAddressValid &&
      isStartValid &&
      isEndValid &&
      isBudgetValid
    ) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      if (initialData) {
        editProject &&
          editProject(
            name,
            address,
            startDate,
            endDate,
            budget,
            lat,
            long,
            selectedClientId,
            avatar,
            initialData.projectId || '',
          );
      } else {
        createProject && createProject(name, address, startDate, endDate, budget, lat, long, selectedClientId, avatar);
      }
      modalClose();
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setAvatar(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const attemptCancel = () => {
    if (window.confirm('Sure? Your changes will be lost')) {
      modalClose();
    }
  };

  return (
    <Box>
      <Typography variant="h3">{localeProjects['addProject']}</Typography>

      <Box>
        <AltInput
          fullWidth
          autoComplete="project"
          label={localeProjects['name']}
          placeholder={`${localeProjects['project']} ${localeProjects['name']}`}
          onChange={handleNameChange}
          value={name}
          error={errors.name}
          errorMessage={localeProjects['nameErrorMessage']}
        />
        <AddressSelect
          fullWidth
          autoComplete="address"
          label={localeProjects['address']}
          placeholder={`${localeProjects['project']} ${localeProjects['address']}`}
          onChange={handleAddressChange}
          onChangeCoor={handleCoordChange}
          value={address}
          error={errors.address}
          errorMessage={localeProjects['addressErrorMessage']}
        />
        {lat && (
          <Box sx={{ width: '100%', height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Map lat={lat} lng={long} />
          </Box>
        )}
        <SelectInput
          label={'Client'}
          placeholder={'Client'}
          items={clients}
          value={selectedClientId}
          valueKey="clientId"
          labelKey="name"
          onChange={handleChange}
          error={false}
          errorMessage="required"
        />
        <Stack direction="row" style={{ marginLeft: '22px' }} spacing={1}>
          <DateInput
            disabled
            fullWidth
            autoComplete="start"
            label={'Start'}
            showIcon={true}
            placeholder={`mm/dd/yyyy`}
            onChange={handleStartChange}
            error={errors.startDate || null || undefined}
            value={start}
            errorMessage={localeProjects['startErrorMessage']}
          />
          <DateInput
            disabled
            fullWidth
            autoComplete="End"
            label={'End'}
            showIcon={true}
            placeholder={`mm/dd/yyyy`}
            onChange={handleEndChange}
            error={errors.endDate || isDateRangeInvalid || undefined}
            value={end}
            errorMessage={errors.endDate ? localeProjects['endErrorMessage'] : localeProjects['rangeErrorMessage']}
          />
        </Stack>
        <NumericFormatInput
          fullWidth
          autoComplete="Budget"
          label={'Budget'}
          placeholder={`${localeProjects['project']} ${'Budget'}`}
          onChange={handleBudgetChange}
          value={budget}
          error={errors.budget}
          errorMessage={localeProjects['budgetErrorMessage']}
        />
        <Box display="flex" justifyContent="center" alignItems="center">
          <LogoPicture
            src={imagePreview || initialData?.siteImage}
            name={'Logo'}
            handleImageChange={handleImageChange}
          />
        </Box>
      </Box>
      <Box className="flex justify-between mt-4">
        <Button onClick={attemptCancel} color="secondary">
          {localeCommon['cancel']}
        </Button>
        <Button onClick={submit} color="primary" autoFocus disabled={saving}>
          {initialData ? localeCommon['saveChanges'] : localeCommon['create']}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEditProjectModal;
