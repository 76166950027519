import { CalendarMonthOutlined, InfoOutlined, WarningAmber } from '@mui/icons-material';
import {
  BaseTextFieldProps,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import COLORS from '@utils/color';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme, multiline }) => ({
  '& .MuiOutlinedInput-root': multiline
    ? {
        border: `1px solid ${COLORS.lightBlue}`,
        '&:hover fieldset': {
          borderColor: COLORS.lightBlue,
        },
        '&.Mui-focused fieldset': {
          borderColor: COLORS.lightBlue,
        },
        '&.Mui-error fieldset': {
          borderColor: COLORS.lightBlue,
        },
        '&.Mui-disabled fieldset': {
          borderColor: COLORS.lightBlue,
        },
      }
    : {},

  '& .MuiInput-underline:before': !multiline
    ? {
        borderBottomColor: COLORS.lightBlue,
      }
    : {},

  '& .MuiInput-underline:hover:not(.Mui-disabled):before': !multiline
    ? {
        borderBottomColor: COLORS.lightBlue,
      }
    : {},

  '& .MuiInput-underline:after': !multiline
    ? {
        borderBottomColor: COLORS.lightBlue,
      }
    : {},

  '& .MuiInputBase-root': {
    boxSizing: 'border-box',
    height: multiline ? '90px' : '40px',
    padding: multiline ? '0px 8px' : '0px 8px',
  },

  '& .MuiInputBase-input': {
    padding: multiline ? '8px 8px' : '8px 8px',
    height: '100%',
    boxSizing: 'border-box',
  },
}));

interface AltInputProps extends BaseTextFieldProps {
  label: string;
  name?: string;
  placeholder?: string;
  infoText?: string;
  showIcon?: boolean;
  icon?: React.ReactNode;
  iconAction?: () => void;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AltInput: React.FC<AltInputProps> = ({
  label,
  name,
  placeholder,
  infoText,
  showIcon = false,
  icon,
  iconAction,
  error = false,
  errorMessage,
  disabled,
  onChange,
  ...props
}) => {
  return (
    <Box className="flex w-full" sx={{ alignItems: 'center' }}>
      {label && (
        <Typography
          variant="body2"
          gutterBottom
          sx={{ width: 100, textAlign: 'right', marginRight: '10px', fontSize: '14px' }}>
          {label}
          {infoText && (
            <Tooltip title={infoText}>
              <IconButton size="small">
                <InfoOutlined sx={{ width: 18, height: 18 }} className="text-primaryDark" />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      )}
      <StyledTextField
        placeholder={placeholder}
        error={error}
        helperText={error ? errorMessage : ''}
        variant={props.multiline ? 'outlined' : 'standard'}
        onChange={onChange}
        disabled={disabled}
        multiline={props.multiline}
        name={name}
        InputProps={
          showIcon
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={iconAction}>
                      {icon ? (
                        icon
                      ) : (
                        <CalendarMonthOutlined sx={{ width: 22, height: 24 }} className="text-primaryDark" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : undefined
        }
        {...props}
      />
    </Box>
  );
};

export default AltInput;
