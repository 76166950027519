import { useState, useEffect } from 'react';

const useFormattedCurrency = (dateNumber: number | null) => {
  const [formattedCurrency, setFormattedCurrency] = useState<string | null>(null);

  useEffect(() => {
    if (dateNumber) {
      const formattedNumber = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(dateNumber);
      setFormattedCurrency(formattedNumber);
    } else {
      setFormattedCurrency('');
    }
  }, [dateNumber]);

  return formattedCurrency;
};

export default useFormattedCurrency;
