import { gql } from 'graphql-request';

export const EMPLOYEE_FIELDS = gql`
  fragment EmployeeFields on EmployeeDto {
    employeeId
    rate
    hireDate
    dob
    payPeriod
    mode
    user {
      firstName
      lastName
      avatar
      email
      userId
      role
    }
  }
`;

export const getEmployees = gql`
  query getEmployees {
    getEmployees {
      ...EmployeeFields
    }
  }

  ${EMPLOYEE_FIELDS}
`;

export const createEmployee = gql`
  mutation createEmployee($employee: EmployeeCreateInput!) {
    createEmployee(employee: $employee) {
      ...EmployeeFields
    }
  }
  ${EMPLOYEE_FIELDS}
`;

export const updateEmployee = gql`
  mutation updateEmployee($employee: EmployeeUpdateInput!) {
    updateEmployee(employee: $employee) {
      ...EmployeeFields
    }
  }
  ${EMPLOYEE_FIELDS}
`;

export const deleteEmployee = gql`
  mutation deleteEmployee($id: String!) {
    deleteEmployee(id: $id) {
      affected
      messages
    }
  }
`;

const EmployeesGraphQL = {
  queries: {
    getEmployees,
  },
  mutations: {
    createEmployee,
    updateEmployee,
    deleteEmployee,
  },
};

export default EmployeesGraphQL;
