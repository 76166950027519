import CreateEditUserModal from '@components/user/CreateEditUserModal';
import CustomModal from '@components/generic/CustomModal';
import GridListSubheader from '@components/generic/GridListSubheader';
import { graphQlClient } from '@config/graphqlClient';
import OrganizationsGraphQL from '@graphql/organization.queries';
import UserGraphQL from '@graphql/user.queries';
import { useDebounce } from '@hooks/useDebounce';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { Organization, getOrganizationsRequest } from '@models/organization.model';
import {
  User,
  UserRoles,
  UserStatus,
  createOrUpdateUserRequest,
  disableOrEnableUserRequest,
  getPaginatedUsersRequest,
} from '@models/user.model';
import { Container } from '@mui/material';
import UserList from '@pages/users/list';
import { useAppDispatch } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useCallback, useEffect, useState } from 'react';

interface UsersPageProps {}

const UsersPage: React.FC<UsersPageProps> = () => {
  // common
  const dispatch = useAppDispatch();
  const localeCommon = useTranslation('common');
  const localeUsers = useTranslation('users');
  const localeActionMessages = useTranslation('actionMessages');

  // list
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [users, setUsers] = useState<User[] | null>(null);
  const [count, setCount] = useState<number>(0);
  // search
  const [search, setSearch] = useState<string>('');
  const debouncedSearchValue = useDebounce(search, 500);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  // filter
  const [filter, setFilter] = useState<string>('Active');
  const handleFilterChange = (filter: string) => {
    setFilter(filter);
  };
  // sort
  const [orderBy, setOrderBy] = useState<string | null>('name');
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // pagination
  const [page, setPage] = useState<number>(0);
  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(event.target.value as unknown as number);
    setPage(0);
  };

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);

  const getUsers = useCallback(async () => {
    try {
      const data: getPaginatedUsersRequest = await graphQlClient.request(UserGraphQL.queries.getPaginatedUsers, {
        users: {
          page,
          limit: rowsPerPage,
          search: debouncedSearchValue,
          filter,
          orderBy,
          orderDirection,
        },
      });
      setCount(data?.getPaginatedUsers?.count || 0);
      setUsers(data?.getPaginatedUsers?.users || []);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filter, localeCommon, orderBy, orderDirection, page, rowsPerPage, debouncedSearchValue]);

  const getOrganizations = useCallback(async () => {
    try {
      const data: getOrganizationsRequest = await graphQlClient.request(OrganizationsGraphQL.queries.getOrganizations, {
        organizations: {
          page,
          limit: rowsPerPage,
          search: debouncedSearchValue,
          filter,
          orderBy,
          orderDirection,
        },
      });
      setOrganizations(data?.getOrganizations?.organizations || []);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
    //TODO: Check deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // initialize
  useEffect(() => {
    getUsers();
    getOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, page, debouncedSearchValue, filter, orderBy, orderDirection]);

  const modalClose = (): void => {
    setShowCreateModal(false);
  };

  const handleAddUser = () => {
    setShowCreateModal(true);
  };

  //TODO: Remove?
  const handleSelectUser = (userId: string) => {
    console.log('user', userId);
  };

  const toggleEnableDisableUser = async (userId: string, enable: boolean = false) => {
    if (!userId) return;
    try {
      const query = enable ? UserGraphQL.mutations.enableUser : UserGraphQL.mutations.disableUser;
      const result: disableOrEnableUserRequest = await graphQlClient.request(query, {
        id: userId,
      });
      if (result?.disableUser?.userId || result?.enableUser?.userId) {
        dispatch(
          appendActionMessage({
            message: enable
              ? localeActionMessages['userEnabledSuccessfully']
              : localeActionMessages['userDisabledSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      getUsers();
    }
  };

  const createUser = async (
    organizationId: string,
    status: UserStatus | null,
    email: string,
    firstName: string,
    lastName: string,
    role: UserRoles,
    userId?: string,
  ) => {
    if (!email) return;

    // If is has a userId, it's an edit
    const isEdit = !!userId;
    setSaving(true);
    try {
      const query = isEdit ? UserGraphQL.mutations.updateOtherUser : UserGraphQL.mutations.createUser;
      const params: any = {
        user: {
          firstName,
          lastName,
          email,
          role,
        },
      };
      if (isEdit) {
        params.user.userId = userId;
      } else {
        params.user.organizationId = organizationId;
        params.user.status = status;
      }
      const data: createOrUpdateUserRequest = await graphQlClient.request(query, params);
      if (data?.createUser?.userId || data?.updateOtherUser?.userId) {
        dispatch(
          appendActionMessage({
            message: isEdit
              ? localeActionMessages['userEditedSuccessfully']
              : localeActionMessages['userCreatedSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
      }
    } catch (e: any) {
      console.log('e', e);
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setSaving(false);
      getUsers();
      setShowCreateModal(false);
    }
  };

  return (
    <Container>
      <GridListSubheader
        title={localeUsers['title']}
        buttonText={localeUsers['addUser']}
        isGridView={false}
        onToggleView={() => {}}
        onDrawerOpen={handleAddUser}
        icon="user"
        showFavGrid={false}
      />{' '}
      <UserList
        handleDisableUser={toggleEnableDisableUser}
        handleEnableUser={(id) => toggleEnableDisableUser(id, true)}
        handleEditUser={createUser}
        count={count}
        users={users || []}
        organizations={organizations || []}
        page={page}
        handleChangePage={handleChangePage}
        rowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        search={search}
        handleSearchChange={handleSearchChange}
        orderDirection={orderDirection}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        filter={filter}
        handleFilterChange={handleFilterChange}
      />
      <CustomModal isOpen={!!showCreateModal} onClose={modalClose}>
        <CreateEditUserModal
          modalClose={modalClose}
          createUser={createUser}
          organizations={organizations || []}
          saving={saving}
        />
      </CustomModal>
    </Container>
  );
};

export default UsersPage;
