import { useTranslation } from '@hooks/useTranslation';
import { Organization } from '@models/organization.model';
import { Box, Button, Typography } from '@mui/material';

interface DisableOrganizationModalProps {
  modalClose: () => void;
  disableOrganization: () => void;
  organization: Organization | undefined;
}

const DisableOrganizationModal: React.FC<DisableOrganizationModalProps> = ({
  modalClose,
  disableOrganization,
  organization,
}) => {
  const localeCommon = useTranslation('common');
  const localeOrganizations = useTranslation('organizations');

  return (
    <Box>
      <Typography variant="h5">{localeOrganizations['disableOrganization']}</Typography>

      <Typography variant="h6">{localeOrganizations['ConfirmDisableMessage']}</Typography>

      <Typography variant="h6">{organization?.name}</Typography>

      <Box className="flex justify-between">
        <Button onClick={modalClose} color="primary">
          {localeCommon['cancel']}
        </Button>
        <Button onClick={() => disableOrganization()} color="primary" autoFocus>
          {localeCommon['disable']}
        </Button>
      </Box>
    </Box>
  );
};

export default DisableOrganizationModal;
