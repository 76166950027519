import { useState, useEffect } from 'react';
import moment from 'moment';

const useFormattedDate = (dateStr: string | Date | null) => {
  const [formattedDate, setFormattedDate] = useState<string | null>(null);

  useEffect(() => {
    if (dateStr) {
      const formatted = moment(dateStr).format('MM/DD/YYYY');

      setFormattedDate(formatted);
    } else {
      setFormattedDate(null);
    }
  }, [dateStr]);

  return formattedDate;
};

export default useFormattedDate;
