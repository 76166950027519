export const OrganizationTypes = {
  SET_ORGANIZATION_DATA: 'SET_ORGANIZATION_DATA',
};

export const setOrganizationData = (data: { organizationId: string; name: string }) => {
  return {
    type: OrganizationTypes.SET_ORGANIZATION_DATA,
    data: data,
  };
};

export type OrganizationState = {
  organizationId: string | null;
  name: string | null;
};

// INITIAL STATE
const organizationInitialState: OrganizationState = {
  organizationId: null,
  name: null,
};

export type OrganizationAction = {
  type: string;
  data: { organizationId: string; name: string };
};

const organizationReducer = (state = organizationInitialState, action: OrganizationAction) => {
  switch (action.type) {
    case OrganizationTypes.SET_ORGANIZATION_DATA:
      return {
        ...state,
        organizationId: action.data.organizationId,
        name: action.data.name,
      };

    default:
      return state;
  }
};

export default organizationReducer;
