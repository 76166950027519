import SidebarButton from '@components/ui/SidebarButton';
import { Box, CircularProgress, LinearProgress, List, Typography } from '@mui/material';
import ListItem from '@components/list/list';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CustomModal from '@components/generic/CustomModal';
import Button from '@components/ui/Button';
import { graphQlClient, graphQlUpload } from '@config/graphqlClient';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { Project, deleteProjectRequest, getProjectsRequest } from '@models/project.model';
import ProjectsGraphQL from '@graphql/project.queries';
import CreateEditProjectModal from '@components/project/CreateEditProjectModal';
import { Business, Delete, Edit, Refresh } from '@mui/icons-material';
import useFormattedDate from '@hooks/useFormattedDate';
import useFormattedCurrency from '@hooks/useFormattedCurrency';
import { useHistory, useParams } from 'react-router-dom';
import COLORS from '@utils/color';
import { Activity } from '@models/activity.model';
import ActivitiesGraphQL from '@graphql/activity.queries';
import BudgetModal from '@components/budget/BudgetModal';
import TasksGraphQL from '@graphql/task.queries';
import { Task } from '@models/task.model';
import { RootState } from '@redux/store';

const styles = {
  name: {
    fontSize: 22,
    fontWeight: 'bold',
  },
};

interface RouteParams {
  id: string;
}

const ProjectsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [projects, setProjects] = useState<Project[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [groupedActivities, setGroupedActivities] = useState<Activity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInventory, setLoadingInventory] = useState<boolean>(false);
  const history = useHistory();
  const { id } = useParams<RouteParams>();
  const limit = 9;
  const [showNewProjectModal, setShowNewProjectModal] = useState<boolean>(false);
  const [showBudgetModal, setShowBudgetModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Project | null>(null);
  const localeCommon = useTranslation('common');
  const [initialData, setInitialData] = useState<Project | null>(null);
  const formattedStartDate = useFormattedDate(selectedItem?.startDate || null);
  const formattedEndDate = useFormattedDate(selectedItem?.endDate || null);
  const formattedCurrency = useFormattedCurrency(selectedItem?.budget || null);
  const [isDelegated, setIsDelegated] = useState<boolean>(false);
  const [inventory, setInventory] = useState<any[]>([]);
  const [unassigned, setUnassigned] = useState<boolean>(false);
  const [percent, setPercent] = useState(0);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const { organizationId } = useAppSelector((state: RootState) => state.organization);
  const [delegateTask, setDelegateTask] = useState<Task[]>([]);

  const total = inventory.reduce((sum: number, item: any) => {
    if ('equipmentId' in item) {
      // Es un equipment
      return sum + item.costPerHour * item.hoursUsed;
    } else if ('materialId' in item) {
      // Es un material
      return sum + item.amount * item.costPerUnit;
    }
    return sum;
  }, 0);

  function formatNumber(value: number) {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  // I think this is never triggered, or not needed
  // I commented it out without issues, but maybe I didn't try all scenarios
  useEffect(() => {
    if (projects && projects?.length > 0 && id) {
      const initial = projects.filter((project) => project?.projectId === id);
      if (initial?.[0]) {
        setSelectedItem(initial[0]);
        setPage(1);
        setActivities([]);
        setGroupedActivities([]);
        if (initial[0]?.originalProjectId) {
          setIsDelegated(true);
        } else {
          setIsDelegated(false);
        }
      }
    }
  }, [id, projects]);

  const editProject = () => {
    setInitialData(selectedItem);
    setShowNewProjectModal(true);
  };

  const toggleNewProjectModal = () => {
    setShowNewProjectModal(!showNewProjectModal);
    if (!showNewProjectModal) {
      setInitialData(null);
    }
  };

  const toggleBudgetModal = () => {
    setShowBudgetModal(!showBudgetModal);
  };

  const handleMenuItemClick = (project: Project) => {
    setSelectedItem(project);
    setPage(1);
    setActivities([]);
    setGroupedActivities([]);
    if (project.originalProjectId) {
      setIsDelegated(true);
    } else {
      setIsDelegated(false);
    }
  };

  const createOrUpdateProject = async (
    name: string,
    address: string,
    startDate: Date | string,
    endDate: Date | string,
    budget: number | null,
    lat: number | null,
    long: number | null,
    clientId: string | null,
    avatar?: File | null,
    projectId?: string,
  ) => {
    setLoading(true);

    try {
      const query = projectId ? ProjectsGraphQL.mutations.updateProject : ProjectsGraphQL.mutations.createProject;
      const params = {
        project: {
          projectId,
          name,
          address,
          budget,
          startDate,
          endDate,
          lat,
          long,
          clientId,
        } as Project,
      };
      if (!projectId) {
        delete params.project.projectId;
      }
      const data = avatar
        ? await graphQlUpload(avatar, 'project', query, params.project)
        : await graphQlClient.request(query, params);
      if (data?.createProject?.projectId || data?.updateProject?.projectId) {
        const selected = (data?.createProject || data?.updateProject) as Project;
        setSelectedItem(selected);
        dispatch(
          appendActionMessage({
            message: projectId ? localeCommon['updatedSuccessfully'] : localeCommon['createdSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        getProjects();
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteProject = async () => {
    if (window.confirm('Sure? Your changes will be lost')) {
      setLoading(true);

      try {
        const deleteResult: deleteProjectRequest = await graphQlClient.request(
          ProjectsGraphQL.mutations.deleteProject,
          { id: selectedItem?.projectId },
        );
        if (deleteResult?.deleteProject?.affected > 0) {
          dispatch(appendActionMessage({ message: localeCommon['deletedSuccessfully'], type: SnackType.SUCCESS }));
        }
      } catch (e: any) {
        dispatch(
          appendActionMessage({
            message: e?.response?.errors[0]?.message || localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
      } finally {
        getProjects();
      }
    }
  };

  useEffect(() => {
    getProjects(true);
  }, []);

  useEffect(() => {
    if (selectedItem?.projectId) {
      getActivities(true, selectedItem.projectId, 1, limit);
    }
  }, [selectedItem]);

  const getActivities = async (initialize = false, projectId: string, page: number, limit: number) => {
    try {
      const data: any = await graphQlClient.request(ActivitiesGraphQL.queries.getActivities, {
        projectId,
        page,
        limit,
      });
      setPages(Math.ceil(data.getActivities.total / limit));
      setActivities([...activities, ...data.getActivities.items]);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLoad = (newPage: number, id: string) => {
    setPage(newPage);
    getActivities(false, id, newPage, limit);
  };

  const handleRefresh = () => {
    setActivities([]);
    setPage(1);
    if (selectedItem?.projectId) {
      getActivities(false, selectedItem.projectId, 1, limit);
    }
  };

  const getProjects = async (initialize = false) => {
    setLoading(true);
    try {
      const data: getProjectsRequest = await graphQlClient.request(ProjectsGraphQL.queries.getProjects);
      setProjects(data.getProjects);
      if (data.getProjects[0] && initialize) {
        setSelectedItem(data.getProjects[0]);
        setPage(1);
        setActivities([]);
        setGroupedActivities([]);
        if (data.getProjects[0].originalProjectId) {
          setIsDelegated(true);
        } else {
          setIsDelegated(false);
        }
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const navigateToClient = () => {
    history.push(`/clients/${selectedItem?.clientId}`);
  };

  const navigateToSubcontractor = (id: string | undefined) => {
    history.push(`/subcontractors/${id}`);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();

    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

    return isToday
      ? 'Today'
      : `${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(
          2,
          '0',
        )}/${date.getFullYear()}`;
  };

  const groupByDate = (activities: any[]) => {
    return activities.reduce(
      (acc, activity) => {
        const dateKey = formatDate(activity.createdAt);

        if (!acc[dateKey]) acc[dateKey] = [];
        acc[dateKey].push(activity);
        return acc;
      },
      {} as Record<string, any[]>,
    );
  };

  useEffect(() => {
    setGroupedActivities(groupByDate(activities));
  }, [activities]);

  useEffect(() => {
    if (selectedItem?.projectId) {
      getEquipmentsAndMaterials(true, selectedItem?.projectId, unassigned);
      getResumeTasks(true, selectedItem?.projectId);
    }
  }, [selectedItem, unassigned]);

  const getResumeTasks = async (initialize = false, projectId: string) => {
    try {
      const data: any = await graphQlClient.request(TasksGraphQL.queries.getTaskStats, { projectId });
      if (data.getTaskStats.totalTasks > 0) {
        setPercent((data.getTaskStats.completedTasks / data.getTaskStats.totalTasks) * 100);
      } else {
        setPercent(0);
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const getEquipmentsAndMaterials = async (initialize = false, projectId: string, unassigned: boolean) => {
    setLoadingInventory(true);
    try {
      const data: any = await graphQlClient.request(TasksGraphQL.queries.getAllMaterialsAndEquipments, {
        projectId,
        unassigned,
      });
      const equipments = data?.getAllMaterialsAndEquipments?.equipments || [];
      const materials = data?.getAllMaterialsAndEquipments?.materials || [];
      setInventory([...equipments, ...materials]);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoadingInventory(false);
    }
  };

  useEffect(() => {
    getTasksByOriginalOrganizationId(true, organizationId);
  }, []);

  const getTasksByOriginalOrganizationId = async (initialize = false, organizationId: string | null) => {
    setLoading(true);
    try {
      const data: any = await graphQlClient.request(TasksGraphQL.queries.getTasksByOriginalOrganizationId, {
        originalOrganizationId: organizationId,
      });
      setDelegateTask(data.getTasksByOriginalOrganizationId);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flex: 1, backgroundColor: COLORS.veryLightBlue }}>
      <CustomModal isOpen={!!showNewProjectModal} style={{ maxWidth: 580 }}>
        <CreateEditProjectModal
          modalClose={toggleNewProjectModal}
          createProject={createOrUpdateProject}
          editProject={createOrUpdateProject}
          saving={false}
          initialData={initialData}
        />
      </CustomModal>
      <CustomModal isOpen={!!showBudgetModal} style={{ maxWidth: 900 }}>
        <BudgetModal
          modalClose={toggleBudgetModal}
          selectedItem={selectedItem}
          inventory={inventory}
          loadingInventory={loadingInventory}
          setUnassigned={setUnassigned}
          unassigned={unassigned}
        />
      </CustomModal>
      {/* TODO: All these sidebars should be in a component */}
      <List
        className="flex flex-col items-start"
        sx={{
          marginTop: '0',
          paddingTop: 0,
          backgroundColor: '#c3cbcd',
          width: '240px',
          justifyContent: 'space-between',
          paddingBottom: 0,
        }}>
        <Box sx={{ overflow: 'scroll' }}>
          {projects.map((item) => (
            <SidebarButton
              sx={{
                maxHeight: '49px',
                width: '240px',
                backgroundColor: '#5dc1e6',
              }}
              key={item.projectId}
              delegated={!!item.originalProjectId}
              icon={<></>}
              label={item.name}
              labelStyle={{
                textWrap: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
                maxWidth: '184px',
                overflow: 'hidden',
              }}
              selected={selectedItem?.projectId === item.projectId}
              onClick={() => handleMenuItemClick(item)}
            />
          ))}
        </Box>
        <SidebarButton
          sx={{ maxHeight: '49px', width: '240px', backgroundColor: '#1f282d' }}
          labelColor={'#5dc1e6'}
          key={'somekey'}
          icon={<AddIcon sx={{ color: '#5dc1e6' }} />}
          label={'New Project'}
          selected={false}
          onClick={() => toggleNewProjectModal()}
        />
      </List>
      {selectedItem && (
        <Box className="my-6 mx-8" sx={{ flex: 1 }}>
          <Box className="mb-4" sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Typography sx={styles.name}>
                {selectedItem?.name}
                {isDelegated && (
                  <Typography variant="body14" sx={{ color: COLORS.darkBlue, marginLeft: '4px' }}>
                    (delegated)
                  </Typography>
                )}
                {!isDelegated && (
                  <Button
                    onClick={editProject}
                    color="primary"
                    sx={{ height: '16px', width: '16px', borderRadius: 20, marginLeft: '12px' }}>
                    <Edit sx={{ fontSize: '16px' }} />
                  </Button>
                )}
                {!isDelegated && (
                  <Button
                    onClick={deleteProject}
                    color="error"
                    sx={{ height: '16px', width: '16px', borderRadius: 20, marginLeft: '12px' }}>
                    <Delete sx={{ fontSize: '16px', color: 'white' }} />
                  </Button>
                )}
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeigth: 'light', color: COLORS.lightGray, marginBottom: '4px' }}>
                {selectedItem?.address}
              </Typography>
            </Box>
            <Box sx={{ width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {selectedItem?.siteImage ? (
                <img src={selectedItem?.siteImage} style={{ height: '100%', width: '100%' }} />
              ) : (
                <Business />
              )}
            </Box>
          </Box>

          {/* TODO: This should be in a component */}
          {isDelegated && (
            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
              <Box sx={{ display: 'flex', gap: '14px' }}>
                <Button onClick={() => history.push(`/schedule/${selectedItem.projectId}`)} color="primary">
                  View all tasks
                </Button>
                See task list here -- same as task list view from schedule page to be added
              </Box>
            </Box>
          )}
          {!isDelegated && (
            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}>
              <Box sx={{ display: 'flex', gap: '14px' }}>
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ marginBottom: '12px', marginRight: '10px' }}>
                    <Typography
                      sx={{ borderBottomWidth: 1, borderBottomColor: COLORS.black, borderBottomStyle: 'solid' }}>
                      Client
                    </Typography>
                    <Typography sx={{ color: COLORS.lightBlue, cursor: 'pointer' }} onClick={navigateToClient}>
                      {selectedItem?.client?.name}
                    </Typography>
                  </Box>
                  <Box sx={{ marginBottom: '12px', marginRight: '10px' }}>
                    <Typography
                      sx={{ borderBottomWidth: 1, borderBottomColor: COLORS.black, borderBottomStyle: 'solid' }}>
                      Subcontractors
                    </Typography>
                    {delegateTask.map((item) => (
                      <Typography
                        onClick={() => navigateToSubcontractor(item?.delegateOrganization?.organizationId)}
                        sx={{ color: COLORS.lightBlue, cursor: 'pointer' }}>
                        {item.projectId === selectedItem?.projectId && item?.delegateOrganization?.name}
                      </Typography>
                    ))}
                  </Box>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ marginBottom: '12px', marginRight: '10px' }}>
                    <Typography
                      sx={{ borderBottomWidth: 1, borderBottomColor: COLORS.black, borderBottomStyle: 'solid' }}>
                      Budget
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Box sx={{ flex: 1 }}>
                        <Typography>Cost to Date</Typography>
                        <Typography fontSize={14}>{`$${formatNumber(total)}`}</Typography>
                      </Box>
                      <Box sx={{ flex: 1 }}>
                        <Typography>Max Budget</Typography>
                        <Typography fontSize={14}>{formattedCurrency}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2">{`${selectedItem?.budget ? Math.round((total / selectedItem?.budget) * 100) : 0}%`}</Typography>
                    </Box>
                    <Box sx={{ width: '100%', ml: 1 }}>
                      <LinearProgress
                        variant="determinate"
                        value={selectedItem?.budget ? (total / selectedItem?.budget) * 100 : 0}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ marginRight: '10px' }}>
                    <Button onClick={toggleBudgetModal} color="primary" sx={{ width: '100%' }}>
                      BUDGET DETAILS
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Box sx={{ marginBottom: '12px', marginRight: '10px' }}>
                    <Typography
                      sx={{ borderBottomWidth: 1, borderBottomColor: COLORS.black, borderBottomStyle: 'solid' }}>
                      Timeline
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                      <Box sx={{ flex: 1 }}>
                        <Typography>Start</Typography>
                        <Typography>{formattedStartDate}</Typography>
                      </Box>
                      <Box>
                        <Typography>End</Typography>
                        <Typography>{formattedEndDate}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2">{`${Math.round(percent)}%`}</Typography>
                    </Box>
                    <Box sx={{ width: '100%', ml: 1 }}>
                      <LinearProgress variant="determinate" value={percent} />
                    </Box>
                  </Box>
                  <Box sx={{ marginRight: '10px' }}>
                    <Button
                      onClick={() => history.push(`/schedule/${selectedItem.projectId}`)}
                      color="primary"
                      sx={{ width: '100%' }}>
                      TASK GANTT CHART
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          {/* TODO: This should be in a component */}
          <Box sx={{ flex: 1, justifyContent: 'space-between', height: '65%' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottomWidth: 1,
                borderBottomColor: COLORS.black,
                borderBottomStyle: 'solid',
              }}>
              <Typography sx={{ alignContent: 'center' }}>Project Feed</Typography>
              <Button
                onClick={handleRefresh}
                color="primary"
                sx={{ height: '16px', width: '16px', borderRadius: 20, margin: '5px' }}>
                <Refresh sx={{ fontSize: '16px' }} />
              </Button>
            </Box>
            <Box sx={{ height: '100%' }}>
              <ListItem
                projectId={selectedItem.projectId}
                page={page}
                pages={pages}
                handleLoad={handleLoad}
                groupedActivities={groupedActivities}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ProjectsPage;
