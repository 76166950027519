import { gql } from 'graphql-request';

export const EVENT_FIELDS = gql`
  fragment EventFields on EventDto {
    eventId
    name
    eventType
    otherEventType
    eventTime
    incidentType
    addedIncidentType
    inspectionType
    permit
    delay
    delayType
    notes
    precedentTaskId
    projectId
  }
`;

export const getEvents = gql`
  query getEvents($projectId: String!) {
    getEvents(projectId: $projectId) {
      ...EventFields
    }
  }
  ${EVENT_FIELDS}
`;

export const createEvent = gql`
  mutation createEvent($event: EventCreateInput!) {
    createEvent(event: $event) {
      ...EventFields
    }
  }
  ${EVENT_FIELDS}
`;

export const updateEvent = gql`
  mutation updateEvent($event: EventUpdateInput!) {
    updateEvent(event: $event) {
      ...EventFields
    }
  }
  ${EVENT_FIELDS}
`;

export const deleteEvent = gql`
  mutation deleteEvent($id: String!) {
    deleteEvent(id: $id) {
      affected
      messages
    }
  }
`;

const EventsGraphQL = {
  queries: {
    getEvents,
  },
  mutations: {
    createEvent,
    updateEvent,
    deleteEvent,
  },
};

export default EventsGraphQL;
