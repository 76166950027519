import { gql } from 'graphql-request';

export const CLIENT_FIELDS = gql`
  fragment ClientFields on ClientDto {
    clientId
    name
    address
    phone
    email
    logo
    projects {
      projectId
      name
      clientId
      address
    }
  }
`;

export const getClients = gql`
  query getClients {
    getClients {
      ...ClientFields
    }
  }

  ${CLIENT_FIELDS}
`;

export const createClient = gql`
  mutation createClient($client: ClientCreateInput!) {
    createClient(client: $client) {
      ...ClientFields
    }
  }
  ${CLIENT_FIELDS}
`;

export const updateClient = gql`
  mutation updateClient($client: ClientUpdateInput!) {
    updateClient(client: $client) {
      ...ClientFields
    }
  }
  ${CLIENT_FIELDS}
`;

export const deleteClient = gql`
  mutation deleteClient($id: String!) {
    deleteClient(id: $id) {
      affected
      messages
    }
  }
`;

const ClientsGraphQL = {
  queries: {
    getClients,
  },
  mutations: {
    createClient,
    updateClient,
    deleteClient,
  },
};

export default ClientsGraphQL;
