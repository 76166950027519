import SidebarButton from '@components/ui/SidebarButton';
import { Box, CircularProgress, List, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CustomModal from '@components/generic/CustomModal';
import Button from '@components/ui/Button';
import { graphQlClient } from '@config/graphqlClient';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useAppDispatch } from '@redux/hooks';
import { Delete, Edit } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import COLORS from '@utils/color';
import { Crew, createOrUpdateCrewRequest, deleteCrewRequest, getCrewsRequest } from '@models/crew.model';
import CrewsGraphQL from '@graphql/crew.queries';
import CreateEditCrewModal from '@components/crew/CreateEditCrewModal';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import EmployeeContainer from '@components/crew/EmployeeContainer';
import CustomizedTables from '@components/crew/TaskTable';
import ProjectTable from '@components/crew/ProjectTable';
import { UserRoles } from '@models/user.model';

const styles = {
  name: {
    fontSize: 22,
    fontWeight: 'bold',
  },
};

interface RouteParams {
  id: string;
}

const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  height: '240px',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const CrewsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [crews, setCrews] = useState<Crew[]>([]);
  const [tasks, setTasks] = useState<any[]>([]);
  const [projectIds, setProjectIds] = useState<(string | undefined)[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<RouteParams>();

  const [showNewCrewModal, setShowNewCrewModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Crew | null>(null);
  const localeCommon = useTranslation('common');
  const [initialData, setInitialData] = useState<any | null>(null);

  useEffect(() => {
    if (crews && crews?.length > 0 && id) {
      const initial = crews.filter((crew) => crew?.crewId === id);
      if (initial?.[0]) {
        setSelectedItem(initial[0]);
      }
    }
  }, [id, crews]);

  useEffect(() => {
    const allTasks = selectedItem?.tasks;
    if (allTasks) {
      const uniqueProjectIds = allTasks
        .map((item) => item.projectId) // Obtener los projectId
        .filter((value, index, self) => value && self.indexOf(value) === index); // Filtrar duplicados
      setProjectIds(uniqueProjectIds);
      setTasks(allTasks);
    }
  }, [selectedItem]);

  const editCrew = () => {
    setInitialData(selectedItem);
    setShowNewCrewModal(true);
  };
  const toggleNewCrewModal = () => {
    setShowNewCrewModal(!showNewCrewModal);
    if (!showNewCrewModal) {
      setInitialData(null);
    }
  };

  const handleMenuItemClick = (crew: Crew) => {
    setSelectedItem(crew);
  };

  const createOrUpdateCrew = async (name: string, crewId?: string) => {
    setLoading(true);

    try {
      const query = crewId ? CrewsGraphQL.mutations.updateCrew : CrewsGraphQL.mutations.createCrew;
      const params = {
        crew: {
          name,
          crewId,
        } as Crew,
      };
      if (!crewId) {
        delete params.crew.crewId;
      }
      const data: createOrUpdateCrewRequest = await graphQlClient.request(query, params);
      if (data?.createCrew?.crewId || data?.updateCrew?.crewId) {
        const selected = (data?.createCrew || data?.updateCrew) as Crew;
        setSelectedItem(selected);
        dispatch(
          appendActionMessage({
            message: crewId ? localeCommon['updatedSuccessfully'] : localeCommon['createdSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        getCrews();
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteCrew = async () => {
    if (window.confirm('Sure? Your changes will be lost')) {
      setLoading(true);

      try {
        const deleteResult: deleteCrewRequest = await graphQlClient.request(CrewsGraphQL.mutations.deleteCrew, {
          id: selectedItem?.crewId,
        });
        if (deleteResult?.deleteCrew?.affected > 0) {
          dispatch(appendActionMessage({ message: localeCommon['deletedSuccessfully'], type: SnackType.SUCCESS }));
        }
      } catch (e: any) {
        dispatch(
          appendActionMessage({
            message: e?.response?.errors[0]?.message || localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
      } finally {
        getCrews();
      }
    }
  };

  useEffect(() => {
    getCrews(true);
  }, []);

  const getCrews = async (initialize = false) => {
    setLoading(true);
    try {
      const data: getCrewsRequest = await graphQlClient.request(CrewsGraphQL.queries.getCrews);
      setCrews(data.getCrews);
      if (data.getCrews[0]) {
        setSelectedItem(data.getCrews[0]);
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ display: 'flex', flex: 1, backgroundColor: COLORS.veryLightBlue }}>
      <CustomModal isOpen={!!showNewCrewModal} style={{ maxWidth: 580 }}>
        <CreateEditCrewModal
          modalClose={toggleNewCrewModal}
          createCrew={createOrUpdateCrew}
          editCrew={createOrUpdateCrew}
          saving={false}
          initialData={initialData}
        />
      </CustomModal>

      <List
        className="flex flex-col items-start"
        sx={{
          marginTop: '0',
          paddingTop: 0,
          backgroundColor: '#c3cbcd',
          width: '240px',
          justifyContent: 'space-between',
          paddingBottom: 0,
        }}>
        <Box sx={{ overflow: 'scroll' }}>
          {crews.map((item) => (
            <SidebarButton
              sx={{
                maxHeight: '49px',
                width: '240px',
                backgroundColor: '#5dc1e6',
              }}
              key={item.crewId}
              icon={<></>}
              label={`${item.name}`}
              labelStyle={{
                textWrap: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
                maxWidth: '184px',
                overflow: 'hidden',
              }}
              selected={selectedItem?.crewId === item.crewId}
              onClick={() => handleMenuItemClick(item)}
            />
          ))}
        </Box>
        <SidebarButton
          sx={{ maxHeight: '49px', width: '240px', backgroundColor: '#1f282d' }}
          labelColor={'#5dc1e6'}
          key={'somekey'}
          icon={<AddIcon sx={{ color: '#5dc1e6' }} />}
          label={'New Crew'}
          selected={false}
          onClick={() => toggleNewCrewModal()}
        />
      </List>
      {selectedItem && (
        <Box className="my-6 mx-8" sx={{ flex: 1 }}>
          <Box className="mb-4" sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Typography sx={styles.name}>
                {`${selectedItem?.name}`}
                <Button
                  onClick={editCrew}
                  color="primary"
                  sx={{ height: '16px', width: '16px', borderRadius: 20, marginLeft: '12px' }}>
                  <Edit sx={{ fontSize: '16px' }} />
                </Button>
                <Button
                  onClick={deleteCrew}
                  color="error"
                  sx={{ height: '16px', width: '16px', borderRadius: 20, marginLeft: '12px' }}>
                  <Delete sx={{ fontSize: '16px', color: 'white' }} />
                </Button>
              </Typography>
              <Box className="flex" sx={{ alignItems: 'center' }}>
                <GroupsOutlinedIcon sx={{ color: '#215182', marginRight: 1 }} />
                <Typography fontSize={'18px'} fontWeight={600} color={'#215182'} sx={{ marginRight: 1 }}>
                  {selectedItem.employees?.length}{' '}
                </Typography>
                <HouseOutlinedIcon sx={{ color: '#215182', marginRight: 1 }} />
                <Typography fontSize={'18px'} fontWeight={600} color={'#215182'} sx={{ marginRight: 1 }}>
                  {projectIds.length}{' '}
                </Typography>
                <FormatListBulletedOutlinedIcon sx={{ color: '#215182', marginRight: 1 }} />
                <Typography fontSize={'18px'} fontWeight={600} color={'#215182'} sx={{ marginRight: 1 }}>
                  {tasks.length}{' '}
                </Typography>
              </Box>
              <Typography sx={{ marginBottom: 2 }}> Crew Members </Typography>
              {!selectedItem?.employees?.some((employee) => employee?.user?.role === UserRoles.SUPER_EMPLOYEE) && (
                <Typography sx={{ marginBottom: 4, color: 'red' }}>
                  {' '}
                  Note: There is no superintendent in this crew{' '}
                </Typography>
              )}
              <EmployeeContainer getCrew={getCrews} selectedItem={selectedItem} crewId={selectedItem?.crewId} />
              <Box sx={{ flexGrow: 1, marginTop: 2 }}>
                <Grid container spacing={2} columns={16}>
                  <Grid item xs={6}>
                    <Typography sx={{ marginBottom: 1, fontWeight: 600, fontSize: 14 }}> Assigned Projects </Typography>
                    <ProjectTable projectIds={projectIds} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography sx={{ marginBottom: 1, fontWeight: 600, fontSize: 14 }}> Assigned Tasks </Typography>
                    <CustomizedTables tasks={tasks} />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CrewsPage;
