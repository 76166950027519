import Button from '@components/ui/Button';
import { useTranslation } from '@hooks/useTranslation';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useValidation from '@hooks/useValidation';
import AutocompleteMultipleInput from '@components/ui/AutocompleteMultipleInput';
import { Employee } from '@models/employee.model';
import { graphQlClient } from '@config/graphqlClient';
import CrewsGraphQL from '@graphql/crew.queries';
import { SnackType } from '@models/common.model';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useAppDispatch } from '@redux/hooks';

interface AddEmployeeModalProps {
  modalClose: () => void;
  employees: Employee[];
  setItems: (items: Employee[]) => void;
  items: Employee[] | undefined;
  crewId: string | undefined;
  getCrew: () => void;
}

const AddEmployeeModal: React.FC<AddEmployeeModalProps> = ({
  modalClose,
  employees,
  setItems,
  items,
  crewId,
  getCrew,
}) => {
  const dispatch = useAppDispatch();
  const localeCommon = useTranslation('common');
  const localeProjects = useTranslation('projects');
  const [name, setName] = useState<string | null>(null);
  const { validateInput, resetError, errors } = useValidation();
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  useEffect(() => {
    if (items) {
      const employeeIds: string[] = items
        .map((employee) => employee.employeeId)
        .filter((id): id is string => id !== undefined);
      if (employeeIds) {
        setSelectedIds(employeeIds);
      }
    }
  }, [items]);

  const submit = async () => {
    try {
      await graphQlClient.request(CrewsGraphQL.mutations.AssignEmployeesToCrew, { crewId, employeeIds: selectedIds });
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      getCrew();
      modalClose();
    }
  };

  const attemptCancel = () => {
    if (window.confirm('Sure? Your changes will be lost')) {
      modalClose();
    }
  };

  return (
    <Box>
      <Typography variant="h3">{'Select Employees'}</Typography>
      <Box>
        <AutocompleteMultipleInput<Employee>
          fullWidth
          label=""
          items={employees}
          value={items}
          getOptionLabel={(option) => `${option.user?.firstName} ${option.user?.lastName}` ?? ''}
          placeholder="Select Employee"
          isOptionEqualToValue={(option, value) => option.employeeId === value.employeeId}
          onChange={(event, newValue) => setItems(newValue)}
          error={errors.address}
          errorMessage="Error selecting employee"
        />
      </Box>
      <Box className="flex justify-between mt-4">
        <Button onClick={attemptCancel} color="secondary">
          {localeCommon['cancel']}
        </Button>
        <Button onClick={submit} color="primary" autoFocus disabled={false}>
          {localeCommon['save']}
        </Button>
      </Box>
    </Box>
  );
};

export default AddEmployeeModal;
