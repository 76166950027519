import Layout from '@layouts/Layout';
import { UserRoles } from '@models/user.model';
import AuthPage from '@pages/auth';
import LoginPage from '@pages/login';
import LogoutPage from '@pages/logout';
import OrganizationsPage from '@pages/organizations/organizations';
import SettingsPage from '@pages/settings';
import UsersPage from '@pages/users';
import SchedulePage from '@pages/schedule';
import React from 'react';
import { Redirect, Route, RouteProps, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useAppSelector } from './app/redux/hooks';
import { RootState } from './app/redux/store';
import DashboardPage from '@pages/dashboard';
import ClientsPage from '@pages/clients';
import ProjectsPage from '@pages/projects';
import EmployeesPage from '@pages/employees';
import CrewsPage from '@pages/crews';
import SubcontractorsPage from '@pages/subcontractors/subcontractors';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const App: React.FC = () => {
  const { isLoggedIn, role } = useAppSelector((state: RootState) => state.user);

  const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => (
    <Layout>
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn ? (
            <Component {...props} />
          ) : (
            (() => {
              if (window.location.pathname !== '/logout') {
                sessionStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
              }
              return <Redirect to="/auth" />;
            })()
          )
        }
      />
    </Layout>
  );

  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          {isLoggedIn ? <Redirect to="/dashboard" /> : <LoginPage />}
        </Route>

        {/* Redirect from /auth/code... to /dashboard if logged in */}
        <Route path="/auth/*">{isLoggedIn ? <Redirect to="/dashboard" /> : <AuthPage />}</Route>

        {/* Specific route for the authentication page */}
        <Route exact path="/auth">
          {isLoggedIn ? <Redirect to="/dashboard" /> : <AuthPage />}
        </Route>

        <Route exact path="/logout">
          <LogoutPage />
        </Route>

        {/* PrivateRoute for Dashboard and all other authenticated routes */}
        {role === UserRoles.SUPER_ADMIN && <PrivateRoute path="/organizations" component={OrganizationsPage} />}
        {[UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.SUPER].includes(role as UserRoles) && (
          <PrivateRoute path="/users" component={UsersPage} />
        )}
        <PrivateRoute exact path="/dashboard" component={DashboardPage} />
        <PrivateRoute exact path="/clients/:id" component={ClientsPage} />
        <PrivateRoute exact path="/clients" component={ClientsPage} />
        <PrivateRoute exact path="/projects/:id" component={ProjectsPage} />
        <PrivateRoute exact path="/projects" component={ProjectsPage} />
        <PrivateRoute path="/settings" component={SettingsPage} />
        <PrivateRoute path="/schedule/:id" component={SchedulePage} />
        <PrivateRoute path="/schedule" component={SchedulePage} />
        <PrivateRoute path="/employees" component={EmployeesPage} />
        <PrivateRoute path="/crews" component={CrewsPage} />
        <PrivateRoute path="/subcontractors/:id" component={SubcontractorsPage} />
        <PrivateRoute path="/subcontractors" component={SubcontractorsPage} />

        {/* Optionally handle the root URL explicitly */}
        <Route path="/">{isLoggedIn ? <Redirect to="/dashboard" /> : <LoginPage />}</Route>
      </Switch>
    </Router>
  );
};

export default App;
