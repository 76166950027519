import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@components/ui/Button';
import { Delete, Edit } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import COLORS from '@utils/color';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#edf4f5',
    color: '#859194',
    fontSize: 12,
    padding: '4px 8px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: '4px 8px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: 'none',
  height: '34px',
  '&:nth-of-type(odd)': {
    backgroundColor: '#ffffff',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#edf4f5',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface CustomizedTableProps {
  tasks: any;
  handleTaskDetail: (id: string) => void;
  deleteTask: (task: any) => void;
  deleteEvent: (event: any) => void;
}

export default function CustomizedTables({ tasks, handleTaskDetail, deleteTask, deleteEvent }: CustomizedTableProps) {
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(dateString).toLocaleString('en-US', options);
  };

  tasks.sort((a: any, b: any) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Task</StyledTableCell>
            <StyledTableCell>Start</StyledTableCell>
            <StyledTableCell>End</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.map((task: any, index: number) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                <Typography fontWeight={200} fontSize={14} variant="subtitle1">
                  {task.name}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>{formatDate(task.startTime)}</StyledTableCell>
              <StyledTableCell>{formatDate(task.endTime)}</StyledTableCell>
              <StyledTableCell>
                <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <CircleIcon
                    style={{
                      height: 10,
                      width: 10,
                      marginRight: 2,
                      color:
                        task.status === 'completed'
                          ? COLORS.lightBlue
                          : task.status === 'On Track'
                            ? COLORS.green
                            : task.status === 'Delayed'
                              ? COLORS.yellow
                              : task.status === 'On Hold'
                                ? COLORS.orange
                                : 'transparent',
                    }}
                  />
                  <Typography fontWeight={200} fontSize={12} variant="subtitle1">
                    {task.status && task.status}
                  </Typography>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    onClick={() => handleTaskDetail(task.id)}
                    color="primary"
                    sx={{ height: '3px', width: '3px', borderRadius: 4, marginLeft: '3px' }}>
                    <Edit sx={{ fontSize: '12px' }} />
                  </Button>
                  <Button
                    onClick={task.isEvent ? () => deleteEvent(task) : () => deleteTask(task)}
                    color="error"
                    sx={{ height: '3px', width: '3px', borderRadius: 4, marginLeft: '3px' }}>
                    <Delete sx={{ fontSize: '12px', color: 'white' }} />
                  </Button>
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
