import logoText from '@assets/logo.png';
import AvatarDropdown from '@components/generic/AvatarDropdown';
import { Box, IconButton, AppBar as MuiAppBar, Toolbar as MuiToolbar, styled } from '@mui/material';
import { useAppSelector } from '@redux/hooks';
import { RootState } from '@redux/store';
import React from 'react';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  height: '72px',
  paddingLeft: 0,
  paddingRight: 0,
  backgroundColor: 'rgb(33, 45, 51)',
  borderBottom: `1px solid ${theme.palette.primaryIcy.main}`,
}));

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  height: '72px',
  paddingLeft: 0,
  paddingRight: 0,
  backgroundColor: 'rgb(33, 45, 51)',
  display: 'flex',
  alignItems: 'center',
}));

const Header: React.FC = () => {
  const { avatar } = useAppSelector((state: RootState) => state.user);

  return (
    <AppBar
      position="static"
      color="transparent"
      elevation={0}
      className="!bg-white"
      sx={{
        backgroundColor: 'rgb(33, 45, 51)',
        borderBottom: '2px solid rgb(32, 196, 234)',
        height: 65,
      }}>
      <Toolbar className={'justify-between'}>
        <IconButton edge="start" color="inherit" aria-label="logo">
          <Box sx={{ height: 30 }}>
            <img height={'100%'} src={logoText} alt="logo" />
          </Box>
        </IconButton>
        <Box className="flex items-center">
          <AvatarDropdown avatar={avatar || ''} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
