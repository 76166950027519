export type User = {
  accessToken?: string;
  status?: UserStatus;
  file?: string;
  email?: string;
  userId?: string;
  firstName?: string;
  lastName?: string;
  avatar?: string;
  role?: UserRoles;
  lastLogin?: string;
  loginMethod?: string;
  organizationId?: string;
  rememberMe?: boolean;
  locale?: UserLocale;
};

export interface createOrUpdateUserRequest {
  createUser?: User;
  updateOtherUser?: User;
}

export interface updateOwnUserRequest {
  updateOwnUser: User;
}

export interface disableOrEnableUserRequest {
  disableUser?: User;
  enableUser?: User;
}

export interface getSelfRequest {
  getSelf: User;
}

export interface getSelfWithOrganizationRequest {
  getSelfWithOrganization: {
    user: User;
    organization: {
      name: string;
      organizationId: string;
    };
  };
}

export interface getAllowedRolesRequest {
  getAllowedRoles: UserRoles[];
}

export interface getUsersRequest {
  getUsers: User[];
}

export interface getUserByEmailRequest {
  getUserByEmail: User;
}

export interface getPaginatedUsersRequest {
  getPaginatedUsers: {
    users: User[];
    count: number;
  };
}

export enum UserRoles {
  SUPER_ADMIN = 'SUPER_ADMIN',
  OWNER = 'OWNER',
  SUPER = 'SUPER',
  CREW = 'CREW',
  SUPER_EMPLOYEE = 'SUPER_EMPLOYEE',
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export enum UserLocale {
  EN = 'en',
  ES = 'es',
}
