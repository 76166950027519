import SidebarButton from '@components/ui/SidebarButton';
import { useTranslation } from '@hooks/useTranslation';
import { UserRoles } from '@models/user.model';
import {
  CorporateFareOutlined,
  PeopleAltOutlined,
  Dashboard,
  Architecture,
  CalendarMonth,
  BadgeOutlined,
} from '@mui/icons-material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import { Box, List, styled } from '@mui/material';
import { useAppSelector } from '@redux/hooks';
import { RootState } from '@redux/store';
import { useHistory, useLocation } from 'react-router-dom';

const SidebarContainer = styled(Box)(({ theme }) => ({
  width: 240,
  minWidth: 240,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  borderRight: `1px solid ${theme.palette.primaryIcy.main}`,
  backgroundColor: '#2e517e',
  zIndex: 1000,
}));

export interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = () => {
  const localeMenu = useTranslation('menu');
  const history = useHistory();
  const location = useLocation();

  const { role } = useAppSelector((state: RootState) => state.user);

  const menuItems = [
    // { text: localeMenu['clients'], icon: <PeopleAltOutlined sx={{ color: 'white' }} />, link: '/clients' },
    // { text: localeMenu['employees'], icon: <></>, link: '/employees' },
    // { text: localeMenu['crew'], icon: <></>, link: '/crew' },
    // { text: localeMenu['subcontractors'], icon: <></>, link: '/subcontractors' },
    // { text: localeMenu['inventory'], icon: <></>, link: '/inventory' },
    // { text: localeMenu['reports'], icon: <></>, link: '/reports' },
    // { text: localeMenu['invoices'], icon: <></>, link: '/invoices' },
    // { text: localeMenu['schedule'], icon: <></>, link: '/schedule' },
    // { text: localeMenu['permits'], icon: <></>, link: '/permits' },
    // { text: localeMenu['insurance'], icon: <></>, link: '/insurance' },
  ];

  // if ([UserRoles.SUPER_ADMIN, UserRoles.OWNER, UserRoles.SUPER].includes(role as UserRoles)) {
  //   menuItems.unshift({ text: localeMenu['users'], icon: <PeopleAltOutlined />, link: `/users` });
  // }

  if ([UserRoles.OWNER].includes(role as UserRoles)) {
    menuItems.unshift({
      text: localeMenu['schedule'],
      icon: <CalendarMonth sx={{ color: 'white' }} />,
      link: '/schedule',
    });
    menuItems.unshift({
      text: localeMenu['projects'],
      icon: <Architecture sx={{ color: 'white' }} />,
      link: '/projects',
    });
    menuItems.unshift({
      text: localeMenu['subcontractors'],
      icon: <EngineeringOutlinedIcon sx={{ color: 'white' }} />,
      link: '/subcontractors',
    });
    menuItems.unshift({
      text: localeMenu['crews'],
      icon: <GroupsOutlinedIcon sx={{ color: 'white' }} />,
      link: '/crews',
    });
    menuItems.unshift({
      text: localeMenu['employees'],
      icon: <BadgeOutlined sx={{ color: 'white' }} />,
      link: '/employees',
    });
    menuItems.unshift({
      text: localeMenu['clients'],
      icon: <PeopleAltOutlined sx={{ color: 'white' }} />,
      link: '/clients',
    });
    menuItems.unshift({
      text: localeMenu['dashboard'],
      icon: <Dashboard sx={{ color: 'white' }} />,
      link: '/dashboard',
    });
  }

  if ([UserRoles.SUPER_ADMIN].includes(role as UserRoles)) {
    menuItems.unshift({
      text: localeMenu['organizations'],
      icon: <CorporateFareOutlined sx={{ color: 'white' }} />,
      link: `/organizations`,
    });
  }

  const handleMenuItemClick = (link: string, text: string) => {
    history.push(link);
  };

  return (
    <SidebarContainer>
      <List className="flex flex-col items-start" sx={{ marginTop: '57px' }}>
        {menuItems.map((item) => (
          <SidebarButton
            key={item.text}
            icon={item.icon}
            label={item.text}
            selected={location.pathname.includes(item.link)}
            onClick={() => handleMenuItemClick(item.link, item.text)}
          />
        ))}
      </List>
    </SidebarContainer>
  );
};

export default Sidebar;
