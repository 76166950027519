import { gql } from 'graphql-request';

export const getAutocomplete = gql`
  query Autocomplete($input: String!) {
    autocomplete(input: $input) {
      description
      place_id
      latitude
      longitude
    }
  }
`;

const MapsGraphQL = {
  queries: {
    getAutocomplete,
  },
};

export default MapsGraphQL;
