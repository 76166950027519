import Button from '@components/ui/Button';
import Chip from '@components/ui/Chip';
import IconButton from '@components/ui/IconButton';
import { AreaIcon24 } from '@components/ui/Icons';
import { VisuallyHiddenInput } from '@components/generic/VisuallyHiddenInput';
import {
  AttachFileOutlined,
  ContactPageOutlined,
  CorporateFareOutlined,
  GroupWorkOutlined,
  HubOutlined,
  PolicyOutlined,
  WorkspacesOutlined,
} from '@mui/icons-material';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined';
import SpaceDashboardOutlined from '@mui/icons-material/SpaceDashboardOutlined';
import ViewList from '@mui/icons-material/ViewList';
import ViewModule from '@mui/icons-material/ViewModule';
import { Box, Typography } from '@mui/material';
import { LegacyRef } from 'react';
import { useHistory } from 'react-router-dom';

interface GridListSubheaderProps {
  title: string;
  subTitle?: string;
  subTitleLink?: string;
  buttonText: string | null;
  onDrawerOpen: () => void;
  onFileChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onToggleView: () => void;
  isGridView: boolean;
  inputRef?: LegacyRef<HTMLInputElement> | undefined;
  icon?: 'dashboard' | 'area' | 'policy' | 'service' | 'process' | 'organization' | 'user' | 'integration' | 'document';
  showFavGrid?: boolean;
}

const GridListSubheader: React.FC<GridListSubheaderProps> = ({
  title,
  subTitle,
  subTitleLink,
  buttonText,
  onDrawerOpen,
  onFileChange,
  onToggleView,
  isGridView,
  icon = 'dashboard',
  showFavGrid = true,
  inputRef,
}) => {
  const history = useHistory();

  return (
    <>
      <Box className="flex flex-wrap justify-between items-center mb-6">
        <Box className="flex items-center">
          <Box className="mx-2 flex">
            {icon === 'area' ? (
              <AreaIcon24 />
            ) : icon === 'policy' ? (
              <PolicyOutlined />
            ) : icon === 'service' ? (
              <WorkspacesOutlined />
            ) : icon === 'process' ? (
              <GroupWorkOutlined />
            ) : icon === 'organization' ? (
              <CorporateFareOutlined />
            ) : icon === 'user' ? (
              <ContactPageOutlined />
            ) : icon === 'integration' ? (
              <HubOutlined />
            ) : icon === 'document' ? (
              <AttachFileOutlined />
            ) : (
              <SpaceDashboardOutlined />
            )}
          </Box>
          <Typography variant="h3" className="mx-2">
            {title}
          </Typography>
          <Typography
            variant="h3semibold"
            className="ml-2 cursor pointer"
            onClick={() => {
              history.push(subTitleLink || '');
            }}>
            {subTitle}
          </Typography>
        </Box>
        {buttonText && (
          <>
            <Button variant="contained" color="primary" onClick={onDrawerOpen}>
              {buttonText}
            </Button>
            {inputRef && <VisuallyHiddenInput type="file" onChange={onFileChange} ref={inputRef} />}
          </>
        )}
      </Box>
      {showFavGrid && (
        <Box className="flex items-center mb-6">
          {/* // TODO: Enable fav functionality */}
          <Chip label="Destacados" leftIcon={<FavoriteBorderOutlined />} selected={false} />
          <Box marginLeft="auto">
            <IconButton icon={<ViewModule />} onClick={onToggleView} className="!mr-2" selected={isGridView} />
            <IconButton icon={<ViewList />} onClick={onToggleView} selected={!isGridView} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default GridListSubheader;
