import AltInput from '@components/ui/AltInput';
import NumericFormatInput from '@components/ui/NumericFormatInput';
import Button from '@components/ui/Button';
import { useTranslation } from '@hooks/useTranslation';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { SetStateAction, useEffect, useState } from 'react';
import useValidation from '@hooks/useValidation';
import { Equipment } from '@models/equipment.model';
import { Material } from '@models/material.model';
import DateInput from '@components/ui/DateInput';
import useFormattedDateLarge from '@hooks/useFormattedDateLarge';

type InventoryItem = Equipment | Material;

interface CreateEditEquipmentModalProps {
  modalClose: () => void;
  saving: boolean;
  initialData?: any;
  setInventory: (inventory: InventoryItem[]) => void;
  inventory: InventoryItem[];
}

const CreateEditEquipmentModal: React.FC<CreateEditEquipmentModalProps> = ({
  modalClose,
  saving,
  initialData,
  setInventory,
  inventory,
}) => {
  const localeCommon = useTranslation('common');
  const localeProjects = useTranslation('projects');
  const [supplierOwner, setSupplierOwner] = useState<string>('');
  const [time, setTime] = useState<string | null>(null);
  // const [crewAssigned, setCrewAssigned] = useState<string | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [hoursUsed, setHourUsed] = useState<number>(0);
  const [costPerHour, setCostPerHour] = useState<number>(0);
  const [id, setId] = useState<string>('new');
  const formattedTimeDate = useFormattedDateLarge(initialData?.time || null);
  const { validateInput, resetError, errors } = useValidation();

  useEffect(() => {
    if (initialData?.costPerHour) {
      setName(initialData.name);
      setSupplierOwner(initialData.supplierOwner);
      setAmount(initialData.amount);
      setHourUsed(initialData.hoursUsed);
      setCostPerHour(initialData.costPerHour);
      setId(initialData.equipmentId);
      setTime(formattedTimeDate);
    }
  }, [initialData, formattedTimeDate]);

  const handleNameChange = (event: { target: { value: SetStateAction<string> } }) => {
    resetError('name');
    setName(event.target.value);
  };

  const handleSupplierOwnerChange = (event: { target: { value: SetStateAction<string> } }) => {
    resetError('supplierOwner');
    setSupplierOwner(event.target.value);
  };

  // const handleCrewAssignedChange = (event: { target: { value: SetStateAction<string | null> } }) => {
  //   resetError('name')
  //   setCrewAssigned(event.target.value);
  // };

  const handleAmountChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('amount');
    setAmount(Number(event.target.value));
  };

  const handleCostPerHourChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('costPerHour');
    setCostPerHour(Number(event.target.value));
  };

  const handleHoursUsedChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('hourUsed');
    setHourUsed(Number(event.target.value));
  };

  const handleTimeChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('time');
    setTime(event.target.value);
  };

  const submit = () => {
    const isSupplierOwner = validateInput('supplierOwner', supplierOwner);
    const isNameValid = validateInput('name', name);
    const isHoursUsed = validateInput('hourUsed', hoursUsed);
    const isAmountValid = validateInput('amount', amount);
    const isCostPerHour = validateInput('costPerHour', costPerHour);
    const isTimeValid = validateInput('time', time);

    if (isSupplierOwner && isNameValid && isHoursUsed && isAmountValid && isCostPerHour && isTimeValid && time) {
      const newInventory = inventory ? [...inventory] : [];
      const filteredInventory = newInventory.filter((item) => (item as { equipmentId: string }).equipmentId !== id);
      const timeDate = new Date(time).toISOString();

      const newItem = {
        ...(initialData ? { equipmentId: id } : {}),
        name,
        supplierOwner,
        time: timeDate,
        hoursUsed,
        amount,
        costPerHour,
      };

      filteredInventory.push(newItem);
      setInventory(filteredInventory);

      modalClose();
    }
  };

  const attemptCancel = () => {
    if (window.confirm('Sure? Your changes will be lost')) {
      modalClose();
    }
  };

  return (
    <Box>
      <Typography variant="h3">{'Add Equipment'}</Typography>

      <Box>
        <AltInput
          fullWidth
          autoComplete="project"
          label={localeProjects['name']}
          placeholder={`Equipment Name`}
          onChange={handleNameChange}
          value={name}
          error={errors.name}
          errorMessage={localeProjects['nameErrorMessage']}
        />
        <AltInput
          fullWidth
          autoComplete="project"
          label={'Supplier/ Owner'}
          placeholder={`Equipment Supplier/Owner`}
          onChange={handleSupplierOwnerChange}
          value={supplierOwner}
          error={errors.supplierOwner}
          errorMessage={'Must be at least 3 characters long'}
        />
        <DateInput
          fullWidth
          autoComplete="time"
          disabled
          showTimeSelect
          showIcon={true}
          label={'Supply/Delivery Date'}
          placeholder={`mm/dd/yyyy`}
          onChange={handleTimeChange}
          error={errors.time || null || undefined}
          value={time}
          errorMessage={localeProjects['startErrorMessage']}
        />
        <Stack direction="row" width={'50%'} paddingLeft={3}>
          <NumericFormatInput
            fullWidth
            autoComplete="project"
            label={'Hours Used'}
            placeholder={`120`}
            onChange={handleHoursUsedChange}
            disablePrefix
            value={hoursUsed}
            error={errors.hourUsed}
            errorMessage={localeProjects['budgetErrorMessage']}
          />
          <Typography variant="body2" sx={{ width: 100, justifyContent: 'center', fontSize: '14px', paddingTop: 1 }}>
            {'(estimated)'}
          </Typography>
        </Stack>
        <Stack direction="row" width={'50%'} paddingLeft={3}>
          <NumericFormatInput
            fullWidth
            autoComplete="project"
            label={'Amount'}
            placeholder={`120`}
            onChange={handleAmountChange}
            disablePrefix
            value={amount}
            error={errors.amount}
            errorMessage={localeProjects['budgetErrorMessage']}
          />
          <Typography variant="body2" sx={{ width: 100, justifyContent: 'center', fontSize: '14px', paddingTop: 1 }}>
            {'Units'}
          </Typography>
        </Stack>
        <Stack direction="row" width={'50%'} paddingLeft={3}>
          <NumericFormatInput
            fullWidth
            autoComplete="Budget"
            label={'Cost per Hour'}
            placeholder={`${localeProjects['project']} ${'Budget'}`}
            onChange={handleCostPerHourChange}
            value={costPerHour}
            error={errors.costPerHour}
            errorMessage={localeProjects['budgetErrorMessage']}
          />
          <Typography variant="body2" sx={{ width: 100, justifyContent: 'center', fontSize: '14px', paddingTop: 1 }}>
            {''}
          </Typography>
        </Stack>
      </Box>
      <Box className="flex justify-between mt-4">
        <Button onClick={attemptCancel} color="secondary">
          {localeCommon['cancel']}
        </Button>
        <Button onClick={submit} color="primary" autoFocus disabled={saving}>
          {localeCommon['create']}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEditEquipmentModal;
