import { ActionMessage } from '@models/common.model';
import { Alert, Snackbar, Theme, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { clearActionMessage } from '@redux/reducers/actionMessages.reducer';
import { RootState } from '@redux/store';

const ActionMessages: React.FC = () => {
  const { actionMessages } = useAppSelector((state: RootState) => state.actionMessages);

  const dispatch = useAppDispatch();
  const theme = useTheme();

  const handleCloseActionMessage = (actionMessage: ActionMessage) => {
    dispatch(clearActionMessage(actionMessage));
  };

  const getAlertStyles = (type: string, theme: Theme) => {
    switch (type) {
      case 'success':
        return { backgroundColor: theme.palette.feedbackSuccess.main, color: theme.palette.common.white };
      case 'warning':
        return { backgroundColor: theme.palette.feedbackWarning.main, color: theme.palette.common.white };
      case 'error':
        return { backgroundColor: theme.palette.feedbackError.main, color: theme.palette.common.white };
      case 'dark':
        return { backgroundColor: theme.palette.primary.main, color: theme.palette.common.white };
      case 'info':
        return { backgroundColor: theme.palette.common.white, color: theme.palette.primary.main };
      default:
        return { backgroundColor: theme.palette.primary.main, color: theme.palette.common.white };
    }
  };

  return (
    <>
      {actionMessages.map((actionMessage, index) => (
        <Snackbar
          key={actionMessage.id}
          open={true}
          autoHideDuration={5000}
          onClose={() => handleCloseActionMessage(actionMessage)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ marginTop: 12 * index }}>
          <Alert
            severity={actionMessage.type}
            onClose={() => handleCloseActionMessage(actionMessage)}
            icon={false}
            sx={{
              ...getAlertStyles(actionMessage.type, theme),
              display: 'flex',
              alignItems: 'center',
              maxWidth: '500px',
            }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <div className="text-body16 font-semibold mr-5">{actionMessage.title}</div>
              <div className="text-body16">{actionMessage.message}</div>
            </div>
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};
export default ActionMessages;
