import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomModal from '@components/generic/CustomModal';
import { graphQlClient, graphQlMultipleUpload, graphQlUpload } from '@config/graphqlClient';
import { useTranslation } from '@hooks/useTranslation';
import { SnackType } from '@models/common.model';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { useAppDispatch } from '@redux/hooks';
import CreateEditTaskModal from '@components/task/CreateEditTaskModal';
import CreateEditEquipmentModal from '@components/task/CreateEditEquipmentModal';
import CreateEditMaterialModal from '@components/task/CreateEditMaterialModal';
import { useHistory, useParams } from 'react-router-dom';
import { Equipment } from '@models/equipment.model';
import { Material } from '@models/material.model';
import TasksGraphQL from '@graphql/task.queries';
import { Task, deleteTaskRequest, getTaskRequest } from '@models/task.model';
import { Event, deleteEventRequest, getEventRequest } from '@models/event.model';
import CreateEditEventModal from '@components/event/CreateEditEventModal';
import EventsGraphQL from '@graphql/event.queries';
import TimeLineComponent from '@components/timeLine/TimeLine';
import AccordionUsage from '@components/accordion/accordion';
import { getProjectByIdRequest, getProjectsRequest } from '@models/project.model';
import ProjectsGraphQL from '@graphql/project.queries';
import { Circle } from '@mui/icons-material';
import COLORS from '@utils/color';

interface RouteParams {
  id: string;
}

const SchedulePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const { id = 'all' } = useParams<RouteParams>();

  type InventoryItem = Equipment | Material;
  const [showNewTaskModal, setShowNewTaskModal] = useState<boolean>(false);
  const [showNewEquipmentModal, setShowNewEquipmentModal] = useState<boolean>(false);
  const [showNewMaterialModal, setShowNewMaterialModal] = useState<boolean>(false);
  const [showNewEventModal, setShowNewEventModal] = useState<boolean>(false);
  const [selectedInventoryItem, setSelectedInventoryItem] = useState<InventoryItem | null>();
  const localeCommon = useTranslation('common');
  const [initialData, setInitialData] = useState<Task | null>(null);
  const [initialEvent, setInitialEvent] = useState<Event | null>(null);
  const [inventary, setInventory] = useState<InventoryItem[]>([]);
  const [projectId, setProjectId] = useState<string | null>(null);
  const [isDelegated, setIsDelegated] = useState<boolean>(false);

  useEffect(() => {
    if (id !== 'all') {
      getProjectById(id);
    }
  }, [id]);

  const getProjectById = async (projectId: string) => {
    setLoading(true);
    try {
      const variables = { id: projectId };
      const data: getProjectByIdRequest = await graphQlClient.request(
        ProjectsGraphQL.queries.getProjectById,
        variables,
      );
      if (data.getProjectByID.originalProjectId) {
        setIsDelegated(true);
      } else {
        setIsDelegated(false);
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const toggleNewTaskModal = (idProject: string | null) => {
    setProjectId(idProject);
    setShowNewTaskModal(!showNewTaskModal);
    if (!showNewTaskModal) {
      setInventory([]);
      setInitialData(null);
    }
  };

  const toggleNewEventModal = (idProject: string | null) => {
    setProjectId(idProject);
    setShowNewEventModal(!showNewEventModal);
    if (!showNewEventModal) {
      setInitialEvent(null);
    }
  };

  const toggleMaterialModal = () => {
    setShowNewMaterialModal(!showNewMaterialModal);
  };

  const toggleEquipmentModal = () => {
    setShowNewEquipmentModal(!showNewEquipmentModal);
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleSetTask = (id: string, tasks: Task[]) => {
    const initial = tasks.filter((task) => task.taskId === id);
    const combinedArray = [
      ...(initial[0].equipments ? initial[0].equipments : []),
      ...(initial[0].materials ? initial[0].materials : []),
    ];
    setInventory(combinedArray);
    if (initial && initial[0].projectId) {
      setProjectId(initial[0].projectId);
      setInitialData(initial[0]);
    }
    setShowNewTaskModal(!showNewTaskModal);
  };

  const handleSetEvent = (id: string, events: Event[]) => {
    const initial = events.filter((event) => event.eventId === id);
    if (initial && initial[0].projectId) {
      setProjectId(initial[0].projectId);
      setInitialEvent(initial[0]);
    }
    setShowNewEventModal(!showNewEventModal);
  };

  const createOrUpdateTask = async (
    name: string,
    description: string,
    taskType: string,
    status: string | null,
    otherType: string | null,
    manHours: number | null,
    startDate: Date | string,
    endDate: Date | string,
    timeConstraint: number,
    selectedTypeConstraint: string,
    crewIds?: string[] | undefined,
    equipmentsIds?: Equipment[],
    materialsIds?: Material[],
    deleteDocuments?: string[],
    presedentTaskId?: string[] | undefined,
    presedentEventId?: string[] | undefined,
    avatars?: File[] | null,
    taskId?: string,
    delegateOrganizationId?: string | null,
  ) => {
    setLoading(true);

    try {
      const query = taskId ? TasksGraphQL.mutations.updateTask : TasksGraphQL.mutations.createTask;
      const params = {
        task: {
          taskId,
          name,
          description,
          taskType,
          status: status === 'Unassigned' ? null : status,
          otherType,
          manHours,
          startTime: startDate,
          endTime: endDate,
          timeConstraint,
          typeOfTimeConstraint: selectedTypeConstraint,
          crewIds,
          projectId: projectId,
          equipmentsIds,
          materialsIds,
          presedentTaskId,
          presedentEventId,
          deleteDocuments,
          delegateOrganizationId,
        } as Task,
      };
      if (!taskId) {
        delete params.task.taskId;
      }
      const data =
        avatars && avatars.length > 0
          ? avatars.length === 1
            ? await graphQlUpload(avatars[0], 'task', query, params.task)
            : await graphQlMultipleUpload(avatars, 'task', query, params.task)
          : await graphQlClient.request(query, params);
      if (data?.createTask?.taskId || data?.updateTask?.taskId) {
        // const updated = (data?.createTask || data?.updateTask) as Task
        // console.log('updated', updated)
        dispatch(
          appendActionMessage({
            message: taskId ? localeCommon['updatedSuccessfully'] : localeCommon['createdSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        getTasks(false, id);
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const createOrUpdateEvent = async (
    name: string,
    eventType: string,
    otherEventType: string | null,
    eventTime: Date | string,
    incidentType: string | null,
    addedIncidentType: string | null,
    inspectionType: string | null,
    permit: string | null | undefined,
    delay: number | null,
    delayType: string | null,
    notes: string | null | undefined,
    precedentTaskId?: string[],
    eventId?: string,
  ) => {
    setLoading(true);

    try {
      const query = eventId ? EventsGraphQL.mutations.updateEvent : EventsGraphQL.mutations.createEvent;
      const params = {
        event: {
          eventId,
          name,
          eventType,
          otherEventType,
          eventTime,
          incidentType,
          addedIncidentType,
          inspectionType,
          permit,
          delay,
          delayType,
          notes,
          projectId: projectId,
          precedentTaskId,
        } as Event,
      };
      if (!eventId) {
        delete params.event.eventId;
      }
      const data: any = await graphQlClient.request(query, params);
      if (data?.createEvent?.eventId || data?.updateEvent?.eventId) {
        dispatch(
          appendActionMessage({
            message: eventId ? localeCommon['updatedSuccessfully'] : localeCommon['createdSuccessfully'],
            type: SnackType.SUCCESS,
          }),
        );
        getEvents(false, id);
      }
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteTask = async (task: Task) => {
    if (window.confirm('Sure? Your changes will be lost')) {
      setLoading(true);

      try {
        const deleteResult: deleteTaskRequest = await graphQlClient.request(TasksGraphQL.mutations.deleteTask, {
          id: task.taskId,
        });
        if (deleteResult?.deleteTask?.affected > 0) {
          dispatch(appendActionMessage({ message: localeCommon['deletedSuccessfully'], type: SnackType.SUCCESS }));
        }
      } catch (e: any) {
        dispatch(
          appendActionMessage({
            message: e?.response?.errors[0]?.message || localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
      } finally {
        getTasks(false, id);
      }
    }
  };

  const deleteEvent = async (event: Event) => {
    if (window.confirm('Sure? Your changes will be lost')) {
      setLoading(true);

      try {
        const deleteResult: deleteEventRequest = await graphQlClient.request(EventsGraphQL.mutations.deleteEvent, {
          id: event.eventId,
        });
        if (deleteResult?.deleteEvent?.affected > 0) {
          dispatch(appendActionMessage({ message: localeCommon['deletedSuccessfully'], type: SnackType.SUCCESS }));
        }
      } catch (e: any) {
        dispatch(
          appendActionMessage({
            message: e?.response?.errors[0]?.message || localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
      } finally {
        getEvents(false, id);
      }
    }
  };

  useEffect(() => {
    getTasks(true, id);
    getEvents(true, id);
  }, []);

  const getTasks = async (initialize = false, projectId: string) => {
    setLoading(true);
    try {
      const data: getTaskRequest = await graphQlClient.request(TasksGraphQL.queries.getTasks, { projectId });
      setTasks(data.getTasks);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const getEvents = async (initialize = false, projectId: string) => {
    setLoading(true);
    try {
      const data: getEventRequest = await graphQlClient.request(EventsGraphQL.queries.getEvents, { projectId });
      setEvents(data.getEvents);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ flex: 1, background: '#333333' }}>
      <CustomModal isOpen={!!showNewTaskModal} style={{ maxWidth: 500 }}>
        <CreateEditTaskModal
          modalClose={toggleNewTaskModal}
          modalEquipmentClose={toggleEquipmentModal}
          modalMaterialClose={toggleMaterialModal}
          createTask={createOrUpdateTask}
          editTask={createOrUpdateTask}
          saving={false}
          initialData={initialData}
          setInventory={setInventory}
          inventory={inventary}
          tasks={initialData?.taskId ? tasks.filter((task) => task.taskId !== initialData?.taskId) : tasks}
          // events={initialEvent?.eventId ? events.filter((event) => event.eventId !== initialEvent?.eventId) : events}
          events={events}
          setSelectedInventoryItem={setSelectedInventoryItem}
        />
      </CustomModal>
      <CustomModal isOpen={showNewEquipmentModal} style={{ maxWidth: 500 }}>
        <CreateEditEquipmentModal
          modalClose={toggleEquipmentModal}
          saving={false}
          initialData={selectedInventoryItem}
          setInventory={setInventory}
          inventory={inventary}
        />
      </CustomModal>
      <CustomModal isOpen={showNewMaterialModal} style={{ maxWidth: 500 }}>
        <CreateEditMaterialModal
          modalClose={toggleMaterialModal}
          saving={false}
          initialData={selectedInventoryItem}
          setInventory={setInventory}
          inventory={inventary}
        />
      </CustomModal>
      <CustomModal isOpen={!!showNewEventModal} style={{ maxWidth: 500 }}>
        <CreateEditEventModal
          modalClose={toggleNewEventModal}
          createEvent={createOrUpdateEvent}
          editEvent={createOrUpdateEvent}
          saving={false}
          initialData={initialEvent}
          tasks={tasks}
        />
      </CustomModal>
      {id !== 'all' ? (
        <div>
          <TimeLineComponent
            isDelegated={isDelegated}
            setTask={handleSetTask}
            setEvent={handleSetEvent}
            projectId={id}
            handleBack={handleBack}
            toggleNewTaskModal={toggleNewTaskModal}
            toggleNewEventModal={toggleNewEventModal}
          />
          <div
            style={{
              display: 'flex',
              backgroundColor: 'rgba(255,255,255,0.3)',
              height: 50,
              width: 600,
              position: 'fixed',
              zIndex: 2,
              right: 0,
              bottom: 0,
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 10,
            }}>
            <Box sx={{ display: 'flex' }}>
              <Circle style={{ color: COLORS.lightBlue }} fontSize="small" />
              <Typography sx={{}}>Competed</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Circle style={{ color: COLORS.green }} fontSize="small" />
              <Typography sx={{}}>On Track</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Circle style={{ color: COLORS.yellow }} fontSize="small" />
              <Typography sx={{}}>Delayed</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Circle style={{ color: COLORS.orange }} fontSize="small" />
              <Typography sx={{}}>On Hold</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Circle style={{ color: COLORS.darkUltraGray }} fontSize="small" />
              <Typography sx={{}}>Assigned</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Circle style={{ color: COLORS.darkGray }} fontSize="small" />
              <Typography sx={{}}>Unassigned</Typography>
            </Box>
          </div>
        </div>
      ) : (
        <AccordionUsage
          setTask={handleSetTask}
          setEvent={handleSetEvent}
          handleBack={handleBack}
          toggleNewTaskModal={toggleNewTaskModal}
          toggleNewEventModal={toggleNewEventModal}
        />
      )}
    </Box>
  );
};

export default SchedulePage;
