import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { BaseTextFieldProps, Box, IconButton, TextField, TextFieldProps, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import COLORS from '@utils/color';
import { InfoOutlined } from '@mui/icons-material';
import { getPlacesRequest, Place } from '@models/place.model';
import MapsGraphQL from '@graphql/google-maps-service.queries';
import { graphQlClient } from '@config/graphqlClient';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInput-underline:before': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInput-root .MuiInput-input': {
    padding: '4px 4px',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: COLORS.lightBlue,
    borderBottomWidth: 1,
  },
  '& .MuiInputBase-root': {
    boxSizing: 'border-box',
    height: '40px',
    '& .MuiInputBase-input': {
      padding: '8px 16px',
      height: '40px',
      boxSizing: 'border-box',
    },
  },
  '& .MuiInputLabel-root': {
    display: 'none', // Hide the label by default
  },
  '& .MuiOutlinedInput-root': {
    border: `1px solid ${theme.palette.grey[300]}`,
    transition: 'border-color 0.3s ease',
    '&:hover': {
      border: `1px solid ${theme.palette.grey[400]}`, // Hover state
    },
    '&.Mui-focused': {
      outline: 'none', // Remove default focus outline
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${theme.palette.primary.main}`, // Focused state
    },
    '&.Mui-error fieldset': {
      border: `1px solid ${theme.palette.error.main}`, // Error state
    },
    '&.Mui-disabled fieldset': {
      color: theme.palette.grey[500],
      border: `1px solid ${theme.palette.grey[200]}`, // Disabled state
      background: theme.palette.action.disabledBackground,
    },
  },
}));

interface AltInputProps extends BaseTextFieldProps {
  label: string;
  name?: string;
  placeholder?: string;
  infoText?: string;
  showIcon?: boolean;
  icon?: React.ReactNode;
  iconAction?: () => void;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  value: string;
  onChange: (address: string, lat?: Number, long?: Number) => void;
  onChangeCoor?: (lat?: number | null | undefined, long?: number | null | undefined) => void;
}

export default function AddressSelect({
  label,
  name,
  placeholder,
  infoText,
  showIcon = false,
  icon,
  iconAction,
  error = false,
  errorMessage,
  disabled,
  value,
  onChange,
  onChangeCoor,
  ...props
}: AltInputProps) {
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [selectedCountry, setSelectedAddress] = React.useState<Place | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [places, setPlaces] = useState<Place[]>([]);

  useEffect(() => {
    if (searchValue.length > 3) {
      getPlaces();
    } else {
      setPlaces([]);
    }
    onChange(searchValue);
  }, [searchValue]);

  const getPlaces = async () => {
    setLoading(true);
    try {
      const data: getPlacesRequest = await graphQlClient.request(MapsGraphQL.queries.getAutocomplete, {
        input: searchValue,
      });
      setPlaces(data.autocomplete);
    } catch (e: any) {
      console.log('error', e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box className="flex w-full" sx={{ alignItems: 'center' }}>
      {label && (
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            width: 100,
            textAlign: 'right',
            marginRight: '10px',
            fontSize: '14px',
          }}>
          {label}
          {infoText && (
            <Tooltip title={infoText}>
              <IconButton size="small">
                <InfoOutlined sx={{ width: 18, height: 18 }} className="text-primaryDark" />
              </IconButton>
            </Tooltip>
          )}
        </Typography>
      )}
      <Autocomplete
        id="country-select-demo"
        sx={{ width: '100%' }}
        options={places}
        autoHighlight
        getOptionLabel={(option) => option.description}
        inputValue={value || searchValue}
        onChange={(_, selectedOption) => {
          if (selectedOption) {
            setSearchValue(selectedOption.description);
            setSelectedAddress(selectedOption);
            if (onChangeCoor) {
              onChangeCoor(selectedOption.latitude, selectedOption.longitude);
            }
          }
        }}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.description}
          </Box>
        )}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            placeholder={placeholder}
            error={error}
            helperText={error ? errorMessage : ''}
            variant="standard"
            name={name}
            {...props}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchValue(event.target.value);
              setSelectedAddress(null);
              if (onChangeCoor) {
                onChangeCoor(null, null);
              }
            }}
          />
        )}
      />
    </Box>
  );
}
