import AltInput from '@components/ui/AltInput';
import DateInput from '@components/ui/DateInput';
import NumericFormatInput from '@components/ui/NumericFormatInput';
import Button from '@components/ui/Button';
import { useTranslation } from '@hooks/useTranslation';
import { Box, CircularProgress, FormControlLabel, Switch, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { SetStateAction, useEffect, useState } from 'react';
import LogoPicture from '@components/generic/LogoPicture';
import useFormattedDate from '@hooks/useFormattedDate';
import useValidation from '@hooks/useValidation';
import SelectInput from '@components/ui/SelectInput';
import { PeriodOptions, FullTimeOptions } from '@utils/options';
import { useAppSelector } from '@redux/hooks';
import { RootState } from '@redux/store';
import { UserRoles } from '@models/user.model';

interface CreateEditEmployeeModalProps {
  modalClose: () => void;
  createProject?: (
    firstName: string,
    lastName: string,
    email: string,
    dob: Date,
    hireDate: Date,
    rate: number,
    selectedPayPeriod: string,
    selectedMode: string | null,
    roleType: string,
    avatar?: File | null,
  ) => void;
  editProject?: (
    firstName: string,
    lastName: string,
    email: string,
    dob: Date,
    hireDate: Date,
    rate: number,
    selectedPayPeriod: string,
    selectedMode: string | null,
    roleType: string,
    avatar?: File | null,
    employeeId?: string,
    userId?: string,
  ) => void;
  saving: boolean;
  disabled?: boolean;
  initialData?: any;
  errorMessage?: string | undefined;
  loading?: boolean;
}

const CreateEditEmployeeModal: React.FC<CreateEditEmployeeModalProps> = ({
  modalClose,
  createProject,
  editProject,
  saving,
  disabled,
  initialData,
  errorMessage,
  loading,
}) => {
  const localeCommon = useTranslation('common');
  const localeProjects = useTranslation('projects');
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [dob, setDob] = useState<string | null>(null);
  const [hireDate, setHireDate] = useState<string | null>(null);
  const [rate, setRate] = useState<number | null>(null);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [siteImage, setSiteImage] = useState<string | null | undefined>('');
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [selectedPayPeriod, setSelectPayPeriod] = useState<string | null>('');
  const [selectedMode, setSelectedMode] = useState<string | null>('');
  const formattedDoB = useFormattedDate(initialData?.dob || null);
  const formattedHireDate = useFormattedDate(initialData?.hireDate || null);
  const [roleType, setRoleType] = useState<string>('EMPLOYEE');
  const { role } = useAppSelector((state: RootState) => state.user);
  const { validateInput, resetError, errors } = useValidation();

  useEffect(() => {
    if (
      initialData &&
      initialData?.user &&
      initialData?.user?.firstName &&
      initialData?.user?.lastName &&
      initialData.user?.email &&
      initialData.user?.role
    ) {
      setFirstName(initialData?.user?.firstName);
      setLastName(initialData?.user?.lastName);
      setEmail(initialData.user?.email);
      setRoleType(initialData.user?.role);
      setDob(formattedDoB);
      setHireDate(formattedHireDate);
      setRate(initialData.rate);
      setSiteImage(initialData.user?.avatar);
      setSelectPayPeriod(initialData.payPeriod);
      setSelectedMode(initialData.mode);
    }
  }, [initialData, formattedDoB, formattedHireDate]);

  const handleFirstNameChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('name');
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('lastName');
    setLastName(event.target.value);
  };

  const handleEmailChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('email');
    setEmail(event.target.value);
  };

  const handleDobChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('startDate');
    setDob(event.target.value);
  };

  const handleHireDateChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('endDate');
    setHireDate(event.target.value);
  };

  const handleRateChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('budget');
    setRate(Number(event.target.value));
  };

  const handlePayPeriodChange = (event: React.SyntheticEvent, value: { id: string; name: string } | null) => {
    resetError('timeType');
    if (value) {
      setSelectPayPeriod(value.id);
    } else {
      setSelectPayPeriod(null);
    }
  };

  const handleModeChange = (event: React.SyntheticEvent, value: { id: string; name: string } | null) => {
    resetError('mode');
    if (value) {
      setSelectedMode(value.id);
    } else {
      setSelectedMode(null);
    }
  };

  const handleRoleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newType = e.target.checked ? 'SUPER_EMPLOYEE' : 'EMPLOYEE';
    setRoleType(newType);
  };

  const submit = () => {
    const isLastNameValid = validateInput('lastName', lastName);
    const isNameValid = validateInput('name', firstName);
    const isEmailValid = validateInput('email', email);
    const isDobValid = validateInput('startDate', dob);
    const isHireDateValid = validateInput('endDate', hireDate);
    const isRateValid = validateInput('budget', rate);
    const isPayPeriodValid = validateInput('timeType', selectedPayPeriod);
    const isModeValid = validateInput('mode', selectedMode);

    if (
      hireDate &&
      dob &&
      rate &&
      isNameValid &&
      firstName &&
      lastName &&
      isLastNameValid &&
      isEmailValid &&
      email &&
      isDobValid &&
      isRateValid &&
      isHireDateValid &&
      isPayPeriodValid &&
      isModeValid &&
      selectedPayPeriod
    ) {
      const dobFormatDate = new Date(dob);
      const hireFormatDate = new Date(hireDate);
      if (initialData) {
        editProject &&
          editProject(
            firstName,
            lastName,
            email,
            dobFormatDate,
            hireFormatDate,
            rate,
            selectedPayPeriod,
            selectedMode,
            roleType,
            avatar ? avatar : null,
            initialData.employeeId || '',
            initialData.user?.userId,
          );
      } else {
        createProject &&
          createProject(
            firstName,
            lastName,
            email,
            dobFormatDate,
            hireFormatDate,
            rate,
            selectedPayPeriod,
            selectedMode,
            roleType,
            avatar ? avatar : null,
          );
      }
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setAvatar(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const attemptCancel = () => {
    if (!disabled) {
      if (window.confirm('Sure? Your changes will be lost')) {
        modalClose();
      }
    } else {
      modalClose();
    }
  };

  return (
    <Box>
      <Typography variant="h3">{`${disabled ? '' : 'Add New'} Employee`}</Typography>

      <Box>
        <AltInput
          disabled={disabled}
          fullWidth
          autoComplete="employee"
          label={'First Name'}
          placeholder={`Employee First Name`}
          onChange={handleFirstNameChange}
          value={firstName}
          error={errors.name}
          errorMessage={'First Name must be at least 3 characters long'}
        />
        <AltInput
          disabled={disabled}
          fullWidth
          autoComplete="employee"
          label={'Last Name'}
          placeholder={`Employee Last Name`}
          onChange={handleLastNameChange}
          value={lastName}
          error={errors.lastName}
          errorMessage={'Last Name must be at least 3 characters long'}
        />
        <AltInput
          disabled={initialData?.user?.userId ? true : false}
          fullWidth
          autoComplete="employee"
          label={'Email'}
          placeholder={`Employee Email`}
          onChange={handleEmailChange}
          value={email}
          error={errors.email}
          errorMessage={'Invalid email format'}
        />
        <DateInput
          disabled={true}
          fullWidth
          autoComplete="employee"
          label={'Date of Birth'}
          showIcon={!disabled}
          placeholder={`mm/dd/yyyy`}
          onChange={handleDobChange}
          error={errors.startDate || null || undefined}
          value={dob}
          errorMessage={localeProjects['startErrorMessage']}
        />
        <DateInput
          disabled={true}
          fullWidth
          autoComplete="employee"
          label={'Hire Date'}
          showIcon={!disabled}
          placeholder={`mm/dd/yyyy`}
          onChange={handleHireDateChange}
          error={errors.endDate || null || undefined}
          value={hireDate}
          errorMessage={localeProjects['startErrorMessage']}
        />
        <Stack direction="row" style={{ marginLeft: '22px' }} spacing={1}>
          <NumericFormatInput
            disabled={disabled}
            fullWidth
            autoComplete="Rate"
            label={'Rate'}
            placeholder={`Employee Rate`}
            onChange={handleRateChange}
            value={rate}
            error={errors.budget}
            errorMessage={localeProjects['budgetErrorMessage']}
          />
          <SelectInput
            disabled={disabled}
            label={''}
            placeholder={'Pay Period'}
            items={PeriodOptions}
            value={selectedPayPeriod}
            valueKey="id"
            labelKey="name"
            error={errors.timeType}
            errorMessage={'Required field'}
            onChange={handlePayPeriodChange}
          />
          <SelectInput
            disabled={disabled}
            label={''}
            placeholder={'mode'}
            items={FullTimeOptions}
            value={selectedMode}
            valueKey="id"
            labelKey="name"
            error={errors.mode}
            errorMessage={'Required field'}
            onChange={handleModeChange}
          />
        </Stack>
        {role === UserRoles.OWNER && (
          <Stack direction="row" width={'100%'} paddingLeft={1} sx={{ paddingTop: 1 }}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 0, marginRight: 0 }}>
              <Typography sx={{ textAlign: 'center', marginRight: 3 }}>SUPER</Typography>
              <FormControlLabel
                control={
                  <Switch checked={roleType === 'SUPER_EMPLOYEE'} onChange={handleRoleTypeChange} color="primary" />
                }
                label=""
              />
            </Box>
          </Stack>
        )}
        <Box display="flex" justifyContent="center" alignItems="center">
          <LogoPicture
            src={imagePreview || initialData?.user?.avatar}
            name={'Photo'}
            handleImageChange={handleImageChange}
            disabled={disabled}
          />
        </Box>
        <Box>
          <Typography sx={{ color: 'red', textAlign: 'center' }}>{errorMessage}</Typography>
        </Box>
      </Box>
      <Box className="flex justify-between mt-4">
        <Button onClick={attemptCancel} color="secondary">
          {localeCommon['cancel']}
        </Button>
        {disabled ? null : (
          <Button onClick={submit} color="primary" autoFocus disabled={saving}>
            {loading ? <CircularProgress /> : initialData ? localeCommon['saveChanges'] : localeCommon['create']}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CreateEditEmployeeModal;
