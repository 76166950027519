import AltInput from '@components/ui/AltInput';
import PhoneInput from '@components/ui/PhoneInput';
import Button from '@components/ui/Button';
import LogoPicture from '@components/generic/LogoPicture';
import { useTranslation } from '@hooks/useTranslation';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useValidation from '@hooks/useValidation';
import AddressSelect from '@components/ui/AutocompleteInput';
import AutocompleteMultipleInput from '@components/ui/AutocompleteMultipleInput';
import { Project, getProjectsRequest } from '@models/project.model';
import { graphQlClient } from '@config/graphqlClient';
import ProjectsGraphQL from '@graphql/project.queries';

interface CreateEditClientModalProps {
  modalClose: () => void;
  createClient?: (
    name: string,
    address: string,
    phone: string,
    email: string,
    avatar: File | null,
    projectIds: string[],
  ) => void;
  editClient?: (
    name: string,
    address: string,
    phone: string,
    email: string,
    avatar: File | null,
    projectIds: string[],
    clientId: string,
  ) => void;
  saving: boolean;
  initialData?:
    | {
        name: string;
        address: string;
        phone: string;
        email: string;
        logo?: string | null;
        projects?: Project[];
        clientId?: string;
      }
    | null
    | undefined;
}

const CreateEditClientModal: React.FC<CreateEditClientModalProps> = ({
  modalClose,
  createClient,
  editClient,
  saving,
  initialData,
}) => {
  const localeCommon = useTranslation('common');
  const localeClients = useTranslation('clients');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [avatar, setAvatar] = useState<File | null>(null);
  const [logo, setLogo] = useState<string | null | undefined>('');
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { validateInput, resetError, errors } = useValidation();

  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
      setAddress(initialData.address);
      setPhone(initialData.phone);
      setEmail(initialData.email);
      setLogo(initialData.logo);
      setSelectedProjects(initialData.projects || []);
      const projectIds = initialData.projects
        ?.filter((project: Project) => project.projectId !== undefined)
        .map((project: Project) => project.projectId as string);
      setSelectedIds(projectIds || []);
    }
  }, [initialData]);

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    try {
      const data: getProjectsRequest = await graphQlClient.request(ProjectsGraphQL.queries.getProjects);
      setProjects(data.getProjects);
    } catch (e: any) {
      console.log(e);
    }
  };

  const handleChange = (event: React.SyntheticEvent, value: Project[]) => {
    setSelectedProjects(value);
    const ids = value.map((item) => item?.projectId).filter((id): id is string => id !== undefined);
    setSelectedIds(ids);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>,
    clearError: () => void,
  ) => {
    clearError();
    setter(event.target.value);
  };

  const handleAddressChange = (value: string) => {
    resetError('address');
    setAddress(value);
  };

  const submit = () => {
    const isEmailValid = validateInput('email', email);
    const isPhoneValid = validateInput('phone', phone);
    const isNameValid = validateInput('name', name);
    const isAddressValid = validateInput('address', address);

    if (isEmailValid && isPhoneValid && isNameValid && isAddressValid) {
      if (initialData) {
        editClient && editClient(name, address, phone, email, avatar, selectedIds, initialData.clientId || '');
      } else {
        createClient && createClient(name, address, phone, email, avatar, selectedIds);
      }
      modalClose();
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      setAvatar(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const attemptCancel = () => {
    if (window.confirm('Sure? Your changes will be lost')) {
      modalClose();
    }
  };

  return (
    <Box>
      <Typography variant="h3">{localeClients['addClient']}</Typography>

      <Box>
        <AltInput
          fullWidth
          autoComplete="client"
          label={localeClients['name']}
          placeholder={`${localeClients['client']} ${localeClients['name']}`}
          onChange={(e) => handleInputChange(e, setName, () => resetError('name'))}
          value={name}
          error={errors.name}
          errorMessage={localeClients['nameErrorMessage']}
        />
        <AddressSelect
          fullWidth
          autoComplete="address"
          label={localeClients['address']}
          placeholder={`${localeClients['client']} ${localeClients['address']}`}
          onChange={handleAddressChange}
          value={address}
          error={errors.address}
          errorMessage={localeClients['addressErrorMessage']}
        />
        <PhoneInput
          fullWidth
          autoComplete="phone"
          label={localeClients['phone']}
          placeholder={`${localeClients['client']} ${localeClients['phone']}`}
          onChange={(e) => handleInputChange(e, setPhone, () => resetError('phone'))}
          value={phone}
          error={errors.phone}
          errorMessage={localeClients['phoneErrorMessage']}
        />
        <AltInput
          fullWidth
          autoComplete="email"
          label={localeClients['email']}
          placeholder={`${localeClients['client']} ${localeClients['email']}`}
          onChange={(e) => handleInputChange(e, setEmail, () => resetError('email'))}
          value={email}
          error={errors.email}
          errorMessage={localeClients['emailErrorMessage']}
        />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <LogoPicture src={imagePreview || initialData?.logo} name="Logo" handleImageChange={handleImageChange} />
      </Box>
      <AutocompleteMultipleInput<Project>
        fullWidth
        label="Select Projects"
        items={projects}
        value={selectedProjects}
        getOptionLabel={(option) => option.name}
        placeholder="Select Projects"
        isOptionEqualToValue={(option, value) => option.projectId === value.projectId}
        onChange={(event, newValue) => setSelectedProjects(newValue)}
        error={errors.address}
        errorMessage="Error selecting projects"
      />

      <Box className="flex justify-between mt-4">
        <Button onClick={attemptCancel} color="secondary">
          {localeCommon['cancel']}
        </Button>
        <Button onClick={submit} color="primary" autoFocus disabled={saving}>
          {initialData ? localeCommon['saveChanges'] : localeCommon['create']}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEditClientModal;
