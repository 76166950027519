import { Box, ListItemButton, ListItemButtonProps, Typography, styled } from '@mui/material';
import COLORS from '@utils/color';

interface SidebarButtonProps extends ListItemButtonProps {
  icon: React.ReactNode;
  label?: string;
  tooltip?: string;
  labelColor?: string;
  labelStyle?: object;
  delegated?: boolean;
}

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1, 0),
  width: '100%',
  '&:hover': {
    backgroundColor: '#1f282d',
  },
  '&.Mui-selected': {
    backgroundColor: '#e77c49',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#1f282d',
    },
  },
  borderBottom: `1px solid #4d9dc4`,
}));

const Label = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  textAlign: 'center',
  color: 'white',
}));

const SidebarButton: React.FC<SidebarButtonProps> = ({
  icon,
  label,
  tooltip,
  labelColor,
  labelStyle,
  delegated,
  ...props
}) => (
  <StyledListItemButton {...props}>
    <Box className="flex flex-wrap justify-start items-center !h-8 !md-h-12 !lg:h-14 !px-6">
      {icon}
      {label && (
        <Box className="ml-2 flex items-center">
          <Label
            variant="body14"
            color="primary"
            sx={{
              color: labelColor,
              ...{ ...labelStyle },
            }}>
            {label}
          </Label>
          {delegated && (
            <Label variant="body14" sx={{ color: COLORS.darkBlue, marginLeft: '4px' }}>
              (delegated)
            </Label>
          )}
        </Box>
      )}
    </Box>
  </StyledListItemButton>
);

export default SidebarButton;
