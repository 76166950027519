import { useTranslation } from '@hooks/useTranslation';
import { Organization } from '@models/organization.model';
import { Box, Button, Typography } from '@mui/material';

interface EnableOrganizationModalProps {
  modalClose: () => void;
  enableOrganization: () => void;
  organization: Organization | undefined;
}

//TODO: Maybe this can be consolidated with the DisableUserModal
const EnableOrganizationModal: React.FC<EnableOrganizationModalProps> = ({
  modalClose,
  enableOrganization,
  organization,
}) => {
  const localeCommon = useTranslation('common');
  const localeOrganizations = useTranslation('organizations');

  return (
    <Box>
      <Typography variant="h5">{localeOrganizations['enableOrganization']}</Typography>

      <Typography variant="h6">{localeOrganizations['confirmEnableMessage']}</Typography>

      <Typography variant="h6">{organization?.name}</Typography>

      <Box className="flex justify-between">
        <Button onClick={modalClose} color="primary">
          {localeCommon['cancel']}
        </Button>
        <Button onClick={enableOrganization} color="primary" autoFocus>
          {localeCommon['enable']}
        </Button>
      </Box>
    </Box>
  );
};

export default EnableOrganizationModal;
