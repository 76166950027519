import { useTranslation } from '@hooks/useTranslation';
import { User } from '@models/user.model';
import { Box, Button, Typography } from '@mui/material';

interface DisableUserModalProps {
  modalClose: () => void;
  disableUser: () => void;
  user: User | undefined;
}

const DisableUserModal: React.FC<DisableUserModalProps> = ({ modalClose, disableUser, user }) => {
  const localeCommon = useTranslation('common');
  const localeUsers = useTranslation('users');

  return (
    <Box>
      <Typography variant="h5">{localeUsers['disableUser']}</Typography>

      <Typography variant="h6">{localeUsers['confirmDisableMessage']}</Typography>

      <Typography variant="h6">{user?.email}</Typography>

      <Box className="flex justify-between">
        <Button onClick={modalClose} color="primary">
          {localeCommon['cancel']}
        </Button>
        <Button onClick={() => disableUser()} color="primary" autoFocus>
          {localeCommon['disable']}
        </Button>
      </Box>
    </Box>
  );
};

export default DisableUserModal;
