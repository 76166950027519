import AltInput from '@components/ui/AltInput';
import DateInput from '@components/ui/DateInput';
import NumericFormatInput from '@components/ui/NumericFormatInput';
import Button from '@components/ui/Button';
import { useTranslation } from '@hooks/useTranslation';
import { Box, Typography, Switch, FormControlLabel } from '@mui/material';
import Stack from '@mui/material/Stack';
import { SetStateAction, useEffect, useState } from 'react';
import useFormattedDateLarge from '@hooks/useFormattedDateLarge';
import useValidation from '@hooks/useValidation';
import useCompareDates from '@hooks/useCompareDates';
import SelectInput from '@components/ui/SelectInput';
import CustomizedHook from '@components/ui/CustomizedHookInput';
import { styled } from '@mui/system';
import { Equipment } from '@models/equipment.model';
import { Material } from '@models/material.model';
import { Task } from '@models/task.model';
import { Event } from '@models/event.model';
import { TaskOptions, TaskStatusOptions, TimeOptions } from '@utils/options';
import AutocompleteMultipleInput from '@components/ui/AutocompleteMultipleInput';
import { Crew, getCrewsRequest } from '@models/crew.model';
import { graphQlClient } from '@config/graphqlClient';
import CrewsGraphQL from '@graphql/crew.queries';
import { useAppDispatch } from '@redux/hooks';
import { appendActionMessage } from '@redux/reducers/actionMessages.reducer';
import { SnackType } from '@models/common.model';
import { Organization, getOrganizationsForTaskCreationRequest } from '@models/organization.model';
import OrganizationsGraphQL from '@graphql/organization.queries';
import COLORS from '@utils/color';
import SelectStatusInput from '@components/ui/SelectStatusInput';

type InventoryItem = Equipment | Material;

interface CreateEditTaskModalProps {
  modalClose: (idProject: string | null) => void;
  modalEquipmentClose: () => void;
  modalMaterialClose: () => void;
  createTask?: (
    name: string,
    description: string,
    taskType: string,
    status: string | null,
    otherType: string | null,
    manHours: number | null,
    startDate: Date | string,
    endDate: Date | string,
    timeConstraint: number,
    selectedTypeConstraint: string,
    crewIds: string[] | undefined,
    equipmentsIds?: Equipment[],
    materialsIds?: Material[],
    deleteDocuments?: string[],
    presedentTaskId?: string[],
    presendentEventId?: string[],
    avatars?: File[] | null,
    taskId?: string,
    delegateOrganizationId?: string | null,
  ) => void;
  editTask?: (
    name: string,
    description: string,
    taskType: string,
    status: string | null,
    otherType: string | null,
    manHours: number | null,
    startDate: Date | string,
    endDate: Date | string,
    timeConstraint: number,
    selectedTypeConstraint: string,
    crewIds: string[] | undefined,
    equipmentsIds?: Equipment[],
    materialsIds?: Material[],
    deleteDocuments?: string[],
    presedentTaskId?: string[],
    presendentEventId?: string[],
    avatars?: File[] | null,
    taskId?: string,
    delegateOrganizationId?: string | null,
  ) => void;
  saving: boolean;
  initialData?: Task | null | undefined;
  setInventory: (inventory: InventoryItem[]) => void;
  inventory: InventoryItem[];
  tasks: Task[];
  events: Event[];
  setSelectedInventoryItem: (value: InventoryItem | null) => void;
}

interface DocumentObject {
  documentUrl: string | undefined;
  name: string;
}

const VisuallyHiddenInput = styled('input')({
  display: 'none',
});

const CreateEditTaskModal: React.FC<CreateEditTaskModalProps> = ({
  modalClose,
  modalEquipmentClose,
  modalMaterialClose,
  createTask,
  editTask,
  saving,
  initialData,
  setInventory,
  inventory,
  tasks,
  events,
  setSelectedInventoryItem,
}) => {
  const dispatch = useAppDispatch();
  const localeCommon = useTranslation('common');
  const localeProjects = useTranslation('projects');
  const [name, setName] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [start, setStart] = useState<string | null>(null);
  const [end, setEnd] = useState<string | null>(null);
  const [manHours, setManHours] = useState<number | null>(null);
  const [timeConstraint, setTimeConstraint] = useState<number | null>(null);
  const [otherType, setOtherType] = useState<string | null>(null);
  const [avatars, setAvatars] = useState<File[]>([]);
  const [deleteDocuments, setDeleteDocuments] = useState<string[]>([]);
  const [preseding, setPreseding] = useState<string[] | undefined>([]);
  const [presedingEvent, setPresedingEvent] = useState<string[] | undefined>([]);
  const [documents, setDocuments] = useState<DocumentObject[]>([]);
  const [crews, setCrews] = useState<Crew[]>([]);
  const [items, setItems] = useState<Crew[] | undefined>([]);
  const formattedStartDate = useFormattedDateLarge(initialData?.startTime || null);
  const formattedEndDate = useFormattedDateLarge(initialData?.endTime || null);
  const isDateRangeInvalid = useCompareDates(start, end);
  const [selectedType, setSelectedType] = useState<string | null | undefined>('');
  const [selectedStatus, setSelectedStatus] = useState<string | null>('Unassigned');
  const [selectedTypeConstraint, setSelectedTypeConstraint] = useState<string | null>('');
  const [delegationType, setDelegationType] = useState<'crew' | 'subcontractor'>('crew');
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);

  const { validateInput, resetError, errors } = useValidation();

  useEffect(() => {
    if (initialData) {
      if (initialData.documents) {
        const documentArray = initialData.documents.map((document, index) => ({
          documentUrl: document.url,
          name: document.name,
          documentId: document.documentId,
        }));
        setDocuments(documentArray);
      }
      setName(initialData.name);
      setDescription(initialData.description);
      setStart(formattedStartDate);
      setEnd(formattedEndDate);
      setManHours(initialData.manHours);
      setSelectedType(initialData.taskType);
      setSelectedStatus(initialData.status ? initialData.status : 'Unassigned');
      setOtherType(initialData.otherType);
      setSelectedTypeConstraint(initialData.typeOfTimeConstraint);
      setTimeConstraint(initialData.timeConstraint);
      setPreseding(initialData.presedentTaskId);
      setPresedingEvent(initialData.presedentEventId);
      setItems(initialData?.crews);
      setDelegationType(initialData.delegateTaskId ? 'subcontractor' : 'crew');
    }
  }, [initialData, formattedStartDate, formattedEndDate]);

  useEffect(() => {
    getCrews(true);
  }, []);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const data: getOrganizationsForTaskCreationRequest = await graphQlClient.request(
          OrganizationsGraphQL.queries.getOrganizationsForTaskCreation,
          {
            organizations: {
              page: 0,
              limit: 1000, // TODO: update this
              search: '',
              filter: 'Active',
              orderBy: 'name',
              orderDirection: 'asc',
            },
          },
        );
        if (data.getOrganizationsForTaskCreation.organizations) {
          const filteredOrganizations = data.getOrganizationsForTaskCreation.organizations.filter(
            (org) => org.organizationId !== initialData?.originalOrganizationId,
          );
          setOrganizations(filteredOrganizations || []);
          if (initialData?.delegateTaskId && initialData?.delegateOrganizationId) {
            setSelectedOrganization(
              data.getOrganizationsForTaskCreation.organizations.find(
                (org) => org.organizationId === initialData.delegateOrganizationId,
              ) || null,
            );
          }
        }
      } catch (e: any) {
        dispatch(
          appendActionMessage({
            message: e?.response?.errors[0]?.message || localeCommon['requestError'],
            type: SnackType.ERROR,
          }),
        );
      }
    };

    if (delegationType === 'subcontractor') {
      fetchOrganizations();
    }
  }, [delegationType, dispatch, localeCommon]);

  const getCrews = async (initialize = false) => {
    try {
      const data: getCrewsRequest = await graphQlClient.request(CrewsGraphQL.queries.getCrews);
      setCrews(data.getCrews);
    } catch (e: any) {
      dispatch(
        appendActionMessage({
          message: e?.response?.errors[0]?.message || localeCommon['requestError'],
          type: SnackType.ERROR,
        }),
      );
    }
  };

  const handleNameChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('name');
    setName(event.target.value);
  };

  const handleDescriptionChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('description');
    setDescription(event.target.value);
  };

  const handleTimeConstraintChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('timeConstraint');
    setTimeConstraint(Number(event.target.value));
  };

  const handleStartChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('startDate');
    setStart(event.target.value);
  };

  const handleEndChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('endDate');
    setEnd(event.target.value);
  };

  const handleManHoursChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('manHours');
    setManHours(Number(event.target.value));
  };

  const handleDownload = (pdfUrl: string) => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank'); // Open a new tab for preview
    }
  };

  const handleChange = (event: React.SyntheticEvent, value: { id: string; name: string } | null) => {
    resetError('taskType');
    if (value) {
      setSelectedType(value.name);
    } else {
      setSelectedType(null);
    }
  };

  const handleStatusChange = (event: React.SyntheticEvent, value: { id: string; name: string } | null) => {
    resetError('taskStatus');
    if (value) {
      setSelectedStatus(value.name);
    } else {
      setSelectedStatus(null);
    }
  };

  const handleOtherTypeChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('otherType');
    setOtherType(event.target.value);
  };

  const handleTypeConstrainChange = (event: React.SyntheticEvent, value: { id: string; name: string } | null) => {
    resetError('timeType');
    if (value) {
      setSelectedTypeConstraint(value.id);
    } else {
      setSelectedTypeConstraint(null);
    }
  };

  const submit = () => {
    const isNameValid = validateInput('name', name);
    const isDescriptionValid = validateInput('description', description);
    const isManHoursValid = validateInput('manHours', manHours);
    const isStartValid = validateInput('startTime', start);
    const isEndValid = validateInput('endTime', end);
    const isTimeConstraintValid = validateInput('timeConstraint', timeConstraint);
    const isSelectedTypeConstraintValid = validateInput('timeType', selectedTypeConstraint);
    const isTaskTipeValid = validateInput('taskType', selectedType);
    const isOtherTypeValid = selectedType === 'Other' ? validateInput('otherType', otherType) : true;
    if (
      !isDateRangeInvalid &&
      start &&
      end &&
      isOtherTypeValid &&
      isDescriptionValid &&
      description &&
      timeConstraint &&
      selectedType &&
      isTaskTipeValid &&
      selectedTypeConstraint &&
      isSelectedTypeConstraintValid &&
      isNameValid &&
      name &&
      isManHoursValid &&
      isStartValid &&
      isEndValid &&
      isTimeConstraintValid &&
      (avatars || documents)
    ) {
      const startDate = new Date(start).toISOString();
      const endDate = new Date(end).toISOString();
      const crewIds: string[] | undefined = items
        ?.map((crew) => crew.crewId)
        .filter((id): id is string => id !== undefined);

      let equipments = inventory.filter((item): item is Equipment => 'costPerHour' in item);
      let materials = inventory.filter((item): item is Material => 'costPerUnit' in item);

      if (initialData?.taskId) {
        equipments = equipments.map((equipment) => ({ ...equipment, taskId: initialData.taskId }));
        materials = materials.map((material) => ({ ...material, taskId: initialData.taskId }));
      }

      const preceding = preseding && Array.isArray(preseding) ? [...preseding!] : [];
      const precedingEvent = presedingEvent && Array.isArray(presedingEvent) ? [...presedingEvent!] : [];
      const otherTypeToSend = selectedType && selectedType === 'Other' ? otherType : null;

      if (initialData) {
        editTask &&
          editTask(
            name,
            description,
            selectedType,
            selectedStatus,
            otherTypeToSend,
            manHours,
            startDate,
            endDate,
            timeConstraint,
            selectedTypeConstraint,
            crewIds,
            equipments,
            materials,
            deleteDocuments,
            preceding,
            precedingEvent,
            avatars,
            initialData.taskId || '',
            selectedOrganization?.organizationId ?? null,
          );
      } else {
        createTask &&
          createTask(
            name,
            description,
            selectedType,
            selectedStatus,
            otherTypeToSend,
            manHours,
            startDate,
            endDate,
            timeConstraint,
            selectedTypeConstraint,
            crewIds,
            equipments,
            materials,
            deleteDocuments,
            preceding,
            precedingEvent,
            avatars,
            undefined,
            selectedOrganization?.organizationId ?? null,
          );
      }
      setDeleteDocuments([]);
      modalClose(null);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    if (files.length > 0) {
      // Append the new files to the existing avatars
      const newAvatars = [...avatars, ...files];
      setAvatars(newAvatars);
      // Update the document array dynamically based on the number of files
      const documentArray = files.map((file, index) => ({
        documentUrl: URL.createObjectURL(file), // Create an object URL for preview
        name: file.name, // Ensuring unique names for each document
      }));
      setDocuments((prevDocuments) => [...prevDocuments, ...documentArray]);

      // preview file pending (not handled)
    }
  };

  const toggleMaterialModal = () => {
    setSelectedInventoryItem(null);
    modalMaterialClose();
  };
  const toggleEquipmentModal = () => {
    setSelectedInventoryItem(null);
    modalEquipmentClose();
  };

  const toggleInventoryModal = (value: any) => {
    setSelectedInventoryItem(value);
    if (value.equipmentId) {
      modalEquipmentClose();
    } else {
      modalMaterialClose();
    }
  };

  const handleDocumentDelete = (value: any) => {
    if (value.documentId) {
      if (!deleteDocuments?.includes(value.documentId)) {
        const newDeleteDocuments = [...deleteDocuments, value.documentId];
        setDeleteDocuments(newDeleteDocuments);
      }
    }
  };

  const attemptCancel = () => {
    setDeleteDocuments([]);
    if (window.confirm('Sure? Your changes will be lost')) {
      modalClose(null);
    }
  };

  const getDependentTaskIds = (task: Task, tasks: Task[]): string[] => {
    const precedentTaskIds = task.presedentTaskId || [];

    const dependentTaskIds = precedentTaskIds
      .map((precedentTaskId) => {
        const precedentTask =
          initialData && precedentTaskId === initialData.taskId
            ? initialData
            : tasks.find((t) => t.taskId === precedentTaskId);

        return precedentTask ? getDependentTaskIds(precedentTask, tasks) : [];
      })
      .flat();

    return [...precedentTaskIds, ...dependentTaskIds];
  };

  const tasksWithDependencies = tasks
    .map((task) => ({
      ...task,
      dependencies: getDependentTaskIds(task, tasks),
    }))
    .filter((task) => !task.dependencies.includes(initialData?.taskId!));

  const handleDelegationTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newType = e.target.checked ? 'subcontractor' : 'crew';
    setDelegationType(newType);

    // Reset the unselected option
    if (newType === 'subcontractor') {
      setItems([]); // Reset crew selection
    } else {
      setSelectedOrganization(null); // Reset subcontractor selection
    }
  };

  // const getDependentEventIds = (task: Task, tasks: Task[]): string[] => {
  //   const precedentEventIds = task.presedentEventId || [];

  //   const dependentEventIds = precedentEventIds
  //     .map((precedentEventId) => {
  //       const precedentEvent =
  //         initialData && precedentEventId === initialData.taskId
  //           ? initialData
  //           : tasks.find((t) => t.taskId === precedentEventId);

  //       return precedentEvent ? getDependentEventIds(precedentEvent, tasks) : [];
  //     })
  //     .flat();

  //   return [...precedentEventIds, ...dependentEventIds];
  // };

  // const eventsWithDependencies = tasks
  // .map((task) => ({
  //   ...task,
  //   dependencies: getDependentEventIds(task, tasks),
  // }))
  // .filter((task) => !task.dependencies.includes(initialData?.taskId!));

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h3">{'Add Task'}</Typography>
        <Box sx={{ width: 200 }}>
          <SelectStatusInput
            disabled={!!initialData?.originalTaskId}
            label={''}
            placeholder={'Status'}
            items={TaskStatusOptions}
            value={selectedStatus}
            valueKey="id"
            labelKey="name"
            onChange={handleStatusChange}
            error={errors.taskStatus}
            errorMessage={'Required field'}
          />
        </Box>
      </Box>
      <Box>
        <AltInput
          fullWidth
          disabled={!!initialData?.originalTaskId}
          autoComplete="task"
          label={localeProjects['name']}
          placeholder={`Task ${localeProjects['name']}`}
          onChange={handleNameChange}
          value={name}
          error={errors.name}
          errorMessage={`Name must be at least 3 characters long`}
        />
        <AltInput
          fullWidth
          disabled={!!initialData?.originalTaskId}
          autoComplete="task"
          label={'Description'}
          placeholder={`Task Description`}
          onChange={handleDescriptionChange}
          value={description}
          error={errors.description}
          errorMessage={`Description must be at least 3 characters long`}
        />
        <SelectInput
          disabled={!!initialData?.originalTaskId}
          label={'Task Type'}
          placeholder={'Task Type'}
          items={TaskOptions}
          value={selectedType}
          valueKey="id"
          labelKey="name"
          onChange={handleChange}
          error={errors.taskType}
          errorMessage={'Required field'}
        />
        {selectedType === 'Other' && (
          <AltInput
            fullWidth
            disabled={!!initialData?.originalTaskId}
            autoComplete="otherType"
            label={'Add Other Type'}
            placeholder={`Add Other Type`}
            onChange={handleOtherTypeChange}
            value={otherType}
            error={errors.otherType}
            errorMessage={`Type must be at least 3 characters long`}
          />
        )}
        <Stack direction="row" width={'100%'} paddingLeft={1} sx={{ paddingTop: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 0, marginRight: 0 }}>
            Delegate to Crew
            <FormControlLabel
              control={
                <Switch
                  checked={delegationType === 'subcontractor'}
                  onChange={handleDelegationTypeChange}
                  color="primary"
                />
              }
              label=""
              // label={delegationType === 'crew' ? 'Delegate to Crew' : 'Delegate to Subcontractor'}
            />
            Delegate to Subcontractor
          </Box>
        </Stack>
        <Box>
          {delegationType === 'crew' ? (
            <AutocompleteMultipleInput<Crew>
              fullWidth
              label="Crew"
              items={crews}
              value={items}
              getOptionLabel={(option) => `${option.name}` ?? ''}
              placeholder="Select Crew"
              isOptionEqualToValue={(option, value) => option.crewId === value.crewId}
              onChange={(event, newValue) => setItems(newValue)}
              error={errors.address}
              errorMessage="Error selecting crew"
            />
          ) : (
            <Box>
              <AutocompleteMultipleInput<Organization>
                fullWidth
                label="Subcontractor"
                items={organizations}
                value={selectedOrganization ? [selectedOrganization] : []}
                getOptionLabel={(option) => option.name ?? ''}
                placeholder="Select Subcontractor"
                isOptionEqualToValue={(option, value) => option.organizationId === value.organizationId}
                onChange={(event, newValue) => setSelectedOrganization(newValue[0] || null)}
                error={errors.address}
                errorMessage="Error selecting subcontractor"
              />
              <Typography
                variant="body2"
                sx={{
                  color: COLORS.lightBlue,
                  width: '100%',
                  justifyContent: 'center',
                  fontSize: '11px',
                  paddingTop: 1,
                  textAlign: 'end',
                }}>
                If you can't find your subcontractor contact projectsupport@getzenhammer.com
              </Typography>
            </Box>
          )}
        </Box>
        <Stack direction="row" width={'100%'} paddingLeft={1}>
          <NumericFormatInput
            fullWidth
            autoComplete="project"
            label={'Man Hours'}
            placeholder={`120`}
            onChange={handleManHoursChange}
            disablePrefix
            value={manHours}
            error={errors.manHours}
            errorMessage={localeProjects['budgetErrorMessage']}
          />
          <Typography variant="body2" sx={{ width: 100, justifyContent: 'center', fontSize: '14px', paddingTop: 1 }}>
            {'(estimated)'}
          </Typography>
        </Stack>
        <Box paddingLeft={2}>
          <DateInput
            fullWidth
            autoComplete="start"
            disabled
            showTimeSelect
            showIcon={true}
            label={'Start Time'}
            placeholder={`mm/dd/yyyy`}
            onChange={handleStartChange}
            error={errors.startTime || null || undefined}
            value={start}
            errorMessage={localeProjects['startErrorMessage']}
          />
          <DateInput
            fullWidth
            autoComplete="End"
            disabled
            showTimeSelect
            showIcon={true}
            label={'End Time'}
            placeholder={`mm/dd/yyyy`}
            onChange={handleEndChange}
            error={errors.endTime || isDateRangeInvalid || undefined}
            value={end}
            errorMessage={errors.endTime ? localeProjects['endErrorMessage'] : localeProjects['rangeErrorMessage']}
          />
        </Box>
        <CustomizedHook
          options={inventory}
          setOptions={setInventory}
          label="Inventory"
          getOptionLabel={(option) => option.name}
          onTagClick={(value) => toggleInventoryModal(value)}
        />
        <Box sx={{ height: 30, display: 'flex', paddingTop: 0.5, paddingBottom: 4, justifyContent: 'end' }}>
          <Button
            onClick={toggleEquipmentModal}
            height="28px"
            color="tertiary"
            sx={{ width: '39%', marginRight: 0.25 }}>
            + Add Equipment
          </Button>
          <Button onClick={toggleMaterialModal} height="28px" color="tertiary" sx={{ width: '39%', marginLeft: 0.25 }}>
            + Add Material
          </Button>
        </Box>
        <Stack direction="row" width={'50%'} paddingLeft={3}>
          <NumericFormatInput
            fullWidth
            autoComplete="project"
            label={'Time Constraint'}
            placeholder={`120`}
            onChange={handleTimeConstraintChange}
            disablePrefix
            value={timeConstraint}
            error={errors.timeConstraint}
            errorMessage={localeProjects['budgetErrorMessage']}
          />
          <SelectInput
            label={'   '}
            placeholder={'Select time type'}
            items={TimeOptions}
            value={selectedTypeConstraint}
            valueKey="id"
            labelKey="name"
            error={errors.timeType}
            errorMessage={'Required field'}
            onChange={handleTypeConstrainChange}
          />
        </Stack>
        <CustomizedHook
          options={documents || avatars}
          setOptions={setDocuments}
          label="Documents"
          getOptionLabel={(option) => (option.name.length <= 12 ? option.name : option.name.slice(0, 9) + '...')}
          downloadIcon
          onTagClick={(value) => handleDownload(value.documentUrl ? value.documentUrl : '')}
          onClose={(value) => handleDocumentDelete(value)}
        />
        <Box sx={{ height: 30, display: 'flex', paddingTop: 0.5, paddingBottom: 5, justifyContent: 'end' }}>
          <Button height="28px" color="tertiary" sx={{ width: '79.3%', marginRight: 0.25 }} component="label">
            + Upload Document
            <VisuallyHiddenInput
              type="file"
              accept=".pdf, .txt, .csv, image/png, image/jpeg, image/gif"
              onChange={handleFileChange}
              multiple={true}
            />
          </Button>
        </Box>
        <CustomizedHook
          options={tasksWithDependencies}
          setOptions={(_tasks) => setPreseding(_tasks.map((task) => task.taskId!))}
          label="Preceding Task"
          getOptionLabel={(option) => option.name}
          showList={true}
          taskIds={preseding}
          onTagClick={(value) => console.log(value)}
        />
        <CustomizedHook
          options={events}
          setOptions={(_events) => setPresedingEvent(_events.map((event) => event.eventId!))}
          label="Preceding Event"
          getOptionLabel={(option) => option.name}
          showList={true}
          eventIds={presedingEvent}
          onTagClick={(value) => console.log(value)}
        />
      </Box>
      <Box className="flex justify-between mt-4">
        <Button onClick={attemptCancel} color="secondary">
          {localeCommon['cancel']}
        </Button>
        <Button onClick={submit} color="primary" autoFocus disabled={saving}>
          {initialData ? localeCommon['saveChanges'] : localeCommon['create']}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEditTaskModal;
