import AltInput from '@components/ui/AltInput';
import NumericFormatInput from '@components/ui/NumericFormatInput';
import Button from '@components/ui/Button';
import { useTranslation } from '@hooks/useTranslation';
import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { SetStateAction, useEffect, useState } from 'react';
import useValidation from '@hooks/useValidation';
import SelectInput from '@components/ui/SelectInput';
import { Equipment } from '@models/equipment.model';
import { Material } from '@models/material.model';
import { WeightOptions } from '@utils/options';
import DateInput from '@components/ui/DateInput';
import useFormattedDateLarge from '@hooks/useFormattedDateLarge';

type InventoryItem = Equipment | Material;

interface CreateEditMaterialModalProps {
  modalClose: () => void;
  saving: boolean;
  initialData?: any;
  setInventory: (inventory: InventoryItem[]) => void;
  inventory: InventoryItem[];
}

const CreateEditMaterialModal: React.FC<CreateEditMaterialModalProps> = ({
  modalClose,
  saving,
  initialData,
  setInventory,
  inventory,
}) => {
  const localeCommon = useTranslation('common');
  const localeProjects = useTranslation('projects');
  const [name, setName] = useState<string>('');
  const [supplier, setSupplier] = useState<string>('');
  const [costPerUnit, setCostPerUnit] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [selectedWeightId, setSelectedWeightId] = useState<string | null>('');
  const [time, setTime] = useState<string | null>(null);
  const formattedTimeDate = useFormattedDateLarge(initialData?.time || null);
  const [id, setId] = useState<string>('new');

  const { validateInput, resetError, errors } = useValidation();

  useEffect(() => {
    if (initialData?.costPerUnit) {
      setName(initialData.name);
      setSupplier(initialData.supplier);
      setCostPerUnit(initialData.costPerUnit);
      setAmount(initialData.amount);
      setSelectedWeightId(initialData.amountType);
      setId(initialData.materialId);
      setTime(formattedTimeDate);
    }
  }, [initialData, formattedTimeDate]);

  const handleNameChange = (event: { target: { value: SetStateAction<string> } }) => {
    resetError('name');
    setName(event.target.value);
  };

  const handleSupplierChange = (event: { target: { value: SetStateAction<string> } }) => {
    resetError('supplier');
    setSupplier(event.target.value);
  };

  const handleCostPerUnitChange = (event: { target: { value: SetStateAction<string> } }) => {
    resetError('costPerUnit');
    setCostPerUnit(Number(event.target.value));
  };

  const handleAmountChange = (event: { target: { value: SetStateAction<string> } }) => {
    resetError('amount');
    setAmount(Number(event.target.value));
  };

  const handleChange = (event: React.SyntheticEvent, value: { id: string; name: string } | null) => {
    resetError('taskType');
    if (value) {
      setSelectedWeightId(value?.name);
    } else {
      setSelectedWeightId(null);
    }
  };

  const handleTimeChange = (event: { target: { value: SetStateAction<string | null> } }) => {
    resetError('time');
    setTime(event.target.value);
  };

  const submit = () => {
    const isNameValid = validateInput('name', name);
    const isSupplierValid = validateInput('supplier', supplier);
    const isAmountValid = validateInput('amount', amount);
    const isCostPerUnitValid = validateInput('costPerUnit', costPerUnit);
    const isSelectedWeightValid = validateInput('taskType', selectedWeightId);
    const isTimeValid = validateInput('time', time);

    if (
      isNameValid &&
      isSupplierValid &&
      isAmountValid &&
      isCostPerUnitValid &&
      selectedWeightId &&
      isSelectedWeightValid &&
      isTimeValid &&
      time
    ) {
      const newInventory = inventory ? [...inventory] : [];
      const filteredInventory = newInventory.filter((item) => (item as { materialId: string }).materialId !== id);
      const timeDate = new Date(time).toISOString();
      const newItem = {
        ...(initialData ? { materialId: id } : {}),
        name,
        supplier,
        time: timeDate,
        amount,
        costPerUnit,
        amountType: selectedWeightId,
      };

      filteredInventory.push(newItem);
      setInventory(filteredInventory);

      modalClose();
    }
  };

  const attemptCancel = () => {
    if (window.confirm('Sure? Your changes will be lost')) {
      modalClose();
    }
  };

  return (
    <Box>
      <Typography variant="h3">{'Add Material'}</Typography>

      <Box>
        <AltInput
          fullWidth
          autoComplete="project"
          label={localeProjects['name']}
          placeholder={`Material Name`}
          onChange={handleNameChange}
          value={name}
          error={errors.name}
          errorMessage={localeProjects['nameErrorMessage']}
        />
        <AltInput
          fullWidth
          autoComplete="project"
          label={'Supplier'}
          placeholder={`Material Supplier`}
          onChange={handleSupplierChange}
          value={supplier}
          error={errors.supplier}
          errorMessage={'Supplier must be at least 3 characters long'}
        />
        <DateInput
          fullWidth
          autoComplete="time"
          disabled
          showTimeSelect
          showIcon={true}
          label={'Supply/Delivery Date'}
          placeholder={`mm/dd/yyyy`}
          onChange={handleTimeChange}
          error={errors.time || null || undefined}
          value={time}
          errorMessage={localeProjects['startErrorMessage']}
        />
        <Stack direction="row" width={'50%'} paddingLeft={3}>
          <NumericFormatInput
            fullWidth
            autoComplete="project"
            label={'Amount'}
            placeholder={`120`}
            onChange={handleAmountChange}
            disablePrefix
            value={amount}
            error={errors.amount}
            errorMessage={localeProjects['budgetErrorMessage']}
          />
          <SelectInput
            label={'Unit'}
            placeholder={'Unit'}
            items={WeightOptions}
            value={selectedWeightId}
            valueKey="id"
            labelKey="name"
            onChange={handleChange}
            error={errors.taskType}
            errorMessage={'Required field'}
          />
        </Stack>
        <Stack direction="row" width={'50%'} paddingLeft={3}>
          <NumericFormatInput
            fullWidth
            autoComplete="Budget"
            label={'Cost per Unit'}
            placeholder={`Cost per Unit`}
            onChange={handleCostPerUnitChange}
            value={costPerUnit}
            error={errors.costPerUnit}
            errorMessage={localeProjects['budgetErrorMessage']}
          />
          <Typography variant="body2" sx={{ width: 100, justifyContent: 'center', fontSize: '14px', paddingTop: 1 }}>
            {''}
          </Typography>
        </Stack>
      </Box>
      <Box className="flex justify-between mt-4">
        <Button onClick={attemptCancel} color="secondary">
          {localeCommon['cancel']}
        </Button>
        <Button onClick={submit} color="primary" autoFocus disabled={saving}>
          {localeCommon['create']}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEditMaterialModal;
