import { useState, useEffect } from 'react';

const useFormattedDateLarge = (dateStr: string | Date | null) => {
  const [formattedDate, setFormattedDate] = useState<string | null>(null);

  useEffect(() => {
    if (dateStr) {
      const date = new Date(dateStr);

      const pad = (number: number) => (number < 10 ? `0${number}` : number);

      const month = pad(date.getMonth() + 1);
      const day = pad(date.getDate());
      const year = date.getFullYear();

      let hours = date.getHours();
      const minutes = pad(date.getMinutes());
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12;
      const formattedHours = pad(hours);

      const formatted = `${month}/${day}/${year} ${formattedHours}:${minutes} ${ampm}`;

      setFormattedDate(formatted);
    } else {
      setFormattedDate(null);
    }
  }, [dateStr]);

  return formattedDate;
};

export default useFormattedDateLarge;
